import { KeyStoreUtils } from "conseiljs-softsigner";
import { ethers } from "ethers";
import moment from "moment-timezone";
import { virtualCurrencyBlockchain } from "./config";
import { Transaction } from "./dto/Transaction";

import { assignWith, pickBy } from "lodash";
import { FormikHelpers, FormikProps } from "formik";
import { Offer } from "./dto/Offer";
import { Account } from "./dto/Account";
import { getCurrentAccountId } from "./lib/auth";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router";


export const useOfferFilters = () => {
  const location = useLocation();
  const {
    search,
    tags,
    tagGroups,
    investmentObjective,
    timeHorizon,
    targetReturn,
    riskLevel,
    industry,
    registeredEligible
  } = useMemo(() => {
      const params = new URLSearchParams(location.search);

      return {
        search: params.get("search") || "",
        tags: params.get("tags")?.split(",") || [],
        tagGroups: params.get("tagGroups")?.split(",")?.filter(x => !!x) || [],
        investmentObjective: params.get("investmentObjective")?.split(",") || [],
        timeHorizon: params.get("timeHorizon")?.split(",") || [],
        targetReturn: params.get("targetReturn")?.split(",") || [],
        riskLevel: params.get("riskLevel")?.split(",") || [],
        industry: params.get("industry")?.split(",") || [],
        registeredEligible: params.get("registeredEligible") ? params.get("registeredEligible") === "Yes" : undefined,
      }
    }, [location.search]);

  return {
    search,
    tags,
    tagGroups,
    investmentObjective,
    timeHorizon,
    targetReturn,
    riskLevel,
    industry,
    registeredEligible
  }
}

export const useUpdateOfferFilter = () => {
  const h = useHistory();
  const f = useCallback((key: string, value?: string | string[]) => {
    const params = new URLSearchParams(location.search);
    if ((Array.isArray(value) && value?.length) || value) {
      if (Array.isArray(value)) {
        params.set(key, value.join(","));
      } else {
        params.set(key, value);
      }
    } else {
      params.delete(key);
    }
    h.replace(`${location.pathname}?${params.toString()}`);
  }, [h])

  return f;
};

export function toTitleCase(str: string) {
  return (
    str
      // Lowercase the whole string first
      .toLowerCase()
      // Replace underscores, hyphens, and spaces, followed by a lowercase letter with an uppercase letter
      .replace(/[_\- ]+(\w)/g, (_, char) => " " + char.toUpperCase())
      // Also capitalize the first character of the string if it's a letter
      .replace(/^(\w)/, (char) => char.toUpperCase())
  );
}

export function toSnakeCase(str: string) {
  return (
    str
      // Replace spaces and multiple underscores with a single underscore
      .replace(/\s+/g, "_")
      // Remove all non-word characters (except underscores)
      .replace(/[^a-zA-Z0-9_]/g, "")
      // Replace any uppercase letters with lowercase preceded by an underscore, avoiding double underscores
      .replace(/([A-Z]+)/g, (match) => "_" + match.toLowerCase())
      // Reduce consecutive underscores to a single underscore
      .replace(/_+/g, "_")
      // Convert to lower case
      .toLowerCase()
      // Avoid leading or trailing underscores if any
      .replace(/^_|_$/g, "")
  );
}

export function secondsToString(seconds: any) {
  const numyears = Math.floor(seconds / 31536000);
  const numdays = Math.floor((seconds % 31536000) / 86400);
  const numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
  const numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
  const numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;
  return `${numyears ? numyears + " years " : ""}
      ${numdays ? numdays + " days " : ""}
      ${numhours ? numhours + " hours " : ""}
      ${numminutes ? numminutes + " minutes " : ""}
      ${numseconds ? numseconds + " seconds" : ""}`.trim();
}

export const formatMoney = (
  value: number,
  {
    style = "currency",
    currencyCode = "CAD",
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
  } = {}
) =>
  value
    ? value.toLocaleString("en-CA", {
      style,
      currency: currencyCode,
      minimumFractionDigits,
      maximumFractionDigits,
    })
    : "0";

export const createDate = (
  date?: string | number | Date,
  startOfDate?: boolean
): Date => {
  const today = new Date();
  const time =
    `${today.getHours()}`.padStart(2, "0") +
    ":" +
    `${today.getMinutes()}`.padStart(2, "0") +
    ":" +
    `${today.getSeconds()}`.padStart(2, "0");

  // Fix:
  // - Date fix where 2023-31-01 gets incorectly converted to 01 30 2023
  // - Dates with timestamp 00:00:00 are problematic, replace them with current time
  const dateWithFix = date
    ? typeof date === "string"
      ? date.includes("-") && !date.includes(":")
        ? `${date}T${time}`
        : date.replace(/00:00:00/, time)
      : date
    : "";

  const _date = dateWithFix ? new Date(dateWithFix) : new Date();

  _date.setHours(today.getHours())
  _date.setMinutes(today.getMinutes())
  _date.setSeconds(today.getSeconds())

  const m = moment.tz(_date, "America/Toronto");

  return (startOfDate ? m.startOf("date") : m).toDate();
};

export const formatDate = (d: undefined | null | string | number | Date, x?: any) => {
  if (!d) {
    return x || "-";
  }

  const res = moment
    .tz(createDate(d), "America/Toronto")
    .format("M/DD/YYYY");
  return res === "Invalid date" ? x || "-" : res;
};

export const createTezosWallet = () => KeyStoreUtils.generateIdentity();

export const createEthereumWallet = async () => ethers.Wallet.createRandom();

export function convertTransactionAmount(values: Transaction) {
  const blockchain = (virtualCurrencyBlockchain as any)[values.paymentCurrencyCode];

  const converted = values.amount * (1 / (values.paymentCurrencyExchangeRate || 1));

  return Number(
    !blockchain
      ? converted.toFixed(2)
      : blockchain === "tezos"
        ? converted.toFixed(6)
        : converted.toFixed(8)
  );
}

export const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export function formatForJsonTable(
  data: any,
  hideKeys: Array<string> = [],
  depth = 0
): any {
  return Array.isArray(data)
    ? data?.filter((v) => !!v)?.map((v) => formatForJsonTable(v, hideKeys, depth + 1))
    : typeof data === "object" && data !== undefined && data !== null
      ? pickBy(
        assignWith({}, data || {}, (_: any, value: any) =>
          typeof value === "boolean"
            ? String(value)
            : formatForJsonTable(value, hideKeys, depth + 1)
        ),
        (value, key) => {
          if (depth === 0 && value === "-") {
            return false;
          }
          return !hideKeys.includes(key);
        }
      )
      : data || "-";
}

export function generateRedirectToPath({
  to,
  withTempPassword = true,
}: {
  to?: string;
  withTempPassword?: boolean;
} = {}): any {
  const redirectSearchParams = new URLSearchParams(location.search);

  if (!withTempPassword) {
    redirectSearchParams.delete("tempPassword");
    redirectSearchParams.delete("email");
  }

  if (!redirectSearchParams.has("accountId") && getCurrentAccountId()) {
    redirectSearchParams.append("accountId", getCurrentAccountId() || "");
  }

  redirectSearchParams.append(
    "redirectTo",
    encodeURIComponent((to || location.pathname) + location.search)
  );

  return "?" + redirectSearchParams.toString();
}

export function checkSuitabilityMismatch(offer: Offer, investor: Account) {
  const suitabilityMismatch: Array<{
    param: string;
    value: string;
    config: Array<string>;
  }> = [];

  if (
    offer.liquidityRisk.length &&
    !offer.liquidityRisk.includes(investor.suitability.investment_horizon)
  ) {
    suitabilityMismatch.push({
      param: "Liquidity Risk",
      config: offer.liquidityRisk,
      value: investor.suitability.investment_horizon,
    });
  }
  if (
    offer.riskTolerance.length &&
    !offer.riskTolerance.includes(investor.suitability.risk_tolerance)
  ) {
    suitabilityMismatch.push({
      param: "Risk Tolerance",
      config: offer.riskTolerance,
      value: investor.suitability.risk_tolerance,
    });
  }
  if (
    offer.riskCapacity.length &&
    !offer.riskCapacity.includes(investor.suitability.risk_capacity)
  ) {
    suitabilityMismatch.push({
      param: "Risk Capacity",
      config: offer.riskCapacity,
      value: investor.suitability.risk_capacity,
    });
  }
  if (
    offer.investmentObjective.length &&
    !offer.investmentObjective.includes(investor.suitability.investment_objectives)
  ) {
    suitabilityMismatch.push({
      param: "Investment Objectives",
      config: offer.investmentObjective,
      value: investor.suitability.investment_objectives,
    });
  }

  return suitabilityMismatch;
}

export function isResidenceRestricted(
  residenceRestrictions:
    | {
      [key: string]: {
        canInvest: boolean;
        requiredAccreditation: Array<string>;
        requiredProvincesOrStates: Array<string>;
      };
    }
    | undefined,
  address: any,
  accreditationStatus: any
) {
  return (
    !!residenceRestrictions &&
    Object.keys(residenceRestrictions).length &&
    (residenceRestrictions[address?.country]
      ? // Check if country is allowed to invest
      !residenceRestrictions[address?.country].canInvest ||
      // Check accreditation restriction
      (residenceRestrictions[address?.country].requiredAccreditation?.length &&
        !residenceRestrictions[address?.country].requiredAccreditation.includes(
          accreditationStatus
        )) ||
      // Check province/state
      (residenceRestrictions[address?.country].requiredProvincesOrStates?.length &&
        !residenceRestrictions[address?.country].requiredProvincesOrStates.includes(
          address?.province
        ))
      : // Check if all other countries are allowed to invest
      (residenceRestrictions["All Other Unspecified Countries"] &&
        !residenceRestrictions["All Other Unspecified Countries"].canInvest) ||
      // Check accreditation restriction
      (residenceRestrictions["All Other Unspecified Countries"]?.requiredAccreditation
        ?.length &&
        !residenceRestrictions[
          "All Other Unspecified Countries"
        ]?.requiredAccreditation?.includes(accreditationStatus)))
  );
}

export const downloadFile = async (url: string, fileName?: string) => {
  const link = document.createElement("a");

  if (new URL(url).protocol === "http:") {
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        link.href = URL.createObjectURL(blob);
        link.download = fileName || new URL(url).pathname.split("/").pop() || "Download";

        link.click();
      });
  } else {
    link.href = url;
    link.download = fileName || new URL(url).pathname.split("/").pop() || "Download";

    link.click();
  }
};

export function setFieldsTouched(
  form: FormikProps<any> | FormikHelpers<any>,
  obj: any,
  path?: string
) {
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === "object") {
      setFieldsTouched(form, value, `${path ? `${path}.` : ""}` + key);
    } else {
      form.setFieldTouched(`${path ? `${path}.` : ""}` + key, true);
    }
  });
}

export function titleCase(str: string) {
  return str.replace(/(^|\s)[a-z]/g, function (ch) {
    return ch.toUpperCase();
  });
}

export function onChangeTitleCase(cb: any) {
  return (e: any) => {
    e.target.value = titleCase(e.target.value);
    cb(e);
  };
}

const postalCodeFormatters: { [key: string]: (code: string) => string } = {
  CA: (code) =>
    code
      .replace(/[a-z0-9]/gi, function (ch) {
        return ch.toUpperCase();
      })
      .replace(/\s+/, " "),
};

export function formatPostalCode(countryCode: string, str: string): string {
  if (postalCodeFormatters[countryCode]) {
    return postalCodeFormatters[countryCode](str);
  }
  return str;
}

export function onChangeFormatPostalCode(countryCode: string, cb: any) {
  return (e: any) => {
    e.target.value = formatPostalCode(countryCode, e.target.value);
    cb(e);
  };
}
