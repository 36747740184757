import { BeaconWallet } from "@taquito/beacon-wallet";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { preferredNetwork } from "../config";
import { useNetwork } from "./useNetwork";

const Context = createContext<{
  startSession: () => Promise<string>;
  disconnect: () => void;
  walletAddress?: string;
  preferredNetworkId: string;
  connector: BeaconWallet | undefined;
}>({
  startSession: async () => {
    return "";
  },
  disconnect: () => {},
  connector: undefined,
  preferredNetworkId: preferredNetwork.tezos as any,
});

export const useBeaconWallet = () => useContext(Context);

export const BeaconContextProvider: React.FC = ({ children }) => {
  const [connector] = useState(
    new BeaconWallet({
      name: "Atlas One",
      iconUrl:
        "https://invest.atlasone-staging.ca/static/media/atlasone-logo-full.505dfe22.png",
      preferredNetwork: preferredNetwork.tezos as any,
    })
  );
  const { tezos: preferredNetworkId } = useNetwork();
  const [walletAddress, setWalletAddress] = useState<string | undefined>();

  useEffect(() => {
    (async () => {
      const activeAccount = await connector.client.getActiveAccount();
      if (activeAccount) {
        setWalletAddress(activeAccount.address);
      }
    })();
  }, [connector]);

  const startSession = useCallback(async () => {
    await connector.clearActiveAccount().then(() => setWalletAddress(undefined));

    return connector
      .requestPermissions({
        network: {
          type: preferredNetworkId as any,
        },
      })
      .then(() => connector.getPKH())
      .then((address) => {
        setWalletAddress(address);
        return address;
      });
  }, [connector, preferredNetworkId]);

  const disconnect = useCallback(async () => {
    return connector.clearActiveAccount().then(() => setWalletAddress(undefined));
  }, [connector]);

  return (
    <Context.Provider
      value={{
        startSession,
        disconnect,
        walletAddress,
        connector,
        preferredNetworkId: preferredNetworkId as string,
      }}
    >
      {children}
    </Context.Provider>
  );
};
