import { Auth, CookieStorage } from "@aws-amplify/auth";
import { config } from "../config";

Auth.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.region,
    userPoolId: config.cognito.userPoolId,
    userPoolWebClientId: config.cognito.userPoolWebClientId,
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
  storage: new CookieStorage(config.cookieStorage),
});

export async function getSession() {
  return Auth.currentSession();
}

export async function getAuthToken() {
  return Auth.currentSession().then((session) => session.getAccessToken().getJwtToken());
}

export async function forgotPassword(email: string) {
  return Auth.forgotPassword(email);
}

export async function resetPassword({
  email,
  code,
  newPassword,
}: {
  email: string;
  code: string;
  newPassword: string;
}) {
  return Auth.forgotPasswordSubmit(email, code, newPassword, {});
}

export async function refreshSession() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return new Promise<void>((resolve, reject) => {
      const { refreshToken } = user.getSignInUserSession();
      user.refreshSession(refreshToken, (err: any) => {
        if (err) {
          console.error('Session refresh failed:', err);
          reject(err);
          return;
        }
        resolve();
      });
    });
  } catch (error) {
    console.error('Error refreshing session:', error);
    throw error;
  }
}

export async function signIn({ email, password }: { email: string; password: string }) {
  /**
   * https://www.notion.so/Case-Insensitive-Sign-In-for-Cognito-AWS-22ff101efb504960a7acd545f6862b12
   * Work around for now to solve the case sensitive email field
   */
  return Auth.signIn(email.toLowerCase(), password);
}

export async function signOut() {
  // https://github.com/aws-amplify/amplify-js/issues/3540#issuecomment-506442759
  return Auth.signOut({ global: true })
    .catch(() => Auth.signOut())
    .finally(() => {
      localStorage.clear();
    });
}

export async function signUp({
  email,
  phone,
  password,
  firstName,
  middleName,
  lastName,
}: {
  email: string;
  phone: string;
  password: string;
  firstName: string;
  middleName: string;
  lastName: string;
}) {
  return Auth.signUp({
    username: email,
    password,
    attributes: {
      given_name: firstName,
      middle_name: middleName,
      family_name: lastName,
      phone_number: phone,
    },
  });
}

export async function getMFASetup({ email }: { email: string }) {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.setPreferredMFA(user, "NOMFA");
  const authCode = await Auth.setupTOTP(user);

  const qrCode = `otpauth://totp/Atlas One:${email}?secret=${authCode}&issuer=Atlas One`;
  return {
    authCode,
    qrCode,
  };
}

export async function getPreferredMFA() {
  const user = await Auth.currentAuthenticatedUser();
  const preferredMFA = await Auth.getPreferredMFA(user);
  return {
    preferredMFA,
  };
}

export async function verifyMFA(code: string) {
  const user = await Auth.currentAuthenticatedUser();
  return Auth.verifyTotpToken(user, code).then(() => Auth.setPreferredMFA(user, "TOTP"));
}

export async function confirmSignIn(user: any, code: string) {
  return await Auth.confirmSignIn(user, code, "SOFTWARE_TOKEN_MFA");
}

export async function verifyEmail(code: string) {
  return Auth.verifyCurrentUserAttributeSubmit("email", code).then(async (reason) => {
    // Refresh the auth and id token for cognito to update the email_verified attribute
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const { refreshToken } = cognitoUser.getSignInUserSession();

    return new Promise((resolve) => {
      cognitoUser.refreshSession(refreshToken, () => {
        resolve(reason);
      });
    });
  });
}

export async function sendEmailVerificationCode() {
  return Auth.verifyCurrentUserAttribute("email");
}

export async function completeNewPassword(user: any, newPassword: string) {
  return Auth.completeNewPassword(user, newPassword);
}

export async function confirmSignUp(username: string, code: string) {
  return Auth.confirmSignUp(username, code);
}

export async function resendSignUp(username: string) {
  return Auth.resendSignUp(username);
}

export async function disableMFA() {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.setPreferredMFA(user, "NOMFA");
}

export async function changePassword(oldPassword: string, newPassword: string) {
  return await Auth.currentAuthenticatedUser().then((user) => {
    return Auth.changePassword(user, oldPassword, newPassword);
  });
}
