import MetaMaskOnboarding from "@metamask/onboarding";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { preferredNetwork } from "../config";
import { useNetwork } from "./useNetwork";

const onboarding = new MetaMaskOnboarding();
const Context = createContext<{
  onboarding: MetaMaskOnboarding;
  walletAddress?: string;
  metamaskChainId?: number;
  preferredNetworkId: string;
  connect: () => Promise<string | undefined | null>;
}>({
  onboarding,
  preferredNetworkId: preferredNetwork.ethereum,
  connect: async () => {
    return "";
  },
});

export const useMetamask = () => useContext(Context);

export const MetmaskContextProvider: React.FC = ({ children }) => {
  const { ethereum: preferredNetworkId } = useNetwork();

  const [walletAddress, setWalletAddress] = useState<string | undefined>(
    (window as any)?.ethereum?.selectedAddress as string | undefined
  );
  const [metamaskChainId, setMetamaskChainId] = useState<number | undefined>(
    (window as any)?.ethereum?.chainId && Number((window as any)?.ethereum?.chainId)
  );

  useEffect(() => {
    const ethereum = (window as any).ethereum;
    if (ethereum) {
      const handleAccountsChanged = function (accounts: Array<string>) {
        setWalletAddress(accounts[0]);
      };
      ethereum.on("accountsChanged", handleAccountsChanged);

      const handleChainChanged = function (chainId: string) {
        setMetamaskChainId(Number(chainId));
      };
      ethereum.on("chainChanged", handleChainChanged);

      const handleConnect = function (connectInfo: any) {
        setMetamaskChainId(Number(connectInfo.chainId));
      };
      ethereum.on("connect", handleConnect);

      const handleDisonnect = function () {};
      ethereum.on("disconnect", handleDisonnect);

      return () => {
        ethereum.removeListener("connect", handleConnect);
        ethereum.removeListener("disconnect", handleDisonnect);
        ethereum.removeListener("accountsChanged", handleAccountsChanged);
        ethereum.removeListener("chainChanged", handleChainChanged);
      };
    }
  }, []);

  const connect = useCallback(async () => {
    return (window as any).ethereum
      .request({ method: "eth_requestAccounts" })
      .then((accounts: any) => {
        if (accounts && accounts[0]) {
          setWalletAddress(accounts[0]);
          return accounts[0];
        }
      })
      .catch(console.error);
  }, []);

  return (
    <Context.Provider
      value={{
        metamaskChainId,
        preferredNetworkId,
        walletAddress,
        onboarding,
        connect,
      }}
    >
      {children}
    </Context.Provider>
  );
};
