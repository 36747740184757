import { ApolloProvider } from "@apollo/client";
import { CssBaseline, ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom";
import { AppContextProvider } from "./AppContext";
import { BeaconContextProvider } from "./context/useBeaconWallet";
import { MetmaskContextProvider } from "./context/useMetamask";
import { WalletConnetContextProvider } from "./context/useWalletConnect";
import { client } from "./lib/graphql";
import { theme } from "./theme";
import TagManager from "react-gtm-module";
import { config } from "./config";
import { NetworkContextProvider } from "./context/useNetwork";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

TagManager.initialize({
  gtmId: config.gtmId,
});

export const render = (component: any, element: any) =>
  ReactDOM.render(
    <>
      <NetworkContextProvider>
        <MetmaskContextProvider>
          <BeaconContextProvider>
            <WalletConnetContextProvider>
              <ApolloProvider client={client}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                      <SnackbarProvider maxSnack={3}>
                        <CssBaseline />
                        <ConfirmProvider>
                          <AppContextProvider>{component}</AppContextProvider>
                        </ConfirmProvider>
                      </SnackbarProvider>
                    </ThemeProvider>
                  </StyledEngineProvider>
                </LocalizationProvider>
              </ApolloProvider>
            </WalletConnetContextProvider>
          </BeaconContextProvider>
        </MetmaskContextProvider>
      </NetworkContextProvider>
    </>,
    element
  );
