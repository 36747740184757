import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    background: {
      default: "#F5F5F5",
      paper: "white",
    },
    primary: {
      main: "#03283C",
    },
    secondary: {
      main: "#fff",
    },
    text: {
      primary: "#000",
      secondary: "#000",
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: "Montserrat",
    h1: {
      fontWeight: 800,
    },
    h2: {
      fontWeight: 800,
    },
    h3: {
      fontWeight: 800,
    },
    h4: {
      fontWeight: 600,
      fontSize: "2.25rem",
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    h6: {
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: "1.25rem",
    },
  },
  components: {
    MuiInputBase: {
      defaultProps: {
        autoComplete: "off",
        spellCheck: false,
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: "primary",
      },
      styleOverrides: {
        root: {
          "& > .Mui-disabled": {
            display: "none",
          },
          "&.light": {
            color: "white",
            "& .Mui-selected": {
              zIndex: 1,
              color: "black",
              fontWeight: 600,
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "#D6EFFD",
            },
          },
          "& .Mui-selected": {
            zIndex: 1,
            color: "white",
            fontWeight: 600,
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#03283C",
          },
        },
        scroller: {
          flex: "0 1 auto",
        },
        indicator: {
          height: "100%",
          width: "100%",
          borderRadius: "5px",
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        color: "primary",
      },
      styleOverrides: {
        root: {
          fontWeight: 400,
        },
        sizeLarge: {
          fontWeight: 700,
        },
        containedSecondary: {
          color: "black",
          backgroundColor: "#D6EFFD",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
        color: "primary",
      },
    },
    MuiSelect: {
      defaultProps: {
        color: "primary",
      },
      styleOverrides: {
        iconFilled: {
          fill: "#fff !important",
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        color: "primary",
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 1,
      },
      styleOverrides: {
        rounded: {
          borderRadius: "10px",
        },
        elevation1: {
          border: "1px solid #D3DAD7",
          boxShadow: "none",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        filled: {
          color: "#fff !important",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#03283C !important",
          borderRadius: "4px",
          overflow: "hidden",
          color: "#fff !important",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: "100%",
          padding: "5px",
        },
        label: {
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflowWrap: "break-word",
          whiteSpace: "normal",
          textAlign: "center",
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        vertical: {
          padding: 0,
          marginLeft: "13px",
        },
        lineVertical: {
          minHeight: "25px",
          borderColor: "#CDD5D1",
        },
        root: {
          "&.Mui-complete": {
            "& > .MuiStepConnector-lineVertical": {
              borderColor: "#76CA66 !important",
            },
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: "0.875rem",
          whiteSpace: "nowrap",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: "#D6EFFD",
          "&.Mui-complete": {
            color: "#76CA66 !important",
          },
          "&.Mui-active": {
            color: "#03283C !important",
            "& > .MuiStepIcon-text": {
              fill: "white",
            },
          },
          text: {
            fill: "#03283C",
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          background: "transparent",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginBottom: "15px",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#03283C",
          fontSize: "14px",
          lineHeight: "21px",
          padding: "15px",
        },
        arrow: {
          color: "#03283C",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        ".picker-dialog-bg": {
          zIndex: "1600 !important"
        },
        ".picker-dialog": {
          zIndex: "1601 !important"
        },
        "@global": {
          html: {
            fontSize: "16px",
          },
          "*": {
            outline: "none",
          },
          ".Mui-focused": {
            color: "#000 !important",
          },
          ".Mui-checked": {
            color: "#000 !important",
          },
          ".react-pdf__Document, .react-pdf__message ": {
            width: "100%",
          },
          ".pdf-viewer": {
            display: "flex !important",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          },
          ".MuiPickersToolbarText-toolbarTxt": {
            fontSize: "1.2rem !important",
          },

          /*
           * Disable number field arrows
           */

          /* Chrome, Safari, Edge, Opera */
          ["input::-webkit-outer-spin-button,input::-webkit-inner-spin-button"]: {
            "-webkit-appearance": "none",
            margin: 0,
          },
          /* Firefox */
          "input[type=number]": {
            "-moz-appearance": "textfield",
          },
        },
      },
    },
  },
});
