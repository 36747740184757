import * as Sentry from "@sentry/react";

export function captureAndLogException(
  level: Sentry.SeverityLevel,
  error: Error | string,
  { context, tags }: Record<string, any> = {}
) {
  if (!error) {
    return;
  }

  Sentry.withScope((scope) => {
    scope.setLevel(level);
    scope.setExtras({
      error,
      context,
    });

    const allTags = Object.assign({}, tags);
    Object.keys(allTags).forEach((tag) => scope.setTag(tag, allTags[tag]));
    scope.setExtra("tags", allTags);

    Sentry.captureException(error);
  });
}

export function captureException(
  error: any,
  context: Record<string, any> = {},
  tags: Record<string, string> = {}
) {
  if (error == null) {
    return;
  }

  Sentry.withScope((scope) => {
    scope.setLevel("error");
    scope.setExtra("Error-Full-Object", error);
    scope.setExtra("Error-Context", context);

    const allTags = Object.assign({}, tags);
    Object.keys(allTags).forEach((tag) => scope.setTag(tag, allTags[tag]));
    scope.setExtra("tags", allTags);

    Sentry.captureException(error);
  });
}
