import { CircularProgress } from "@mui/material";

export default function FullScreenLoader() {
  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        background: "#03283C",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress color="secondary" />
    </div>
  );
}
