import { VirtualCurrencyAccessMethod } from "../dto/Transaction";

const env: "production" | "development" | "staging" =
  (process.env.REACT_APP_ENV as any) || "development";

export * from "./countries";
export * from "./crypto";
export * from "./states";

export const maxFileSize = 300000000;
export const acceptedFileTypes = [
  "application/pdf",
  "image/*",
  ".csv",
  ".doc",
  ".docx",
  ".xlsx",
  ".xls",
];

const cookieStorage = {
  domain: `.${
    location.hostname.includes("localhost")
      ? "localhost"
      : location.hostname.split(".").slice(-2).join(".")
  }`,
  secure: location.href.includes("https"),
};

const currencies = {
  development: {
    USD: {
      type: "fiat",
      enabled: true,
      paymentMethods: ["wire", "bill", "registered_account"],
    },
    CAD: {
      type: "fiat",
      enabled: true,
      paymentMethods: ["eft", "bill", "wire", "registered_account", "e_transfer"],
    },
    BTC: {
      type: "virtual_currency",
      enabled: true,
      paymentMethods: ["virtual_currency"],
    },
    ETH: {
      type: "virtual_currency",
      enabled: true,
      paymentMethods: ["virtual_currency"],
    },
    USDC: {
      type: "virtual_currency",
      enabled: true,
      paymentMethods: ["virtual_currency"],
    },
    XTZ: {
      type: "virtual_currency",
      enabled: true,
      paymentMethods: ["virtual_currency"],
    },
  },
  staging: {
    USD: {
      type: "fiat",
      enabled: true,
      paymentMethods: ["bill", "wire", "registered_account"],
    },
    CAD: {
      type: "fiat",
      enabled: true,
      paymentMethods: ["eft", "bill", "wire", "registered_account", "e_transfer"],
    },
    BTC: {
      type: "virtual_currency",
      enabled: true,
      paymentMethods: ["virtual_currency"],
    },
    ETH: {
      type: "virtual_currency",
      enabled: true,
      paymentMethods: ["virtual_currency"],
    },
    USDC: {
      type: "virtual_currency",
      enabled: true,
      paymentMethods: ["virtual_currency"],
    },
    XTZ: {
      type: "virtual_currency",
      enabled: true,
      paymentMethods: ["virtual_currency"],
    },
  },
  production: {
    USD: {
      type: "fiat",
      enabled: true,
      paymentMethods: ["bill", "wire", "registered_account"],
    },
    CAD: {
      type: "fiat",
      enabled: true,
      paymentMethods: ["eft", "bill", "wire", "registered_account", "e_transfer"],
    },
    BTC: {
      type: "virtual_currency",
      enabled: true,
      paymentMethods: ["virtual_currency"],
    },
    ETH: {
      type: "virtual_currency",
      enabled: true,
      paymentMethods: ["virtual_currency"],
    },
    USDC: {
      type: "virtual_currency",
      enabled: true,
      paymentMethods: ["virtual_currency"],
    },
    XTZ: {
      type: "virtual_currency",
      enabled: true,
      paymentMethods: ["virtual_currency"],
    },
  },
}[process.env.REACT_APP_ENV || "development"];

export const config = {
  development: {
    env,
    idVerificationProvider: "veriff",
    idVerificationRequired: false,
    cognito: {
      region: "us-east-1",
      userPoolId: "us-east-1_JL9tPehMP",
      userPoolWebClientId: "nqlv944h5deg080fnjufrbhul",
    },
    google: {
      clientId: "967515544816-tqutdpqdnplp3o5m98e5bqfjn9isuonp.apps.googleusercontent.com",
      mapsApiKey: "AIzaSyBx99RRyR-Xj0sZQ8z3QwhcD1uAJ1ra7O0",
      pickerAPIKey: "AIzaSyAXvtzuCPCjDAzHTIjiZy1weQcwU2SojAk"
    },
    websiteDomain: "https://localhost:3001",
    assetsURL: "https://assets.atlasone-staging.ca",
    baseClientURL: "http://localhost:3000",
    cookieStorage,
    api: {
      baseURL: "http://localhost:5000",
      httpURI: "http://localhost:5000/graphql",
      wsURI: "ws://localhost:5000/graphql/subscriptions",
    },
    paymentMethods: {
      eft: true,
      wire: true,
      bill: true,
      registered_account: true,
      pad: false,
      virtual_currency: true,
    },
    currencies,
    recaptcha: {
      siteKey: "6Ld_QdAaAAAAAK4kAeZBdX5hus65i9siH3toXu98",
    },
    docusign: {
      clientUrl: "https://appdemo.docusign.com",
    },
    veriff: {
      clientUrl: "https://station.veriff.com",
      publishableKey: "60528d71-2e9e-47eb-9677-fa4cf4b49d44",
    },
    virtualCurrencyAccessMethods: {
      approved_provider: true,
      whitelisted_wallet: true,
      acquire_digital_currency: true,
    } as { [key in VirtualCurrencyAccessMethod]: boolean },
    gtmId: "",
  },
  staging: {
    env,
    idVerificationProvider: "veriff",
    idVerificationRequired: false,
    sentryDSN:
      "https://54380d717e0b4c27a9d5d8e0e69bf8a5@o436809.ingest.us.sentry.io/5398485",
    cognito: {
      region: "us-east-1",
      userPoolId: "us-east-1_JL9tPehMP",
      userPoolWebClientId: "nqlv944h5deg080fnjufrbhul",
    },
    google: {
      mapsApiKey: "AIzaSyD4ak87TXlwvsvpzAvFglySQN6HdEZ9pEU",
      clientId: "967515544816-tqutdpqdnplp3o5m98e5bqfjn9isuonp.apps.googleusercontent.com",
      pickerAPIKey: "AIzaSyAXvtzuCPCjDAzHTIjiZy1weQcwU2SojAk"
    },
    websiteDomain: "https://atlasone-staging.ca",
    assetsURL: "https://assets.atlasone-staging.ca",
    baseClientURL: "https://invest.atlasone-staging.ca",
    cookieStorage,
    api: {
      baseURL: "https://api.atlasone-staging.ca/invest",
      httpURI: "https://api.atlasone-staging.ca/invest/graphql",
      wsURI: "wss://api.atlasone-staging.ca/invest/graphql/subscriptions",
    },
    paymentMethods: {
      eft: true,
      wire: true,
      bill: true,
      registered_account: true,
      pad: false,
      virtual_currency: true,
    },
    currencies,
    recaptcha: {
      siteKey: "6Ld_QdAaAAAAAK4kAeZBdX5hus65i9siH3toXu98",
    },
    docusign: {
      clientUrl: "https://appdemo.docusign.com",
    },
    veriff: {
      clientUrl: "https://station.veriff.com",
      publishableKey: "60528d71-2e9e-47eb-9677-fa4cf4b49d44",
    },
    virtualCurrencyAccessMethods: {
      approved_provider: false,
      whitelisted_wallet: true,
      acquire_digital_currency: false,
    } as { [key in VirtualCurrencyAccessMethod]: boolean },
    gtmId: "GTM-T3NH9RD",
  },
  production: {
    env,
    idVerificationProvider: "veriff",
    idVerificationRequired: true,
    sentryDSN:
      "https://54380d717e0b4c27a9d5d8e0e69bf8a5@o436809.ingest.us.sentry.io/5398485",
    cognito: {
      region: "us-east-1",
      userPoolId: "us-east-1_4CNkGtz6W",
      userPoolWebClientId: "662e856f7hkfeds8ff5rk306vd",
    },
    google: {
      clientId: "967515544816-tqutdpqdnplp3o5m98e5bqfjn9isuonp.apps.googleusercontent.com",
      mapsApiKey: "AIzaSyD4ak87TXlwvsvpzAvFglySQN6HdEZ9pEU",
      pickerAPIKey: "AIzaSyAXvtzuCPCjDAzHTIjiZy1weQcwU2SojAk"
    },
    websiteDomain: "https://atlasone.ca",
    assetsURL: "https://assets.atlasone.ca",
    baseClientURL: "https://invest.atlasone.ca",
    cookieStorage,
    api: {
      baseURL: "https://api.atlasone.ca/invest",
      httpURI: "https://api.atlasone.ca/invest/graphql",
      wsURI: "wss://api.atlasone.ca/invest/graphql/subscriptions",
    },
    paymentMethods: {
      eft: true,
      wire: true,
      bill: true,
      registered_account: true,
      pad: false,
      virtual_currency: true,
    },
    currencies,
    recaptcha: {
      siteKey: "6Ld_QdAaAAAAAK4kAeZBdX5hus65i9siH3toXu98",
    },
    docusign: {
      clientUrl: "https://app.docusign.com",
    },
    veriff: {
      clientUrl: "https://station.veriff.com",
      publishableKey: "01ddee5f-33ff-4488-9f51-132170f4aa67",
    },
    virtualCurrencyAccessMethods: {
      approved_provider: false,
      whitelisted_wallet: true,
      acquire_digital_currency: false,
    } as { [key in VirtualCurrencyAccessMethod]: boolean },
    gtmId: "GTM-T6HXGK8",
  },
}[env];
