// https://github.com/stefanbinder/countries-states/blob/master/countries.json
export const countryStates = [
  {
    code2: "AF",
    code3: "AFG",
    name: "Afghanistan",
    capital: "Kabul",
    region: "Asia",
    subregion: "Southern Asia",
    states: [
      {
        code: "BDS",
        name: "Badakhshān",
      },
      {
        code: "BGL",
        name: "Baghlān",
      },
      {
        code: "BAL",
        name: "Balkh",
      },
      {
        code: "BDG",
        name: "Bādghīs",
      },
      {
        code: "BAM",
        name: "Bāmyān",
      },
      {
        code: "DAY",
        name: "Dāykundī",
      },
      {
        code: "FRA",
        name: "Farāh",
      },
      {
        code: "FYB",
        name: "Fāryāb",
      },
      {
        code: "GHA",
        name: "Ghaznī",
      },
      {
        code: "GHO",
        name: "Ghōr",
      },
      {
        code: "HEL",
        name: "Helmand",
      },
      {
        code: "HER",
        name: "Herāt",
      },
      {
        code: "JOW",
        name: "Jowzjān",
      },
      {
        code: "KAN",
        name: "Kandahār",
      },
      {
        code: "KHO",
        name: "Khōst",
      },
      {
        code: "KNR",
        name: "Kunar",
      },
      {
        code: "KDZ",
        name: "Kunduz",
      },
      {
        code: "KAB",
        name: "Kābul",
      },
      {
        code: "KAP",
        name: "Kāpīsā",
      },
      {
        code: "LAG",
        name: "Laghmān",
      },
      {
        code: "LOG",
        name: "Lōgar",
      },
      {
        code: "NAN",
        name: "Nangarhār",
      },
      {
        code: "NIM",
        name: "Nīmrōz",
      },
      {
        code: "NUR",
        name: "Nūristān",
      },
      {
        code: "PIA",
        name: "Paktiyā",
      },
      {
        code: "PKA",
        name: "Paktīkā",
      },
      {
        code: "PAN",
        name: "Panjshayr",
      },
      {
        code: "PAR",
        name: "Parwān",
      },
      {
        code: "SAM",
        name: "Samangān",
      },
      {
        code: "SAR",
        name: "Sar-e Pul",
      },
      {
        code: "TAK",
        name: "Takhār",
      },
      {
        code: "URU",
        name: "Uruzgān",
      },
      {
        code: "WAR",
        name: "Wardak",
      },
      {
        code: "ZAB",
        name: "Zābul",
      },
    ],
  },
  {
    code2: "AX",
    code3: "ALA",
    name: "Åland Islands",
    capital: "Mariehamn",
    region: "Europe",
    subregion: "Northern Europe",
    states: [],
  },
  {
    code2: "AL",
    code3: "ALB",
    name: "Albania",
    capital: "Tirana",
    region: "Europe",
    subregion: "Southern Europe",
    states: [
      {
        code: "01",
        name: "Berat",
      },
      {
        code: "09",
        name: "Dibër",
      },
      {
        code: "02",
        name: "Durrës",
      },
      {
        code: "03",
        name: "Elbasan",
      },
      {
        code: "04",
        name: "Fier",
      },
      {
        code: "05",
        name: "Gjirokastër",
      },
      {
        code: "06",
        name: "Korçë",
      },
      {
        code: "07",
        name: "Kukës",
      },
      {
        code: "08",
        name: "Lezhë",
      },
      {
        code: "10",
        name: "Shkodër",
      },
      {
        code: "11",
        name: "Tiranë",
      },
      {
        code: "12",
        name: "Vlorë",
      },
    ],
  },
  {
    code2: "DZ",
    code3: "DZA",
    name: "Algeria",
    capital: "Algiers",
    region: "Africa",
    subregion: "Northern Africa",
    states: [
      {
        code: "01",
        name: "Adrar",
      },
      {
        code: "16",
        name: "Alger",
      },
      {
        code: "23",
        name: "Annaba",
      },
      {
        code: "44",
        name: "Aïn Defla",
      },
      {
        code: "46",
        name: "Aïn Témouchent",
      },
      {
        code: "05",
        name: "Batna",
      },
      {
        code: "07",
        name: "Biskra",
      },
      {
        code: "09",
        name: "Blida",
      },
      {
        code: "34",
        name: "Bordj Bou Arréridj",
      },
      {
        code: "10",
        name: "Bouira",
      },
      {
        code: "35",
        name: "Boumerdès",
      },
      {
        code: "08",
        name: "Béchar",
      },
      {
        code: "06",
        name: "Béjaïa",
      },
      {
        code: "02",
        name: "Chlef",
      },
      {
        code: "25",
        name: "Constantine",
      },
      {
        code: "17",
        name: "Djelfa",
      },
      {
        code: "32",
        name: "El Bayadh",
      },
      {
        code: "39",
        name: "El Oued",
      },
      {
        code: "36",
        name: "El Tarf",
      },
      {
        code: "47",
        name: "Ghardaïa",
      },
      {
        code: "24",
        name: "Guelma",
      },
      {
        code: "33",
        name: "Illizi",
      },
      {
        code: "18",
        name: "Jijel",
      },
      {
        code: "40",
        name: "Khenchela",
      },
      {
        code: "03",
        name: "Laghouat",
      },
      {
        code: "29",
        name: "Mascara",
      },
      {
        code: "43",
        name: "Mila",
      },
      {
        code: "27",
        name: "Mostaganem",
      },
      {
        code: "28",
        name: "Msila",
      },
      {
        code: "26",
        name: "Médéa",
      },
      {
        code: "45",
        name: "Naama",
      },
      {
        code: "31",
        name: "Oran",
      },
      {
        code: "30",
        name: "Ouargla",
      },
      {
        code: "04",
        name: "Oum el Bouaghi",
      },
      {
        code: "48",
        name: "Relizane",
      },
      {
        code: "20",
        name: "Saïda",
      },
      {
        code: "22",
        name: "Sidi Bel Abbès",
      },
      {
        code: "21",
        name: "Skikda",
      },
      {
        code: "41",
        name: "Souk Ahras",
      },
      {
        code: "19",
        name: "Sétif",
      },
      {
        code: "11",
        name: "Tamanghasset",
      },
      {
        code: "14",
        name: "Tiaret",
      },
      {
        code: "37",
        name: "Tindouf",
      },
      {
        code: "42",
        name: "Tipaza",
      },
      {
        code: "38",
        name: "Tissemsilt",
      },
      {
        code: "15",
        name: "Tizi Ouzou",
      },
      {
        code: "13",
        name: "Tlemcen",
      },
      {
        code: "12",
        name: "Tébessa",
      },
    ],
  },
  {
    code2: "AS",
    code3: "ASM",
    name: "American Samoa",
    capital: "Pago Pago",
    region: "Oceania",
    subregion: "Polynesia",
    states: [],
  },
  {
    code2: "AD",
    code3: "AND",
    name: "Andorra",
    capital: "Andorra la Vella",
    region: "Europe",
    subregion: "Southern Europe",
    states: [
      {
        code: "07",
        name: "Andorra la Vella",
      },
      {
        code: "02",
        name: "Canillo",
      },
      {
        code: "03",
        name: "Encamp",
      },
      {
        code: "08",
        name: "Escaldes-Engordany",
      },
      {
        code: "04",
        name: "La Massana",
      },
      {
        code: "05",
        name: "Ordino",
      },
      {
        code: "06",
        name: "Sant Julià de Lòria",
      },
    ],
  },
  {
    code2: "AO",
    code3: "AGO",
    name: "Angola",
    capital: "Luanda",
    region: "Africa",
    subregion: "Middle Africa",
    states: [
      {
        code: "BGO",
        name: "Bengo",
      },
      {
        code: "BGU",
        name: "Benguela",
      },
      {
        code: "BIE",
        name: "Bié",
      },
      {
        code: "CAB",
        name: "Cabinda",
      },
      {
        code: "CNN",
        name: "Cunene",
      },
      {
        code: "HUA",
        name: "Huambo",
      },
      {
        code: "HUI",
        name: "Huíla",
      },
      {
        code: "CCU",
        name: "Kuando Kubango",
      },
      {
        code: "CNO",
        name: "Kwanza Norte",
      },
      {
        code: "CUS",
        name: "Kwanza Sul",
      },
      {
        code: "LUA",
        name: "Luanda",
      },
      {
        code: "LNO",
        name: "Lunda Norte",
      },
      {
        code: "LSU",
        name: "Lunda Sul",
      },
      {
        code: "MAL",
        name: "Malange",
      },
      {
        code: "MOX",
        name: "Moxico",
      },
      {
        code: "NAM",
        name: "Namibe",
      },
      {
        code: "UIG",
        name: "Uíge",
      },
      {
        code: "ZAI",
        name: "Zaire",
      },
    ],
  },
  {
    code2: "AI",
    code3: "AIA",
    name: "Anguilla",
    capital: "The Valley",
    region: "Americas",
    subregion: "Caribbean",
    states: [],
  },
  {
    code2: "AQ",
    code3: "ATA",
    name: "Antarctica",
    capital: "",
    region: "Polar",
    subregion: "",
    states: [],
  },
  {
    code2: "AG",
    code3: "ATG",
    name: "Antigua and Barbuda",
    capital: "Saint John's",
    region: "Americas",
    subregion: "Caribbean",
    states: [
      {
        code: "10",
        name: "Barbuda",
      },
      {
        code: "11",
        name: "Redonda",
      },
      {
        code: "03",
        name: "Saint George",
      },
      {
        code: "04",
        name: "Saint John",
      },
      {
        code: "05",
        name: "Saint Mary",
      },
      {
        code: "06",
        name: "Saint Paul",
      },
      {
        code: "07",
        name: "Saint Peter",
      },
      {
        code: "08",
        name: "Saint Philip",
      },
    ],
  },
  {
    code2: "AR",
    code3: "ARG",
    name: "Argentina",
    capital: "Buenos Aires",
    region: "Americas",
    subregion: "South America",
    states: [
      {
        code: "B",
        name: "Buenos Aires",
      },
      {
        code: "K",
        name: "Catamarca",
      },
      {
        code: "H",
        name: "Chaco",
      },
      {
        code: "U",
        name: "Chubut",
      },
      {
        code: "C",
        name: "Ciudad Autónoma de Buenos Aires",
      },
      {
        code: "W",
        name: "Corrientes",
      },
      {
        code: "X",
        name: "Córdoba",
      },
      {
        code: "E",
        name: "Entre Ríos",
      },
      {
        code: "P",
        name: "Formosa",
      },
      {
        code: "Y",
        name: "Jujuy",
      },
      {
        code: "L",
        name: "La Pampa",
      },
      {
        code: "F",
        name: "La Rioja",
      },
      {
        code: "M",
        name: "Mendoza",
      },
      {
        code: "N",
        name: "Misiones",
      },
      {
        code: "Q",
        name: "Neuquén",
      },
      {
        code: "R",
        name: "Río Negro",
      },
      {
        code: "A",
        name: "Salta",
      },
      {
        code: "J",
        name: "San Juan",
      },
      {
        code: "D",
        name: "San Luis",
      },
      {
        code: "Z",
        name: "Santa Cruz",
      },
      {
        code: "S",
        name: "Santa Fe",
      },
      {
        code: "G",
        name: "Santiago del Estero",
      },
      {
        code: "V",
        name: "Tierra del Fuego",
      },
      {
        code: "T",
        name: "Tucumán",
      },
    ],
  },
  {
    code2: "AM",
    code3: "ARM",
    name: "Armenia",
    capital: "Yerevan",
    region: "Asia",
    subregion: "Western Asia",
    states: [
      {
        code: "AG",
        name: "Aragac̣otn",
      },
      {
        code: "AR",
        name: "Ararat",
      },
      {
        code: "AV",
        name: "Armavir",
      },
      {
        code: "ER",
        name: "Erevan",
      },
      {
        code: "GR",
        name: "Geġark'unik'",
      },
      {
        code: "KT",
        name: "Kotayk'",
      },
      {
        code: "LO",
        name: "Loṙi",
      },
      {
        code: "SU",
        name: "Syunik'",
      },
      {
        code: "TV",
        name: "Tavuš",
      },
      {
        code: "VD",
        name: "Vayoć Jor",
      },
      {
        code: "SH",
        name: "Širak",
      },
    ],
  },
  {
    code2: "AW",
    code3: "ABW",
    name: "Aruba",
    capital: "Oranjestad",
    region: "Americas",
    subregion: "Caribbean",
    states: [],
  },
  {
    code2: "AU",
    code3: "AUS",
    name: "Australia",
    capital: "Canberra",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    states: [
      {
        code: "ACT",
        name: "Australian Capital Territory",
      },
      {
        code: "NSW",
        name: "New South Wales",
      },
      {
        code: "NT",
        name: "Northern Territory",
      },
      {
        code: "QLD",
        name: "Queensland",
      },
      {
        code: "SA",
        name: "South Australia",
      },
      {
        code: "TAS",
        name: "Tasmania",
      },
      {
        code: "VIC",
        name: "Victoria",
      },
      {
        code: "WA",
        name: "Western Australia",
      },
    ],
  },
  {
    code2: "AT",
    code3: "AUT",
    name: "Austria",
    capital: "Vienna",
    region: "Europe",
    subregion: "Western Europe",
    states: [
      {
        code: "B",
        name: "Burgenland",
      },
      {
        code: "K",
        name: "Kärnten",
      },
      {
        code: "NÖ",
        name: "Niederösterreich",
      },
      {
        code: "OÖ",
        name: "Oberösterreich",
      },
      {
        code: "S",
        name: "Salzburg",
      },
      {
        code: "ST",
        name: "Steiermark",
      },
      {
        code: "T",
        name: "Tirol",
      },
      {
        code: "V",
        name: "Vorarlberg",
      },
      {
        code: "W",
        name: "Wien",
      },
    ],
  },
  {
    code2: "AZ",
    code3: "AZE",
    name: "Azerbaijan",
    capital: "Baku",
    region: "Asia",
    subregion: "Western Asia",
    states: [
      {
        code: "NX",
        name: "Naxçıvan",
      },
    ],
  },
  {
    code2: "BS",
    code3: "BHS",
    name: "The Bahamas",
    capital: "Nassau",
    region: "Americas",
    subregion: "Caribbean",
    states: [
      {
        code: "AK",
        name: "Acklins",
      },
      {
        code: "BY",
        name: "Berry Islands",
      },
      {
        code: "BI",
        name: "Bimini",
      },
      {
        code: "BP",
        name: "Black Point",
      },
      {
        code: "CI",
        name: "Cat Island",
      },
      {
        code: "CO",
        name: "Central Abaco",
      },
      {
        code: "CS",
        name: "Central Andros",
      },
      {
        code: "CE",
        name: "Central Eleuthera",
      },
      {
        code: "FP",
        name: "City of Freeport",
      },
      {
        code: "CK",
        name: "Crooked Island and Long Cay",
      },
      {
        code: "EG",
        name: "East Grand Bahama",
      },
      {
        code: "EX",
        name: "Exuma",
      },
      {
        code: "GC",
        name: "Grand Cay",
      },
      {
        code: "HI",
        name: "Harbour Island",
      },
      {
        code: "HT",
        name: "Hope Town",
      },
      {
        code: "IN",
        name: "Inagua",
      },
      {
        code: "LI",
        name: "Long Island",
      },
      {
        code: "MC",
        name: "Mangrove Cay",
      },
      {
        code: "MG",
        name: "Mayaguana",
      },
      {
        code: "MI",
        name: "Moores Island",
      },
      {
        code: "NO",
        name: "North Abaco",
      },
      {
        code: "NS",
        name: "North Andros",
      },
      {
        code: "NE",
        name: "North Eleuthera",
      },
      {
        code: "RI",
        name: "Ragged Island",
      },
      {
        code: "RC",
        name: "Rum Cay",
      },
      {
        code: "SS",
        name: "San Salvador",
      },
      {
        code: "SO",
        name: "South Abaco",
      },
      {
        code: "SA",
        name: "South Andros",
      },
      {
        code: "SE",
        name: "South Eleuthera",
      },
      {
        code: "SW",
        name: "Spanish Wells",
      },
      {
        code: "WG",
        name: "West Grand Bahama",
      },
    ],
  },
  {
    code2: "BH",
    code3: "BHR",
    name: "Bahrain",
    capital: "Manama",
    region: "Asia",
    subregion: "Western Asia",
    states: [
      {
        code: "14",
        name: "Al Janūbīyah",
      },
      {
        code: "13",
        name: "Al Manāmah",
      },
      {
        code: "15",
        name: "Al Muḩarraq",
      },
      {
        code: "16",
        name: "Al Wusţá",
      },
      {
        code: "17",
        name: "Ash Shamālīyah",
      },
    ],
  },
  {
    code2: "BD",
    code3: "BGD",
    name: "Bangladesh",
    capital: "Dhaka",
    region: "Asia",
    subregion: "Southern Asia",
    states: [
      {
        code: "A",
        name: "Barisal",
      },
      {
        code: "B",
        name: "Chittagong",
      },
      {
        code: "C",
        name: "Dhaka",
      },
      {
        code: "D",
        name: "Khulna",
      },
      {
        code: "E",
        name: "Rajshahi",
      },
      {
        code: "F",
        name: "Rangpur",
      },
      {
        code: "G",
        name: "Sylhet",
      },
    ],
  },
  {
    code2: "BB",
    code3: "BRB",
    name: "Barbados",
    capital: "Bridgetown",
    region: "Americas",
    subregion: "Caribbean",
    states: [
      {
        code: "01",
        name: "Christ Church",
      },
      {
        code: "02",
        name: "Saint Andrew",
      },
      {
        code: "03",
        name: "Saint George",
      },
      {
        code: "04",
        name: "Saint James",
      },
      {
        code: "05",
        name: "Saint John",
      },
      {
        code: "06",
        name: "Saint Joseph",
      },
      {
        code: "07",
        name: "Saint Lucy",
      },
      {
        code: "08",
        name: "Saint Michael",
      },
      {
        code: "09",
        name: "Saint Peter",
      },
      {
        code: "10",
        name: "Saint Philip",
      },
      {
        code: "11",
        name: "Saint Thomas",
      },
    ],
  },
  {
    code2: "BY",
    code3: "BLR",
    name: "Belarus",
    capital: "Minsk",
    region: "Europe",
    subregion: "Eastern Europe",
    states: [
      {
        code: "BR",
        name: "Brestskaya voblasts'",
      },
      {
        code: "HO",
        name: "Homyel'skaya voblasts'",
      },
      {
        code: "HM",
        name: "Horad Minsk",
      },
      {
        code: "HR",
        name: "Hrodzenskaya voblasts'",
      },
      {
        code: "MA",
        name: "Mahilyowskaya voblasts'",
      },
      {
        code: "MI",
        name: "Minskaya voblasts'",
      },
      {
        code: "VI",
        name: "Vitsyebskaya voblasts'",
      },
    ],
  },
  {
    code2: "BE",
    code3: "BEL",
    name: "Belgium",
    capital: "Brussels",
    region: "Europe",
    subregion: "Western Europe",
    states: [
      {
        code: "BRU",
        name: "Brussels Hoofdstedelijk Gewest",
      },
      {
        code: "WAL",
        name: "Région Wallonne",
      },
      {
        code: "VLG",
        name: "Vlaams Gewest",
      },
    ],
  },
  {
    code2: "BZ",
    code3: "BLZ",
    name: "Belize",
    capital: "Belmopan",
    region: "Americas",
    subregion: "Central America",
    states: [
      {
        code: "BZ",
        name: "Belize",
      },
      {
        code: "CY",
        name: "Cayo",
      },
      {
        code: "CZL",
        name: "Corozal",
      },
      {
        code: "OW",
        name: "Orange Walk",
      },
      {
        code: "SC",
        name: "Stann Creek",
      },
      {
        code: "TOL",
        name: "Toledo",
      },
    ],
  },
  {
    code2: "BJ",
    code3: "BEN",
    name: "Benin",
    capital: "Porto-Novo",
    region: "Africa",
    subregion: "Western Africa",
    states: [
      {
        code: "AL",
        name: "Alibori",
      },
      {
        code: "AK",
        name: "Atakora",
      },
      {
        code: "AQ",
        name: "Atlantique",
      },
      {
        code: "BO",
        name: "Borgou",
      },
      {
        code: "CO",
        name: "Collines",
      },
      {
        code: "DO",
        name: "Donga",
      },
      {
        code: "KO",
        name: "Kouffo",
      },
      {
        code: "LI",
        name: "Littoral",
      },
      {
        code: "MO",
        name: "Mono",
      },
      {
        code: "OU",
        name: "Ouémé",
      },
      {
        code: "PL",
        name: "Plateau",
      },
      {
        code: "ZO",
        name: "Zou",
      },
    ],
  },
  {
    code2: "BM",
    code3: "BMU",
    name: "Bermuda",
    capital: "Hamilton",
    region: "Americas",
    subregion: "Northern America",
    states: [],
  },
  {
    code2: "BT",
    code3: "BTN",
    name: "Bhutan",
    capital: "Thimphu",
    region: "Asia",
    subregion: "Southern Asia",
    states: [
      {
        code: "33",
        name: "Bumthang",
      },
      {
        code: "12",
        name: "Chhukha",
      },
      {
        code: "22",
        name: "Dagana",
      },
      {
        code: "GA",
        name: "Gasa",
      },
      {
        code: "13",
        name: "Ha",
      },
      {
        code: "44",
        name: "Lhuentse",
      },
      {
        code: "42",
        name: "Monggar",
      },
      {
        code: "11",
        name: "Paro",
      },
      {
        code: "43",
        name: "Pemagatshel",
      },
      {
        code: "23",
        name: "Punakha",
      },
      {
        code: "45",
        name: "Samdrup Jongkha",
      },
      {
        code: "14",
        name: "Samtse",
      },
      {
        code: "31",
        name: "Sarpang",
      },
      {
        code: "15",
        name: "Thimphu",
      },
      {
        code: "TY",
        name: "Trashi Yangtse",
      },
      {
        code: "41",
        name: "Trashigang",
      },
      {
        code: "32",
        name: "Trongsa",
      },
      {
        code: "21",
        name: "Tsirang",
      },
      {
        code: "24",
        name: "Wangdue Phodrang",
      },
      {
        code: "34",
        name: "Zhemgang",
      },
    ],
  },
  {
    code2: "BO",
    code3: "BOL",
    name: "Bolivia",
    capital: "Sucre",
    region: "Americas",
    subregion: "South America",
    states: [
      {
        code: "H",
        name: "Chuquisaca",
      },
      {
        code: "C",
        name: "Cochabamba",
      },
      {
        code: "B",
        name: "El Beni",
      },
      {
        code: "L",
        name: "La Paz",
      },
      {
        code: "O",
        name: "Oruro",
      },
      {
        code: "N",
        name: "Pando",
      },
      {
        code: "P",
        name: "Potosí",
      },
      {
        code: "S",
        name: "Santa Cruz",
      },
      {
        code: "T",
        name: "Tarija",
      },
    ],
  },
  {
    code2: "BQ",
    code3: "BES",
    name: "Bonaire",
    capital: "Kralendijk",
    region: "Americas",
    subregion: "Caribbean",
    states: [],
  },
  {
    code2: "BA",
    code3: "BIH",
    name: "Bosnia and Herzegovina",
    capital: "Sarajevo",
    region: "Europe",
    subregion: "Southern Europe",
    states: [
      {
        code: "BRC",
        name: "Brčko distrikt",
      },
      {
        code: "BIH",
        name: "Federacija Bosna i Hercegovina",
      },
      {
        code: "SRP",
        name: "Republika Srpska",
      },
    ],
  },
  {
    code2: "BW",
    code3: "BWA",
    name: "Botswana",
    capital: "Gaborone",
    region: "Africa",
    subregion: "Southern Africa",
    states: [
      {
        code: "CE",
        name: "Central",
      },
      {
        code: "CH",
        name: "Chobe",
      },
      {
        code: "FR",
        name: "Francistown",
      },
      {
        code: "GA",
        name: "Gaborone",
      },
      {
        code: "GH",
        name: "Ghanzi",
      },
      {
        code: "JW",
        name: "Jwaneng",
      },
      {
        code: "KG",
        name: "Kgalagadi",
      },
      {
        code: "KL",
        name: "Kgatleng",
      },
      {
        code: "KW",
        name: "Kweneng",
      },
      {
        code: "LO",
        name: "Lobatse",
      },
      {
        code: "NE",
        name: "North-East",
      },
      {
        code: "NW",
        name: "North-West",
      },
      {
        code: "SP",
        name: "Selibe Phikwe",
      },
      {
        code: "SE",
        name: "South-East",
      },
      {
        code: "SO",
        name: "Southern",
      },
      {
        code: "ST",
        name: "Sowa Town",
      },
    ],
  },
  {
    code2: "BV",
    code3: "BVT",
    name: "Bouvet Island",
    capital: "",
    region: "",
    subregion: "",
    states: [],
  },
  {
    code2: "BR",
    code3: "BRA",
    name: "Brazil",
    capital: "Brasília",
    region: "Americas",
    subregion: "South America",
    states: [
      {
        code: "AC",
        name: "Acre",
      },
      {
        code: "AL",
        name: "Alagoas",
      },
      {
        code: "AP",
        name: "Amapá",
      },
      {
        code: "AM",
        name: "Amazonas",
      },
      {
        code: "BA",
        name: "Bahia",
      },
      {
        code: "CE",
        name: "Ceará",
      },
      {
        code: "DF",
        name: "Distrito Federal",
      },
      {
        code: "ES",
        name: "Espírito Santo",
      },
      {
        code: "GO",
        name: "Goiás",
      },
      {
        code: "MA",
        name: "Maranhão",
      },
      {
        code: "MT",
        name: "Mato Grosso",
      },
      {
        code: "MS",
        name: "Mato Grosso do Sul",
      },
      {
        code: "MG",
        name: "Minas Gerais",
      },
      {
        code: "PR",
        name: "Paraná",
      },
      {
        code: "PB",
        name: "Paraíba",
      },
      {
        code: "PA",
        name: "Pará",
      },
      {
        code: "PE",
        name: "Pernambuco",
      },
      {
        code: "PI",
        name: "Piauí",
      },
      {
        code: "RN",
        name: "Rio Grande do Norte",
      },
      {
        code: "RS",
        name: "Rio Grande do Sul",
      },
      {
        code: "RJ",
        name: "Rio de Janeiro",
      },
      {
        code: "RO",
        name: "Rondônia",
      },
      {
        code: "RR",
        name: "Roraima",
      },
      {
        code: "SC",
        name: "Santa Catarina",
      },
      {
        code: "SE",
        name: "Sergipe",
      },
      {
        code: "SP",
        name: "São Paulo",
      },
      {
        code: "TO",
        name: "Tocantins",
      },
    ],
  },
  {
    code2: "IO",
    code3: "IOT",
    name: "British Indian Ocean Territory",
    capital: "Diego Garcia",
    region: "Africa",
    subregion: "Eastern Africa",
    states: [],
  },
  {
    code2: "UM",
    code3: "UMI",
    name: "United States Minor Outlying Islands",
    capital: "",
    region: "Americas",
    subregion: "Northern America",
    states: [
      {
        code: "81",
        name: "Baker Island",
      },
      {
        code: "84",
        name: "Howland Island",
      },
      {
        code: "86",
        name: "Jarvis Island",
      },
      {
        code: "67",
        name: "Johnston Atoll",
      },
      {
        code: "89",
        name: "Kingman Reef",
      },
      {
        code: "71",
        name: "Midway Islands",
      },
      {
        code: "76",
        name: "Navassa Island",
      },
      {
        code: "95",
        name: "Palmyra Atoll",
      },
      {
        code: "79",
        name: "Wake Island",
      },
    ],
  },
  {
    code2: "VG",
    code3: "VGB",
    name: "Virgin Islands (British)",
    capital: "Road Town",
    region: "Americas",
    subregion: "Caribbean",
    states: [],
  },
  {
    code2: "VI",
    code3: "VIR",
    name: "Virgin Islands (U.S.)",
    capital: "Charlotte Amalie",
    region: "Americas",
    subregion: "Caribbean",
    states: [],
  },
  {
    code2: "BN",
    code3: "BRN",
    name: "Brunei",
    capital: "Bandar Seri Begawan",
    region: "Asia",
    subregion: "South-Eastern Asia",
    states: [
      {
        code: "BE",
        name: "Belait",
      },
      {
        code: "BM",
        name: "Brunei-Muara",
      },
      {
        code: "TE",
        name: "Temburong",
      },
      {
        code: "TU",
        name: "Tutong",
      },
    ],
  },
  {
    code2: "BG",
    code3: "BGR",
    name: "Bulgaria",
    capital: "Sofia",
    region: "Europe",
    subregion: "Eastern Europe",
    states: [
      {
        code: "01",
        name: "Blagoevgrad",
      },
      {
        code: "02",
        name: "Burgas",
      },
      {
        code: "08",
        name: "Dobrich",
      },
      {
        code: "07",
        name: "Gabrovo",
      },
      {
        code: "26",
        name: "Haskovo",
      },
      {
        code: "09",
        name: "Kardzhali",
      },
      {
        code: "10",
        name: "Kyustendil",
      },
      {
        code: "11",
        name: "Lovech",
      },
      {
        code: "12",
        name: "Montana",
      },
      {
        code: "13",
        name: "Pazardzhik",
      },
      {
        code: "14",
        name: "Pernik",
      },
      {
        code: "15",
        name: "Pleven",
      },
      {
        code: "16",
        name: "Plovdiv",
      },
      {
        code: "17",
        name: "Razgrad",
      },
      {
        code: "18",
        name: "Ruse",
      },
      {
        code: "27",
        name: "Shumen",
      },
      {
        code: "19",
        name: "Silistra",
      },
      {
        code: "20",
        name: "Sliven",
      },
      {
        code: "21",
        name: "Smolyan",
      },
      {
        code: "23",
        name: "Sofia",
      },
      {
        code: "22",
        name: "Sofia-Grad",
      },
      {
        code: "24",
        name: "Stara Zagora",
      },
      {
        code: "25",
        name: "Targovishte",
      },
      {
        code: "03",
        name: "Varna",
      },
      {
        code: "04",
        name: "Veliko Tarnovo",
      },
      {
        code: "05",
        name: "Vidin",
      },
      {
        code: "06",
        name: "Vratsa",
      },
      {
        code: "28",
        name: "Yambol",
      },
    ],
  },
  {
    code2: "BF",
    code3: "BFA",
    name: "Burkina Faso",
    capital: "Ouagadougou",
    region: "Africa",
    subregion: "Western Africa",
    states: [
      {
        code: "01",
        name: "Boucle du Mouhoun",
      },
      {
        code: "02",
        name: "Cascades",
      },
      {
        code: "03",
        name: "Centre",
      },
      {
        code: "04",
        name: "Centre-Est",
      },
      {
        code: "05",
        name: "Centre-Nord",
      },
      {
        code: "06",
        name: "Centre-Ouest",
      },
      {
        code: "07",
        name: "Centre-Sud",
      },
      {
        code: "08",
        name: "Est",
      },
      {
        code: "09",
        name: "Hauts-Bassins",
      },
      {
        code: "10",
        name: "Nord",
      },
      {
        code: "11",
        name: "Plateau-Central",
      },
      {
        code: "12",
        name: "Sahel",
      },
      {
        code: "13",
        name: "Sud-Ouest",
      },
    ],
  },
  {
    code2: "BI",
    code3: "BDI",
    name: "Burundi",
    capital: "Bujumbura",
    region: "Africa",
    subregion: "Eastern Africa",
    states: [
      {
        code: "BB",
        name: "Bubanza",
      },
      {
        code: "BM",
        name: "Bujumbura Mairie",
      },
      {
        code: "BL",
        name: "Bujumbura Rural",
      },
      {
        code: "BR",
        name: "Bururi",
      },
      {
        code: "CA",
        name: "Cankuzo",
      },
      {
        code: "CI",
        name: "Cibitoke",
      },
      {
        code: "GI",
        name: "Gitega",
      },
      {
        code: "KR",
        name: "Karuzi",
      },
      {
        code: "KY",
        name: "Kayanza",
      },
      {
        code: "KI",
        name: "Kirundo",
      },
      {
        code: "MA",
        name: "Makamba",
      },
      {
        code: "MU",
        name: "Muramvya",
      },
      {
        code: "MY",
        name: "Muyinga",
      },
      {
        code: "MW",
        name: "Mwaro",
      },
      {
        code: "NG",
        name: "Ngozi",
      },
      {
        code: "RT",
        name: "Rutana",
      },
      {
        code: "RY",
        name: "Ruyigi",
      },
    ],
  },
  {
    code2: "KH",
    code3: "KHM",
    name: "Cambodia",
    capital: "Phnom Penh",
    region: "Asia",
    subregion: "South-Eastern Asia",
    states: [
      {
        code: "2",
        name: "Baat Dambang",
      },
      {
        code: "1",
        name: "Banteay Mean Chey",
      },
      {
        code: "3",
        name: "Kampong Chaam",
      },
      {
        code: "4",
        name: "Kampong Chhnang",
      },
      {
        code: "5",
        name: "Kampong Spueu",
      },
      {
        code: "6",
        name: "Kampong Thum",
      },
      {
        code: "7",
        name: "Kampot",
      },
      {
        code: "8",
        name: "Kandaal",
      },
      {
        code: "9",
        name: "Kaoh Kong",
      },
      {
        code: "10",
        name: "Kracheh",
      },
      {
        code: "23",
        name: "Krong Kaeb",
      },
      {
        code: "24",
        name: "Krong Pailin",
      },
      {
        code: "18",
        name: "Krong Preah Sihanouk",
      },
      {
        code: "11",
        name: "Mondol Kiri",
      },
      {
        code: "22",
        name: "Otdar Mean Chey",
      },
      {
        code: "12",
        name: "Phnom Penh",
      },
      {
        code: "15",
        name: "Pousaat",
      },
      {
        code: "13",
        name: "Preah Vihear",
      },
      {
        code: "14",
        name: "Prey Veaeng",
      },
      {
        code: "16",
        name: "Rotanak Kiri",
      },
      {
        code: "17",
        name: "Siem Reab",
      },
      {
        code: "19",
        name: "Stueng Traeng",
      },
      {
        code: "20",
        name: "Svaay Rieng",
      },
      {
        code: "21",
        name: "Taakaev",
      },
    ],
  },
  {
    code2: "CM",
    code3: "CMR",
    name: "Cameroon",
    capital: "Yaoundé",
    region: "Africa",
    subregion: "Middle Africa",
    states: [
      {
        code: "AD",
        name: "Adamaoua",
      },
      {
        code: "CE",
        name: "Centre",
      },
      {
        code: "ES",
        name: "East",
      },
      {
        code: "EN",
        name: "Far North",
      },
      {
        code: "LT",
        name: "Littoral",
      },
      {
        code: "NO",
        name: "North",
      },
      {
        code: "NW",
        name: "North-West",
      },
      {
        code: "SU",
        name: "South",
      },
      {
        code: "SW",
        name: "South-West",
      },
      {
        code: "OU",
        name: "West",
      },
    ],
  },
  {
    code2: "CA",
    code3: "CAN",
    name: "Canada",
    capital: "Ottawa",
    region: "Americas",
    subregion: "Northern America",
    states: [
      {
        code: "AB",
        name: "Alberta",
      },
      {
        code: "BC",
        name: "British Columbia",
      },
      {
        code: "MB",
        name: "Manitoba",
      },
      {
        code: "NB",
        name: "New Brunswick",
      },
      {
        code: "NL",
        name: "Newfoundland and Labrador",
      },
      {
        code: "NS",
        name: "Nova Scotia",
      },
      {
        code: "ON",
        name: "Ontario",
      },
      {
        code: "PE",
        name: "Prince Edward Island",
      },
      {
        code: "QC",
        name: "Quebec",
      },
      {
        code: "SK",
        name: "Saskatchewan",
      },
      {
        code: "NT",
        name: "Northwest Territories",
      },
      {
        code: "NU",
        name: "Nunavut",
      },
      {
        code: "YT",
        name: "Yukon",
      },
    ],
  },
  {
    code2: "CV",
    code3: "CPV",
    name: "Cape Verde",
    capital: "Praia",
    region: "Africa",
    subregion: "Western Africa",
    states: [
      {
        code: "B",
        name: "Ilhas de Barlavento",
      },
      {
        code: "S",
        name: "Ilhas de Sotavento",
      },
    ],
  },
  {
    code2: "KY",
    code3: "CYM",
    name: "Cayman Islands",
    capital: "George Town",
    region: "Americas",
    subregion: "Caribbean",
    states: [],
  },
  {
    code2: "CF",
    code3: "CAF",
    name: "Central African Republic",
    capital: "Bangui",
    region: "Africa",
    subregion: "Middle Africa",
    states: [
      {
        code: "BB",
        name: "Bamingui-Bangoran",
      },
      {
        code: "BGF",
        name: "Bangui",
      },
      {
        code: "BK",
        name: "Basse-Kotto",
      },
      {
        code: "KB",
        name: "Gribingui",
      },
      {
        code: "HM",
        name: "Haut-Mbomou",
      },
      {
        code: "HK",
        name: "Haute-Kotto",
      },
      {
        code: "HS",
        name: "Haute-Sangha / Mambéré-Kadéï",
      },
      {
        code: "KG",
        name: "Kémo-Gribingui",
      },
      {
        code: "LB",
        name: "Lobaye",
      },
      {
        code: "MB",
        name: "Mbomou",
      },
      {
        code: "NM",
        name: "Nana-Mambéré",
      },
      {
        code: "MP",
        name: "Ombella-Mpoko",
      },
      {
        code: "UK",
        name: "Ouaka",
      },
      {
        code: "AC",
        name: "Ouham",
      },
      {
        code: "OP",
        name: "Ouham-Pendé",
      },
      {
        code: "SE",
        name: "Sangha",
      },
      {
        code: "VK",
        name: "Vakaga",
      },
    ],
  },
  {
    code2: "TD",
    code3: "TCD",
    name: "Chad",
    capital: "N'Djamena",
    region: "Africa",
    subregion: "Middle Africa",
    states: [
      {
        code: "BA",
        name: "Al Baṭḩah",
      },
      {
        code: "LC",
        name: "Al Buḩayrah",
      },
      {
        code: "BG",
        name: "Baḩr al Ghazāl",
      },
      {
        code: "BO",
        name: "Būrkū",
      },
      {
        code: "EN",
        name: "Innīdī",
      },
      {
        code: "KA",
        name: "Kānim",
      },
      {
        code: "LO",
        name: "Lūqūn al Gharbī",
      },
      {
        code: "LR",
        name: "Lūqūn ash Sharqī",
      },
      {
        code: "ND",
        name: "Madīnat Injamīnā",
      },
      {
        code: "MA",
        name: "Māndūl",
      },
      {
        code: "MO",
        name: "Māyū Kībbī al Gharbī",
      },
      {
        code: "ME",
        name: "Māyū Kībbī ash Sharqī",
      },
      {
        code: "GR",
        name: "Qīrā",
      },
      {
        code: "SA",
        name: "Salāmāt",
      },
      {
        code: "CB",
        name: "Shārī Bāqirmī",
      },
      {
        code: "MC",
        name: "Shārī al Awsaṭ",
      },
      {
        code: "SI",
        name: "Sīlā",
      },
      {
        code: "TI",
        name: "Tibastī",
      },
      {
        code: "TA",
        name: "Tānjilī",
      },
      {
        code: "OD",
        name: "Waddāy",
      },
      {
        code: "WF",
        name: "Wādī Fīrā",
      },
      {
        code: "HL",
        name: "Ḥajjar Lamīs",
      },
    ],
  },
  {
    code2: "CL",
    code3: "CHL",
    name: "Chile",
    capital: "Santiago",
    region: "Americas",
    subregion: "South America",
    states: [
      {
        code: "AI",
        name: "Aisén del General Carlos Ibañez del Campo",
      },
      {
        code: "AN",
        name: "Antofagasta",
      },
      {
        code: "AR",
        name: "Araucanía",
      },
      {
        code: "AP",
        name: "Arica y Parinacota",
      },
      {
        code: "AT",
        name: "Atacama",
      },
      {
        code: "BI",
        name: "Bío-Bío",
      },
      {
        code: "CO",
        name: "Coquimbo",
      },
      {
        code: "LI",
        name: "Libertador General Bernardo O'Higgins",
      },
      {
        code: "LL",
        name: "Los Lagos",
      },
      {
        code: "LR",
        name: "Los Ríos",
      },
      {
        code: "MA",
        name: "Magallanes",
      },
      {
        code: "ML",
        name: "Maule",
      },
      {
        code: "RM",
        name: "Región Metropolitana de Santiago",
      },
      {
        code: "TA",
        name: "Tarapacá",
      },
      {
        code: "VS",
        name: "Valparaíso",
      },
    ],
  },
  {
    code2: "CN",
    code3: "CHN",
    name: "China",
    capital: "Beijing",
    region: "Asia",
    subregion: "Eastern Asia",
    states: [
      {
        code: "45",
        name: "Guangxi",
      },
      {
        code: "15",
        name: "Nei Mongol",
      },
      {
        code: "64",
        name: "Ningxia",
      },
      {
        code: "65",
        name: "Xinjiang",
      },
      {
        code: "54",
        name: "Xizang",
      },
      {
        code: "11",
        name: "Beijing",
      },
      {
        code: "50",
        name: "Chongqing",
      },
      {
        code: "31",
        name: "Shanghai",
      },
      {
        code: "12",
        name: "Tianjin",
      },
      {
        code: "34",
        name: "Anhui",
      },
      {
        code: "35",
        name: "Fujian",
      },
      {
        code: "62",
        name: "Gansu",
      },
      {
        code: "44",
        name: "Guangdong",
      },
      {
        code: "52",
        name: "Guizhou",
      },
      {
        code: "46",
        name: "Hainan",
      },
      {
        code: "13",
        name: "Hebei",
      },
      {
        code: "23",
        name: "Heilongjiang",
      },
      {
        code: "41",
        name: "Henan",
      },
      {
        code: "42",
        name: "Hubei",
      },
      {
        code: "43",
        name: "Hunan",
      },
      {
        code: "32",
        name: "Jiangsu",
      },
      {
        code: "36",
        name: "Jiangxi",
      },
      {
        code: "22",
        name: "Jilin",
      },
      {
        code: "21",
        name: "Liaoning",
      },
      {
        code: "63",
        name: "Qinghai",
      },
      {
        code: "61",
        name: "Shaanxi",
      },
      {
        code: "37",
        name: "Shandong",
      },
      {
        code: "14",
        name: "Shanxi",
      },
      {
        code: "51",
        name: "Sichuan",
      },
      {
        code: "71",
        name: "Taiwan",
      },
      {
        code: "53",
        name: "Yunnan",
      },
      {
        code: "33",
        name: "Zhejiang",
      },
      {
        code: "91",
        name: "Hong Kong",
      },
      {
        code: "92",
        name: "Macao",
      },
      {
        code: "HONG KONG ISLAND",
        name: "Hong Kong Island",
      },
      {
        code: "KOWLOON",
        name: "Kowloon",
      },
      {
        code: "NEW TERRITORIES",
        name: "New Territories",
      },
    ],
  },
  {
    code2: "CX",
    code3: "CXR",
    name: "Christmas Island",
    capital: "Flying Fish Cove",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    states: [],
  },
  {
    code2: "CC",
    code3: "CCK",
    name: "Cocos (Keeling) Islands",
    capital: "West Island",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    states: [],
  },
  {
    code2: "CO",
    code3: "COL",
    name: "Colombia",
    capital: "Bogotá",
    region: "Americas",
    subregion: "South America",
    states: [
      {
        code: "AMA",
        name: "Amazonas",
      },
      {
        code: "ANT",
        name: "Antioquia",
      },
      {
        code: "ARA",
        name: "Arauca",
      },
      {
        code: "ATL",
        name: "Atlántico",
      },
      {
        code: "BOL",
        name: "Bolívar",
      },
      {
        code: "BOY",
        name: "Boyacá",
      },
      {
        code: "CAL",
        name: "Caldas",
      },
      {
        code: "CAQ",
        name: "Caquetá",
      },
      {
        code: "CAS",
        name: "Casanare",
      },
      {
        code: "CAU",
        name: "Cauca",
      },
      {
        code: "CES",
        name: "Cesar",
      },
      {
        code: "CHO",
        name: "Chocó",
      },
      {
        code: "CUN",
        name: "Cundinamarca",
      },
      {
        code: "COR",
        name: "Córdoba",
      },
      {
        code: "DC",
        name: "Distrito Capital de Bogotá",
      },
      {
        code: "GUA",
        name: "Guainía",
      },
      {
        code: "GUV",
        name: "Guaviare",
      },
      {
        code: "HUI",
        name: "Huila",
      },
      {
        code: "LAG",
        name: "La Guajira",
      },
      {
        code: "MAG",
        name: "Magdalena",
      },
      {
        code: "MET",
        name: "Meta",
      },
      {
        code: "NAR",
        name: "Nariño",
      },
      {
        code: "NSA",
        name: "Norte de Santander",
      },
      {
        code: "PUT",
        name: "Putumayo",
      },
      {
        code: "QUI",
        name: "Quindío",
      },
      {
        code: "RIS",
        name: "Risaralda",
      },
      {
        code: "SAP",
        name: "San Andrés, Providencia y Santa Catalina",
      },
      {
        code: "SAN",
        name: "Santander",
      },
      {
        code: "SUC",
        name: "Sucre",
      },
      {
        code: "TOL",
        name: "Tolima",
      },
      {
        code: "VAC",
        name: "Valle del Cauca",
      },
      {
        code: "VAU",
        name: "Vaupés",
      },
      {
        code: "VID",
        name: "Vichada",
      },
    ],
  },
  {
    code2: "KM",
    code3: "COM",
    name: "Comoros",
    capital: "Moroni",
    region: "Africa",
    subregion: "Eastern Africa",
    states: [
      {
        code: "A",
        name: "Anjouan",
      },
      {
        code: "G",
        name: "Grande Comore",
      },
      {
        code: "M",
        name: "Mohéli",
      },
    ],
  },
  {
    code2: "CG",
    code3: "COG",
    name: "Republic of the Congo",
    capital: "Brazzaville",
    region: "Africa",
    subregion: "Middle Africa",
    states: [
      {
        code: "11",
        name: "Bouenza",
      },
      {
        code: "BZV",
        name: "Brazzaville",
      },
      {
        code: "8",
        name: "Cuvette",
      },
      {
        code: "15",
        name: "Cuvette-Ouest",
      },
      {
        code: "5",
        name: "Kouilou",
      },
      {
        code: "7",
        name: "Likouala",
      },
      {
        code: "2",
        name: "Lékoumou",
      },
      {
        code: "9",
        name: "Niari",
      },
      {
        code: "14",
        name: "Plateaux",
      },
      {
        code: "16",
        name: "Pointe-Noire",
      },
      {
        code: "12",
        name: "Pool",
      },
      {
        code: "13",
        name: "Sangha",
      },
    ],
  },
  {
    code2: "CD",
    code3: "COD",
    name: "Democratic Republic of the Congo",
    capital: "Kinshasa",
    region: "Africa",
    subregion: "Middle Africa",
    states: [
      {
        code: "BN",
        name: "Bandundu",
      },
      {
        code: "BC",
        name: "Bas-Congo",
      },
      {
        code: "KW",
        name: "Kasai-Occidental",
      },
      {
        code: "KE",
        name: "Kasai-Oriental",
      },
      {
        code: "KA",
        name: "Katanga",
      },
      {
        code: "KN",
        name: "Kinshasa",
      },
      {
        code: "MA",
        name: "Maniema",
      },
      {
        code: "NK",
        name: "Nord-Kivu",
      },
      {
        code: "OR",
        name: "Orientale",
      },
      {
        code: "SK",
        name: "Sud-Kivu",
      },
      {
        code: "EQ",
        name: "Équateur",
      },
    ],
  },
  {
    code2: "CK",
    code3: "COK",
    name: "Cook Islands",
    capital: "Avarua",
    region: "Oceania",
    subregion: "Polynesia",
    states: [],
  },
  {
    code2: "CR",
    code3: "CRI",
    name: "Costa Rica",
    capital: "San José",
    region: "Americas",
    subregion: "Central America",
    states: [
      {
        code: "A",
        name: "Alajuela",
      },
      {
        code: "C",
        name: "Cartago",
      },
      {
        code: "G",
        name: "Guanacaste",
      },
      {
        code: "H",
        name: "Heredia",
      },
      {
        code: "L",
        name: "Limón",
      },
      {
        code: "P",
        name: "Puntarenas",
      },
      {
        code: "SJ",
        name: "San José",
      },
    ],
  },
  {
    code2: "HR",
    code3: "HRV",
    name: "Croatia",
    capital: "Zagreb",
    region: "Europe",
    subregion: "Southern Europe",
    states: [
      {
        code: "07",
        name: "Bjelovarsko-bilogorska županija",
      },
      {
        code: "12",
        name: "Brodsko-posavska županija",
      },
      {
        code: "19",
        name: "Dubrovačko-neretvanska županija",
      },
      {
        code: "21",
        name: "Grad Zagreb",
      },
      {
        code: "18",
        name: "Istarska županija",
      },
      {
        code: "04",
        name: "Karlovačka županija",
      },
      {
        code: "06",
        name: "Koprivničko-križevačka županija",
      },
      {
        code: "02",
        name: "Krapinsko-zagorska županija",
      },
      {
        code: "09",
        name: "Ličko-senjska županija",
      },
      {
        code: "20",
        name: "Međimurska županija",
      },
      {
        code: "14",
        name: "Osječko-baranjska županija",
      },
      {
        code: "11",
        name: "Požeško-slavonska županija",
      },
      {
        code: "08",
        name: "Primorsko-goranska županija",
      },
      {
        code: "03",
        name: "Sisačko-moslavačka županija",
      },
      {
        code: "17",
        name: "Splitsko-dalmatinska županija",
      },
      {
        code: "05",
        name: "Varaždinska županija",
      },
      {
        code: "10",
        name: "Virovitičko-podravska županija",
      },
      {
        code: "16",
        name: "Vukovarsko-srijemska županija",
      },
      {
        code: "13",
        name: "Zadarska županija",
      },
      {
        code: "01",
        name: "Zagrebačka županija",
      },
      {
        code: "15",
        name: "Šibensko-kninska županija",
      },
    ],
  },
  {
    code2: "CU",
    code3: "CUB",
    name: "Cuba",
    capital: "Havana",
    region: "Americas",
    subregion: "Caribbean",
    states: [
      {
        code: "15",
        name: "Artemisa",
      },
      {
        code: "09",
        name: "Camagüey",
      },
      {
        code: "08",
        name: "Ciego de Ávila",
      },
      {
        code: "06",
        name: "Cienfuegos",
      },
      {
        code: "12",
        name: "Granma",
      },
      {
        code: "14",
        name: "Guantánamo",
      },
      {
        code: "11",
        name: "Holguín",
      },
      {
        code: "99",
        name: "Isla de la Juventud",
      },
      {
        code: "03",
        name: "La Habana",
      },
      {
        code: "10",
        name: "Las Tunas",
      },
      {
        code: "04",
        name: "Matanzas",
      },
      {
        code: "16",
        name: "Mayabeque",
      },
      {
        code: "01",
        name: "Pinar del Río",
      },
      {
        code: "07",
        name: "Sancti Spíritus",
      },
      {
        code: "13",
        name: "Santiago de Cuba",
      },
      {
        code: "05",
        name: "Villa Clara",
      },
    ],
  },
  {
    code2: "CW",
    code3: "CUW",
    name: "Curaçao",
    capital: "Willemstad",
    region: "Americas",
    subregion: "Caribbean",
    states: [],
  },
  {
    code2: "CY",
    code3: "CYP",
    name: "Cyprus",
    capital: "Nicosia",
    region: "Europe",
    subregion: "Southern Europe",
    states: [
      {
        code: "04",
        name: "Ammochostos",
      },
      {
        code: "06",
        name: "Keryneia",
      },
      {
        code: "03",
        name: "Larnaka",
      },
      {
        code: "01",
        name: "Lefkosia",
      },
      {
        code: "02",
        name: "Lemesos",
      },
      {
        code: "05",
        name: "Pafos",
      },
    ],
  },
  {
    code2: "CZ",
    code3: "CZE",
    name: "Czech Republic",
    capital: "Prague",
    region: "Europe",
    subregion: "Eastern Europe",
    states: [
      {
        code: "JM",
        name: "Jihomoravský kraj",
      },
      {
        code: "JC",
        name: "Jihočeský kraj",
      },
      {
        code: "KA",
        name: "Karlovarský kraj",
      },
      {
        code: "KR",
        name: "Královéhradecký kraj",
      },
      {
        code: "LI",
        name: "Liberecký kraj",
      },
      {
        code: "MO",
        name: "Moravskoslezský kraj",
      },
      {
        code: "OL",
        name: "Olomoucký kraj",
      },
      {
        code: "PA",
        name: "Pardubický kraj",
      },
      {
        code: "PL",
        name: "Plzeňský kraj",
      },
      {
        code: "PR",
        name: "Praha, hlavní město",
      },
      {
        code: "ST",
        name: "Středočeský kraj",
      },
      {
        code: "VY",
        name: "Vysočina",
      },
      {
        code: "ZL",
        name: "Zlínský kraj",
      },
      {
        code: "US",
        name: "Ústecký kraj",
      },
    ],
  },
  {
    code2: "DK",
    code3: "DNK",
    name: "Denmark",
    capital: "Copenhagen",
    region: "Europe",
    subregion: "Northern Europe",
    states: [
      {
        code: "84",
        name: "Hovedstaden",
      },
      {
        code: "82",
        name: "Midtjylland",
      },
      {
        code: "81",
        name: "Nordjylland",
      },
      {
        code: "85",
        name: "Sjælland",
      },
      {
        code: "83",
        name: "Syddanmark",
      },
    ],
  },
  {
    code2: "DJ",
    code3: "DJI",
    name: "Djibouti",
    capital: "Djibouti",
    region: "Africa",
    subregion: "Eastern Africa",
    states: [
      {
        code: "AS",
        name: "Ali Sabieh",
      },
      {
        code: "AR",
        name: "Arta",
      },
      {
        code: "DI",
        name: "Dikhil",
      },
      {
        code: "DJ",
        name: "Djibouti",
      },
      {
        code: "OB",
        name: "Obock",
      },
      {
        code: "TA",
        name: "Tadjourah",
      },
    ],
  },
  {
    code2: "DM",
    code3: "DMA",
    name: "Dominica",
    capital: "Roseau",
    region: "Americas",
    subregion: "Caribbean",
    states: [
      {
        code: "02",
        name: "Saint Andrew",
      },
      {
        code: "03",
        name: "Saint David",
      },
      {
        code: "04",
        name: "Saint George",
      },
      {
        code: "05",
        name: "Saint John",
      },
      {
        code: "06",
        name: "Saint Joseph",
      },
      {
        code: "07",
        name: "Saint Luke",
      },
      {
        code: "08",
        name: "Saint Mark",
      },
      {
        code: "09",
        name: "Saint Patrick",
      },
      {
        code: "10",
        name: "Saint Paul",
      },
      {
        code: "11",
        name: "Saint Peter",
      },
    ],
  },
  {
    code2: "DO",
    code3: "DOM",
    name: "Dominican Republic",
    capital: "Santo Domingo",
    region: "Americas",
    subregion: "Caribbean",
    states: [
      {
        code: "33",
        name: "Cibao Nordeste",
      },
      {
        code: "34",
        name: "Cibao Noroeste",
      },
      {
        code: "35",
        name: "Cibao Norte",
      },
      {
        code: "36",
        name: "Cibao Sur",
      },
      {
        code: "37",
        name: "El Valle",
      },
      {
        code: "38",
        name: "Enriquillo",
      },
      {
        code: "39",
        name: "Higuamo",
      },
      {
        code: "40",
        name: "Ozama",
      },
      {
        code: "41",
        name: "Valdesia",
      },
      {
        code: "42",
        name: "Yuma",
      },
    ],
  },
  {
    code2: "EC",
    code3: "ECU",
    name: "Ecuador",
    capital: "Quito",
    region: "Americas",
    subregion: "South America",
    states: [
      {
        code: "A",
        name: "Azuay",
      },
      {
        code: "B",
        name: "Bolívar",
      },
      {
        code: "C",
        name: "Carchi",
      },
      {
        code: "F",
        name: "Cañar",
      },
      {
        code: "H",
        name: "Chimborazo",
      },
      {
        code: "X",
        name: "Cotopaxi",
      },
      {
        code: "O",
        name: "El Oro",
      },
      {
        code: "E",
        name: "Esmeraldas",
      },
      {
        code: "W",
        name: "Galápagos",
      },
      {
        code: "G",
        name: "Guayas",
      },
      {
        code: "I",
        name: "Imbabura",
      },
      {
        code: "L",
        name: "Loja",
      },
      {
        code: "R",
        name: "Los Ríos",
      },
      {
        code: "M",
        name: "Manabí",
      },
      {
        code: "S",
        name: "Morona-Santiago",
      },
      {
        code: "N",
        name: "Napo",
      },
      {
        code: "D",
        name: "Orellana",
      },
      {
        code: "Y",
        name: "Pastaza",
      },
      {
        code: "P",
        name: "Pichincha",
      },
      {
        code: "SE",
        name: "Santa Elena",
      },
      {
        code: "SD",
        name: "Santo Domingo de los Tsáchilas",
      },
      {
        code: "U",
        name: "Sucumbíos",
      },
      {
        code: "T",
        name: "Tungurahua",
      },
      {
        code: "Z",
        name: "Zamora-Chinchipe",
      },
    ],
  },
  {
    code2: "EG",
    code3: "EGY",
    name: "Egypt",
    capital: "Cairo",
    region: "Africa",
    subregion: "Northern Africa",
    states: [
      {
        code: "DK",
        name: "Ad Daqahlīyah",
      },
      {
        code: "BA",
        name: "Al Baḩr al Aḩmar",
      },
      {
        code: "BH",
        name: "Al Buḩayrah",
      },
      {
        code: "FYM",
        name: "Al Fayyūm",
      },
      {
        code: "GH",
        name: "Al Gharbīyah",
      },
      {
        code: "ALX",
        name: "Al Iskandarīyah",
      },
      {
        code: "IS",
        name: "Al Ismāٰīlīyah",
      },
      {
        code: "GZ",
        name: "Al Jīzah",
      },
      {
        code: "MN",
        name: "Al Minyā",
      },
      {
        code: "MNF",
        name: "Al Minūfīyah",
      },
      {
        code: "KB",
        name: "Al Qalyūbīyah",
      },
      {
        code: "C",
        name: "Al Qāhirah",
      },
      {
        code: "LX",
        name: "Al Uqşur",
      },
      {
        code: "WAD",
        name: "Al Wādī al Jadīd",
      },
      {
        code: "SUZ",
        name: "As Suways",
      },
      {
        code: "SU",
        name: "As Sādis min Uktūbar",
      },
      {
        code: "SHR",
        name: "Ash Sharqīyah",
      },
      {
        code: "ASN",
        name: "Aswān",
      },
      {
        code: "AST",
        name: "Asyūţ",
      },
      {
        code: "BNS",
        name: "Banī Suwayf",
      },
      {
        code: "PTS",
        name: "Būr Saٰīd",
      },
      {
        code: "DT",
        name: "Dumyāţ",
      },
      {
        code: "JS",
        name: "Janūb Sīnā'",
      },
      {
        code: "KFS",
        name: "Kafr ash Shaykh",
      },
      {
        code: "MT",
        name: "Maţrūḩ",
      },
      {
        code: "KN",
        name: "Qinā",
      },
      {
        code: "SIN",
        name: "Shamāl Sīnā'",
      },
      {
        code: "SHG",
        name: "Sūhāj",
      },
      {
        code: "HU",
        name: "Ḩulwān",
      },
    ],
  },
  {
    code2: "SV",
    code3: "SLV",
    name: "El Salvador",
    capital: "San Salvador",
    region: "Americas",
    subregion: "Central America",
    states: [
      {
        code: "AH",
        name: "Ahuachapán",
      },
      {
        code: "CA",
        name: "Cabañas",
      },
      {
        code: "CH",
        name: "Chalatenango",
      },
      {
        code: "CU",
        name: "Cuscatlán",
      },
      {
        code: "LI",
        name: "La Libertad",
      },
      {
        code: "PA",
        name: "La Paz",
      },
      {
        code: "UN",
        name: "La Unión",
      },
      {
        code: "MO",
        name: "Morazán",
      },
      {
        code: "SM",
        name: "San Miguel",
      },
      {
        code: "SS",
        name: "San Salvador",
      },
      {
        code: "SV",
        name: "San Vicente",
      },
      {
        code: "SA",
        name: "Santa Ana",
      },
      {
        code: "SO",
        name: "Sonsonate",
      },
      {
        code: "US",
        name: "Usulután",
      },
    ],
  },
  {
    code2: "GQ",
    code3: "GNQ",
    name: "Equatorial Guinea",
    capital: "Malabo",
    region: "Africa",
    subregion: "Middle Africa",
    states: [
      {
        code: "C",
        name: "Región Continental",
      },
      {
        code: "I",
        name: "Región Insular",
      },
    ],
  },
  {
    code2: "ER",
    code3: "ERI",
    name: "Eritrea",
    capital: "Asmara",
    region: "Africa",
    subregion: "Eastern Africa",
    states: [
      {
        code: "MA",
        name: "Al Awsaţ",
      },
      {
        code: "DU",
        name: "Al Janūbĩ",
      },
      {
        code: "AN",
        name: "Ansabā",
      },
      {
        code: "DK",
        name: "Janūbī al Baḩrī al Aḩmar",
      },
      {
        code: "GB",
        name: "Qāsh-Barkah",
      },
      {
        code: "SK",
        name: "Shimālī al Baḩrī al Aḩmar",
      },
    ],
  },
  {
    code2: "EE",
    code3: "EST",
    name: "Estonia",
    capital: "Tallinn",
    region: "Europe",
    subregion: "Northern Europe",
    states: [
      {
        code: "37",
        name: "Harjumaa",
      },
      {
        code: "39",
        name: "Hiiumaa",
      },
      {
        code: "44",
        name: "Ida-Virumaa",
      },
      {
        code: "51",
        name: "Järvamaa",
      },
      {
        code: "49",
        name: "Jõgevamaa",
      },
      {
        code: "59",
        name: "Lääne-Virumaa",
      },
      {
        code: "57",
        name: "Läänemaa",
      },
      {
        code: "67",
        name: "Pärnumaa",
      },
      {
        code: "65",
        name: "Põlvamaa",
      },
      {
        code: "70",
        name: "Raplamaa",
      },
      {
        code: "74",
        name: "Saaremaa",
      },
      {
        code: "78",
        name: "Tartumaa",
      },
      {
        code: "82",
        name: "Valgamaa",
      },
      {
        code: "84",
        name: "Viljandimaa",
      },
      {
        code: "86",
        name: "Võrumaa",
      },
    ],
  },
  {
    code2: "ET",
    code3: "ETH",
    name: "Ethiopia",
    capital: "Addis Ababa",
    region: "Africa",
    subregion: "Eastern Africa",
    states: [
      {
        code: "BE",
        name: "Bīnshangul Gumuz",
      },
      {
        code: "DD",
        name: "Dirē Dawa",
      },
      {
        code: "GA",
        name: "Gambēla Hizboch",
      },
      {
        code: "HA",
        name: "Hārerī Hizb",
      },
      {
        code: "OR",
        name: "Oromīya",
      },
      {
        code: "SO",
        name: "Sumalē",
      },
      {
        code: "TI",
        name: "Tigray",
      },
      {
        code: "SN",
        name: "YeDebub Bihēroch Bihēreseboch na Hizboch",
      },
      {
        code: "AA",
        name: "Ādīs Ābeba",
      },
      {
        code: "AF",
        name: "Āfar",
      },
      {
        code: "AM",
        name: "Āmara",
      },
    ],
  },
  {
    code2: "FK",
    code3: "FLK",
    name: "Falkland Islands",
    capital: "Stanley",
    region: "Americas",
    subregion: "South America",
    states: [],
  },
  {
    code2: "FO",
    code3: "FRO",
    name: "Faroe Islands",
    capital: "Tórshavn",
    region: "Europe",
    subregion: "Northern Europe",
    states: [],
  },
  {
    code2: "FJ",
    code3: "FJI",
    name: "Fiji",
    capital: "Suva",
    region: "Oceania",
    subregion: "Melanesia",
    states: [
      {
        code: "C",
        name: "Central",
      },
      {
        code: "E",
        name: "Eastern",
      },
      {
        code: "N",
        name: "Northern",
      },
      {
        code: "R",
        name: "Rotuma",
      },
      {
        code: "W",
        name: "Western",
      },
    ],
  },
  {
    code2: "FI",
    code3: "FIN",
    name: "Finland",
    capital: "Helsinki",
    region: "Europe",
    subregion: "Northern Europe",
    states: [
      {
        code: "01",
        name: "Ahvenanmaan maakunta",
      },
      {
        code: "02",
        name: "Etelä-Karjala",
      },
      {
        code: "03",
        name: "Etelä-Pohjanmaa",
      },
      {
        code: "04",
        name: "Etelä-Savo",
      },
      {
        code: "05",
        name: "Kainuu",
      },
      {
        code: "06",
        name: "Kanta-Häme",
      },
      {
        code: "07",
        name: "Keski-Pohjanmaa",
      },
      {
        code: "08",
        name: "Keski-Suomi",
      },
      {
        code: "09",
        name: "Kymenlaakso",
      },
      {
        code: "10",
        name: "Lappi",
      },
      {
        code: "11",
        name: "Pirkanmaa",
      },
      {
        code: "12",
        name: "Pohjanmaa",
      },
      {
        code: "13",
        name: "Pohjois-Karjala",
      },
      {
        code: "14",
        name: "Pohjois-Pohjanmaa",
      },
      {
        code: "15",
        name: "Pohjois-Savo",
      },
      {
        code: "16",
        name: "Päijät-Häme",
      },
      {
        code: "17",
        name: "Satakunta",
      },
      {
        code: "18",
        name: "Uusimaa",
      },
      {
        code: "19",
        name: "Varsinais-Suomi",
      },
    ],
  },
  {
    code2: "FR",
    code3: "FRA",
    name: "France",
    capital: "Paris",
    region: "Europe",
    subregion: "Western Europe",
    states: [
      {
        code: "A",
        name: "Alsace",
      },
      {
        code: "B",
        name: "Aquitaine",
      },
      {
        code: "C",
        name: "Auvergne",
      },
      {
        code: "E",
        name: "Brittany",
      },
      {
        code: "D",
        name: "Burgundy",
      },
      {
        code: "F",
        name: "Centre-Val de Loire",
      },
      {
        code: "G",
        name: "Champagne-Ardenne",
      },
      {
        code: "H",
        name: "Corsica",
      },
      {
        code: "I",
        name: "Franche-Comté",
      },
      {
        code: "K",
        name: "Languedoc-Roussillon",
      },
      {
        code: "L",
        name: "Limousin",
      },
      {
        code: "M",
        name: "Lorraine",
      },
      {
        code: "P",
        name: "Lower Normandy",
      },
      {
        code: "N",
        name: "Midi-Pyrénées",
      },
      {
        code: "O",
        name: "Nord-Pas-de-Calais",
      },
      {
        code: "R",
        name: "Pays de la Loire",
      },
      {
        code: "S",
        name: "Picardy",
      },
      {
        code: "T",
        name: "Poitou-Charentes",
      },
      {
        code: "U",
        name: "Provence-Alpes-Côte d'Azur",
      },
      {
        code: "V",
        name: "Rhône-Alpes",
      },
      {
        code: "Q",
        name: "Upper Normandy",
      },
      {
        code: "J",
        name: "Île-de-France",
      },
    ],
  },
  {
    code2: "GF",
    code3: "GUF",
    name: "French Guiana",
    capital: "Cayenne",
    region: "Americas",
    subregion: "South America",
    states: [],
  },
  {
    code2: "PF",
    code3: "PYF",
    name: "French Polynesia",
    capital: "Papeetē",
    region: "Oceania",
    subregion: "Polynesia",
    states: [],
  },
  {
    code2: "TF",
    code3: "ATF",
    name: "French Southern and Antarctic Lands",
    capital: "Port-aux-Français",
    region: "Africa",
    subregion: "Southern Africa",
    states: [],
  },
  {
    code2: "GA",
    code3: "GAB",
    name: "Gabon",
    capital: "Libreville",
    region: "Africa",
    subregion: "Middle Africa",
    states: [
      {
        code: "1",
        name: "Estuaire",
      },
      {
        code: "2",
        name: "Haut-Ogooué",
      },
      {
        code: "3",
        name: "Moyen-Ogooué",
      },
      {
        code: "4",
        name: "Ngounié",
      },
      {
        code: "5",
        name: "Nyanga",
      },
      {
        code: "6",
        name: "Ogooué-Ivindo",
      },
      {
        code: "7",
        name: "Ogooué-Lolo",
      },
      {
        code: "8",
        name: "Ogooué-Maritime",
      },
      {
        code: "9",
        name: "Woleu-Ntem",
      },
    ],
  },
  {
    code2: "GM",
    code3: "GMB",
    name: "The Gambia",
    capital: "Banjul",
    region: "Africa",
    subregion: "Western Africa",
    states: [
      {
        code: "B",
        name: "Banjul",
      },
      {
        code: "M",
        name: "Central River",
      },
      {
        code: "L",
        name: "Lower River",
      },
      {
        code: "N",
        name: "North Bank",
      },
      {
        code: "U",
        name: "Upper River",
      },
      {
        code: "W",
        name: "Western",
      },
    ],
  },
  {
    code2: "GE",
    code3: "GEO",
    name: "Georgia",
    capital: "Tbilisi",
    region: "Asia",
    subregion: "Western Asia",
    states: [
      {
        code: "AB",
        name: "Abkhazia",
      },
      {
        code: "AJ",
        name: "Ajaria",
      },
      {
        code: "GU",
        name: "Guria",
      },
      {
        code: "IM",
        name: "Imereti",
      },
      {
        code: "KA",
        name: "K'akheti",
      },
      {
        code: "KK",
        name: "Kvemo Kartli",
      },
      {
        code: "MM",
        name: "Mtskheta-Mtianeti",
      },
      {
        code: "RL",
        name: "Rach'a-Lechkhumi-Kvemo Svaneti",
      },
      {
        code: "SZ",
        name: "Samegrelo-Zemo Svaneti",
      },
      {
        code: "SJ",
        name: "Samtskhe-Javakheti",
      },
      {
        code: "SK",
        name: "Shida Kartli",
      },
      {
        code: "TB",
        name: "Tbilisi",
      },
    ],
  },
  {
    code2: "DE",
    code3: "DEU",
    name: "Germany",
    capital: "Berlin",
    region: "Europe",
    subregion: "Western Europe",
    states: [
      {
        code: "BW",
        name: "Baden-Württemberg",
      },
      {
        code: "BY",
        name: "Bayern",
      },
      {
        code: "BE",
        name: "Berlin",
      },
      {
        code: "BB",
        name: "Brandenburg",
      },
      {
        code: "HB",
        name: "Bremen",
      },
      {
        code: "HH",
        name: "Hamburg",
      },
      {
        code: "HE",
        name: "Hessen",
      },
      {
        code: "MV",
        name: "Mecklenburg-Vorpommern",
      },
      {
        code: "NI",
        name: "Niedersachsen",
      },
      {
        code: "NW",
        name: "Nordrhein-Westfalen",
      },
      {
        code: "RP",
        name: "Rheinland-Pfalz",
      },
      {
        code: "SL",
        name: "Saarland",
      },
      {
        code: "SN",
        name: "Sachsen",
      },
      {
        code: "ST",
        name: "Sachsen-Anhalt",
      },
      {
        code: "SH",
        name: "Schleswig-Holstein",
      },
      {
        code: "TH",
        name: "Thüringen",
      },
    ],
  },
  {
    code2: "GH",
    code3: "GHA",
    name: "Ghana",
    capital: "Accra",
    region: "Africa",
    subregion: "Western Africa",
    states: [
      {
        code: "AH",
        name: "Ashanti",
      },
      {
        code: "BA",
        name: "Brong-Ahafo",
      },
      {
        code: "CP",
        name: "Central",
      },
      {
        code: "EP",
        name: "Eastern",
      },
      {
        code: "AA",
        name: "Greater Accra",
      },
      {
        code: "NP",
        name: "Northern",
      },
      {
        code: "UE",
        name: "Upper East",
      },
      {
        code: "UW",
        name: "Upper West",
      },
      {
        code: "TV",
        name: "Volta",
      },
      {
        code: "WP",
        name: "Western",
      },
    ],
  },
  {
    code2: "GI",
    code3: "GIB",
    name: "Gibraltar",
    capital: "Gibraltar",
    region: "Europe",
    subregion: "Southern Europe",
    states: [],
  },
  {
    code2: "GR",
    code3: "GRC",
    name: "Greece",
    capital: "Athens",
    region: "Europe",
    subregion: "Southern Europe",
    states: [
      {
        code: "A",
        name: "Anatoliki Makedonia kai Thraki",
      },
      {
        code: "I",
        name: "Attiki",
      },
      {
        code: "G",
        name: "Dytiki Ellada",
      },
      {
        code: "C",
        name: "Dytiki Makedonia",
      },
      {
        code: "F",
        name: "Ionia Nisia",
      },
      {
        code: "D",
        name: "Ipeiros",
      },
      {
        code: "B",
        name: "Kentriki Makedonia",
      },
      {
        code: "M",
        name: "Kriti",
      },
      {
        code: "L",
        name: "Notio Aigaio",
      },
      {
        code: "J",
        name: "Peloponnisos",
      },
      {
        code: "H",
        name: "Sterea Ellada",
      },
      {
        code: "E",
        name: "Thessalia",
      },
      {
        code: "K",
        name: "Voreio Aigaio",
      },
    ],
  },
  {
    code2: "GL",
    code3: "GRL",
    name: "Greenland",
    capital: "Nuuk",
    region: "Americas",
    subregion: "Northern America",
    states: [
      {
        code: "KU",
        name: "Kommune Kujalleq",
      },
      {
        code: "SM",
        name: "Kommuneqarfik Sermersooq",
      },
      {
        code: "QA",
        name: "Qaasuitsup Kommunia",
      },
      {
        code: "QE",
        name: "Qeqqata Kommunia",
      },
    ],
  },
  {
    code2: "GD",
    code3: "GRD",
    name: "Grenada",
    capital: "St. George's",
    region: "Americas",
    subregion: "Caribbean",
    states: [
      {
        code: "01",
        name: "Saint Andrew",
      },
      {
        code: "02",
        name: "Saint David",
      },
      {
        code: "03",
        name: "Saint George",
      },
      {
        code: "04",
        name: "Saint John",
      },
      {
        code: "05",
        name: "Saint Mark",
      },
      {
        code: "06",
        name: "Saint Patrick",
      },
      {
        code: "10",
        name: "Southern Grenadine Islands",
      },
    ],
  },
  {
    code2: "GP",
    code3: "GLP",
    name: "Guadeloupe",
    capital: "Basse-Terre",
    region: "Americas",
    subregion: "Caribbean",
    states: [],
  },
  {
    code2: "GU",
    code3: "GUM",
    name: "Guam",
    capital: "Hagåtña",
    region: "Oceania",
    subregion: "Micronesia",
    states: [],
  },
  {
    code2: "GT",
    code3: "GTM",
    name: "Guatemala",
    capital: "Guatemala City",
    region: "Americas",
    subregion: "Central America",
    states: [
      {
        code: "AV",
        name: "Alta Verapaz",
      },
      {
        code: "BV",
        name: "Baja Verapaz",
      },
      {
        code: "CM",
        name: "Chimaltenango",
      },
      {
        code: "CQ",
        name: "Chiquimula",
      },
      {
        code: "PR",
        name: "El Progreso",
      },
      {
        code: "ES",
        name: "Escuintla",
      },
      {
        code: "GU",
        name: "Guatemala",
      },
      {
        code: "HU",
        name: "Huehuetenango",
      },
      {
        code: "IZ",
        name: "Izabal",
      },
      {
        code: "JA",
        name: "Jalapa",
      },
      {
        code: "JU",
        name: "Jutiapa",
      },
      {
        code: "PE",
        name: "Petén",
      },
      {
        code: "QZ",
        name: "Quetzaltenango",
      },
      {
        code: "QC",
        name: "Quiché",
      },
      {
        code: "RE",
        name: "Retalhuleu",
      },
      {
        code: "SA",
        name: "Sacatepéquez",
      },
      {
        code: "SM",
        name: "San Marcos",
      },
      {
        code: "SR",
        name: "Santa Rosa",
      },
      {
        code: "SO",
        name: "Sololá",
      },
      {
        code: "SU",
        name: "Suchitepéquez",
      },
      {
        code: "TO",
        name: "Totonicapán",
      },
      {
        code: "ZA",
        name: "Zacapa",
      },
    ],
  },
  {
    code2: "GG",
    code3: "GGY",
    name: "Guernsey",
    capital: "St. Peter Port",
    region: "Europe",
    subregion: "Northern Europe",
    states: [],
  },
  {
    code2: "GN",
    code3: "GIN",
    name: "Guinea",
    capital: "Conakry",
    region: "Africa",
    subregion: "Western Africa",
    states: [
      {
        code: "B",
        name: "Boké",
      },
      {
        code: "C",
        name: "Conakry",
      },
      {
        code: "F",
        name: "Faranah",
      },
      {
        code: "K",
        name: "Kankan",
      },
      {
        code: "D",
        name: "Kindia",
      },
      {
        code: "L",
        name: "Labé",
      },
      {
        code: "M",
        name: "Mamou",
      },
      {
        code: "N",
        name: "Nzérékoré",
      },
    ],
  },
  {
    code2: "GW",
    code3: "GNB",
    name: "Guinea-Bissau",
    capital: "Bissau",
    region: "Africa",
    subregion: "Western Africa",
    states: [
      {
        code: "L",
        name: "Leste",
      },
      {
        code: "N",
        name: "Norte",
      },
      {
        code: "S",
        name: "Sul",
      },
    ],
  },
  {
    code2: "GY",
    code3: "GUY",
    name: "Guyana",
    capital: "Georgetown",
    region: "Americas",
    subregion: "South America",
    states: [
      {
        code: "BA",
        name: "Barima-Waini",
      },
      {
        code: "CU",
        name: "Cuyuni-Mazaruni",
      },
      {
        code: "DE",
        name: "Demerara-Mahaica",
      },
      {
        code: "EB",
        name: "East Berbice-Corentyne",
      },
      {
        code: "ES",
        name: "Essequibo Islands-West Demerara",
      },
      {
        code: "MA",
        name: "Mahaica-Berbice",
      },
      {
        code: "PM",
        name: "Pomeroon-Supenaam",
      },
      {
        code: "PT",
        name: "Potaro-Siparuni",
      },
      {
        code: "UD",
        name: "Upper Demerara-Berbice",
      },
      {
        code: "UT",
        name: "Upper Takutu-Upper Essequibo",
      },
    ],
  },
  {
    code2: "HT",
    code3: "HTI",
    name: "Haiti",
    capital: "Port-au-Prince",
    region: "Americas",
    subregion: "Caribbean",
    states: [
      {
        code: "AR",
        name: "Artibonite",
      },
      {
        code: "CE",
        name: "Centre",
      },
      {
        code: "GA",
        name: "Grande-Anse",
      },
      {
        code: "NI",
        name: "Nippes",
      },
      {
        code: "ND",
        name: "Nord",
      },
      {
        code: "NE",
        name: "Nord-Est",
      },
      {
        code: "NO",
        name: "Nord-Ouest",
      },
      {
        code: "OU",
        name: "Ouest",
      },
      {
        code: "SD",
        name: "Sud",
      },
      {
        code: "SE",
        name: "Sud-Est",
      },
    ],
  },
  {
    code2: "HM",
    code3: "HMD",
    name: "Heard Island and McDonald Islands",
    capital: "",
    region: "",
    subregion: "",
    states: [],
  },
  {
    code2: "VA",
    code3: "VAT",
    name: "Holy See",
    capital: "Rome",
    region: "Europe",
    subregion: "Southern Europe",
    states: [],
  },
  {
    code2: "HN",
    code3: "HND",
    name: "Honduras",
    capital: "Tegucigalpa",
    region: "Americas",
    subregion: "Central America",
    states: [
      {
        code: "AT",
        name: "Atlántida",
      },
      {
        code: "CH",
        name: "Choluteca",
      },
      {
        code: "CL",
        name: "Colón",
      },
      {
        code: "CM",
        name: "Comayagua",
      },
      {
        code: "CP",
        name: "Copán",
      },
      {
        code: "CR",
        name: "Cortés",
      },
      {
        code: "EP",
        name: "El Paraíso",
      },
      {
        code: "FM",
        name: "Francisco Morazán",
      },
      {
        code: "GD",
        name: "Gracias a Dios",
      },
      {
        code: "IN",
        name: "Intibucá",
      },
      {
        code: "IB",
        name: "Islas de la Bahía",
      },
      {
        code: "LP",
        name: "La Paz",
      },
      {
        code: "LE",
        name: "Lempira",
      },
      {
        code: "OC",
        name: "Ocotepeque",
      },
      {
        code: "OL",
        name: "Olancho",
      },
      {
        code: "SB",
        name: "Santa Bárbara",
      },
      {
        code: "VA",
        name: "Valle",
      },
      {
        code: "YO",
        name: "Yoro",
      },
    ],
  },
  {
    code2: "HK",
    code3: "HKG",
    name: "Hong Kong",
    capital: "City of Victoria",
    region: "Asia",
    subregion: "Eastern Asia",
    states: [],
  },
  {
    code2: "HU",
    code3: "HUN",
    name: "Hungary",
    capital: "Budapest",
    region: "Europe",
    subregion: "Eastern Europe",
    states: [
      {
        code: "BA",
        name: "Baranya",
      },
      {
        code: "BZ",
        name: "Borsod-Abaúj-Zemplén",
      },
      {
        code: "BU",
        name: "Budapest",
      },
      {
        code: "BK",
        name: "Bács-Kiskun",
      },
      {
        code: "BE",
        name: "Békés",
      },
      {
        code: "BC",
        name: "Békéscsaba",
      },
      {
        code: "CS",
        name: "Csongrád",
      },
      {
        code: "DE",
        name: "Debrecen",
      },
      {
        code: "DU",
        name: "Dunaújváros",
      },
      {
        code: "EG",
        name: "Eger",
      },
      {
        code: "FE",
        name: "Fejér",
      },
      {
        code: "GY",
        name: "Győr",
      },
      {
        code: "GS",
        name: "Győr-Moson-Sopron",
      },
      {
        code: "HB",
        name: "Hajdú-Bihar",
      },
      {
        code: "HE",
        name: "Heves",
      },
      {
        code: "HV",
        name: "Hódmezővásárhely",
      },
      {
        code: "JN",
        name: "Jász-Nagykun-Szolnok",
      },
      {
        code: "KV",
        name: "Kaposvár",
      },
      {
        code: "KM",
        name: "Kecskemét",
      },
      {
        code: "KE",
        name: "Komárom-Esztergom",
      },
      {
        code: "MI",
        name: "Miskolc",
      },
      {
        code: "NK",
        name: "Nagykanizsa",
      },
      {
        code: "NY",
        name: "Nyíregyháza",
      },
      {
        code: "NO",
        name: "Nógrád",
      },
      {
        code: "PE",
        name: "Pest",
      },
      {
        code: "PS",
        name: "Pécs",
      },
      {
        code: "ST",
        name: "Salgótarján",
      },
      {
        code: "SO",
        name: "Somogy",
      },
      {
        code: "SN",
        name: "Sopron",
      },
      {
        code: "SZ",
        name: "Szabolcs-Szatmár-Bereg",
      },
      {
        code: "SD",
        name: "Szeged",
      },
      {
        code: "SS",
        name: "Szekszárd",
      },
      {
        code: "SK",
        name: "Szolnok",
      },
      {
        code: "SH",
        name: "Szombathely",
      },
      {
        code: "SF",
        name: "Székesfehérvár",
      },
      {
        code: "TB",
        name: "Tatabánya",
      },
      {
        code: "TO",
        name: "Tolna",
      },
      {
        code: "VA",
        name: "Vas",
      },
      {
        code: "VE",
        name: "Veszprém",
      },
      {
        code: "VM",
        name: "Veszprém",
      },
      {
        code: "ZA",
        name: "Zala",
      },
      {
        code: "ZE",
        name: "Zalaegerszeg",
      },
      {
        code: "ER",
        name: "Érd",
      },
    ],
  },
  {
    code2: "IS",
    code3: "ISL",
    name: "Iceland",
    capital: "Reykjavík",
    region: "Europe",
    subregion: "Northern Europe",
    states: [
      {
        code: "7",
        name: "Austurland",
      },
      {
        code: "1",
        name: "Höfuðborgarsvæði utan Reykjavíkur",
      },
      {
        code: "6",
        name: "Norðurland eystra",
      },
      {
        code: "5",
        name: "Norðurland vestra",
      },
      {
        code: "0",
        name: "Reykjavík",
      },
      {
        code: "8",
        name: "Suðurland",
      },
      {
        code: "2",
        name: "Suðurnes",
      },
      {
        code: "4",
        name: "Vestfirðir",
      },
      {
        code: "3",
        name: "Vesturland",
      },
    ],
  },
  {
    code2: "IN",
    code3: "IND",
    name: "India",
    capital: "New Delhi",
    region: "Asia",
    subregion: "Southern Asia",
    states: [
      {
        code: "AN",
        name: "Andaman and Nicobar Islands",
      },
      {
        code: "CH",
        name: "Chandigarh",
      },
      {
        code: "DN",
        name: "Dadra and Nagar Haveli",
      },
      {
        code: "DD",
        name: "Daman and Diu",
      },
      {
        code: "DL",
        name: "Delhi",
      },
      {
        code: "LD",
        name: "Lakshadweep",
      },
      {
        code: "PY",
        name: "Puducherry",
      },
      {
        code: "AP",
        name: "Andhra Pradesh",
      },
      {
        code: "AR",
        name: "Arunachal Pradesh",
      },
      {
        code: "AS",
        name: "Assam",
      },
      {
        code: "BR",
        name: "Bihar",
      },
      {
        code: "CT",
        name: "Chhattisgarh",
      },
      {
        code: "GA",
        name: "Goa",
      },
      {
        code: "GJ",
        name: "Gujarat",
      },
      {
        code: "HR",
        name: "Haryana",
      },
      {
        code: "HP",
        name: "Himachal Pradesh",
      },
      {
        code: "JK",
        name: "Jammu and Kashmir",
      },
      {
        code: "JH",
        name: "Jharkhand",
      },
      {
        code: "KA",
        name: "Karnataka",
      },
      {
        code: "KL",
        name: "Kerala",
      },
      {
        code: "MP",
        name: "Madhya Pradesh",
      },
      {
        code: "MH",
        name: "Maharashtra",
      },
      {
        code: "MN",
        name: "Manipur",
      },
      {
        code: "ML",
        name: "Meghalaya",
      },
      {
        code: "MZ",
        name: "Mizoram",
      },
      {
        code: "NL",
        name: "Nagaland",
      },
      {
        code: "OR",
        name: "Odisha",
      },
      {
        code: "PB",
        name: "Punjab",
      },
      {
        code: "RJ",
        name: "Rajasthan",
      },
      {
        code: "SK",
        name: "Sikkim",
      },
      {
        code: "TN",
        name: "Tamil Nadu",
      },
      {
        code: "TG",
        name: "Telangana",
      },
      {
        code: "TR",
        name: "Tripura",
      },
      {
        code: "UP",
        name: "Uttar Pradesh",
      },
      {
        code: "UT",
        name: "Uttarakhand",
      },
      {
        code: "WB",
        name: "West Bengal",
      },
    ],
  },
  {
    code2: "ID",
    code3: "IDN",
    name: "Indonesia",
    capital: "Jakarta",
    region: "Asia",
    subregion: "South-Eastern Asia",
    states: [
      {
        code: "JW",
        name: "Jawa",
      },
      {
        code: "KA",
        name: "Kalimantan",
      },
      {
        code: "ML",
        name: "Maluku",
      },
      {
        code: "NU",
        name: "Nusa Tenggara",
      },
      {
        code: "PP",
        name: "Papua",
      },
      {
        code: "SL",
        name: "Sulawesi",
      },
      {
        code: "SM",
        name: "Sumatera",
      },
    ],
  },
  {
    code2: "CI",
    code3: "CIV",
    name: "Ivory Coast",
    capital: "Yamoussoukro",
    region: "Africa",
    subregion: "Western Africa",
    states: [
      {
        code: "06",
        name: "18 Montagnes",
      },
      {
        code: "16",
        name: "Agnébi",
      },
      {
        code: "17",
        name: "Bafing",
      },
      {
        code: "09",
        name: "Bas-Sassandra",
      },
      {
        code: "10",
        name: "Denguélé",
      },
      {
        code: "18",
        name: "Fromager",
      },
      {
        code: "02",
        name: "Haut-Sassandra",
      },
      {
        code: "07",
        name: "Lacs",
      },
      {
        code: "01",
        name: "Lagunes",
      },
      {
        code: "12",
        name: "Marahoué",
      },
      {
        code: "19",
        name: "Moyen-Cavally",
      },
      {
        code: "05",
        name: "Moyen-Comoé",
      },
      {
        code: "11",
        name: "Nzi-Comoé",
      },
      {
        code: "03",
        name: "Savanes",
      },
      {
        code: "15",
        name: "Sud-Bandama",
      },
      {
        code: "13",
        name: "Sud-Comoé",
      },
      {
        code: "04",
        name: "Vallée du Bandama",
      },
      {
        code: "14",
        name: "Worodougou",
      },
      {
        code: "08",
        name: "Zanzan",
      },
    ],
  },
  {
    code2: "IR",
    code3: "IRN",
    name: "Iran",
    capital: "Tehran",
    region: "Asia",
    subregion: "Southern Asia",
    states: [
      {
        code: "32",
        name: "Alborz",
      },
      {
        code: "03",
        name: "Ardabīl",
      },
      {
        code: "06",
        name: "Būshehr",
      },
      {
        code: "08",
        name: "Chahār Maḩāll va Bakhtīārī",
      },
      {
        code: "04",
        name: "Eşfahān",
      },
      {
        code: "14",
        name: "Fārs",
      },
      {
        code: "27",
        name: "Golestān",
      },
      {
        code: "19",
        name: "Gīlān",
      },
      {
        code: "24",
        name: "Hamadān",
      },
      {
        code: "23",
        name: "Hormozgān",
      },
      {
        code: "15",
        name: "Kermān",
      },
      {
        code: "17",
        name: "Kermānshāh",
      },
      {
        code: "29",
        name: "Khorāsān-e Janūbī",
      },
      {
        code: "30",
        name: "Khorāsān-e Razavī",
      },
      {
        code: "31",
        name: "Khorāsān-e Shemālī",
      },
      {
        code: "10",
        name: "Khūzestān",
      },
      {
        code: "18",
        name: "Kohgīlūyeh va Būyer Aḩmad",
      },
      {
        code: "16",
        name: "Kordestān",
      },
      {
        code: "20",
        name: "Lorestān",
      },
      {
        code: "22",
        name: "Markazī",
      },
      {
        code: "21",
        name: "Māzandarān",
      },
      {
        code: "28",
        name: "Qazvīn",
      },
      {
        code: "26",
        name: "Qom",
      },
      {
        code: "12",
        name: "Semnān",
      },
      {
        code: "13",
        name: "Sīstān va Balūchestān",
      },
      {
        code: "07",
        name: "Tehrān",
      },
      {
        code: "25",
        name: "Yazd",
      },
      {
        code: "11",
        name: "Zanjān",
      },
      {
        code: "02",
        name: "Āz̄arbāyjān-e Gharbī",
      },
      {
        code: "01",
        name: "Āz̄arbāyjān-e Sharqī",
      },
      {
        code: "05",
        name: "Īlām",
      },
    ],
  },
  {
    code2: "IQ",
    code3: "IRQ",
    name: "Iraq",
    capital: "Baghdad",
    region: "Asia",
    subregion: "Western Asia",
    states: [
      {
        code: "AN",
        name: "Al Anbār",
      },
      {
        code: "BA",
        name: "Al Başrah",
      },
      {
        code: "MU",
        name: "Al Muthanná",
      },
      {
        code: "QA",
        name: "Al Qādisīyah",
      },
      {
        code: "NA",
        name: "An Najaf",
      },
      {
        code: "AR",
        name: "Arbīl",
      },
      {
        code: "SU",
        name: "As Sulaymānīyah",
      },
      {
        code: "TS",
        name: "At Ta'mīm",
      },
      {
        code: "BG",
        name: "Baghdād",
      },
      {
        code: "BB",
        name: "Bābil",
      },
      {
        code: "DA",
        name: "Dahūk",
      },
      {
        code: "DQ",
        name: "Dhī Qār",
      },
      {
        code: "DI",
        name: "Diyālá",
      },
      {
        code: "KA",
        name: "Karbalā'",
      },
      {
        code: "MA",
        name: "Maysān",
      },
      {
        code: "NI",
        name: "Nīnawá",
      },
      {
        code: "WA",
        name: "Wāsiţ",
      },
      {
        code: "SD",
        name: "Şalāḩ ad Dīn",
      },
    ],
  },
  {
    code2: "IE",
    code3: "IRL",
    name: "Republic of Ireland",
    capital: "Dublin",
    region: "Europe",
    subregion: "Northern Europe",
    states: [
      {
        code: "C",
        name: "Connaught",
      },
      {
        code: "L",
        name: "Leinster",
      },
      {
        code: "M",
        name: "Munster",
      },
      {
        code: "U",
        name: "Ulster",
      },
    ],
  },
  {
    code2: "IM",
    code3: "IMN",
    name: "Isle of Man",
    capital: "Douglas",
    region: "Europe",
    subregion: "Northern Europe",
    states: [],
  },
  {
    code2: "IL",
    code3: "ISR",
    name: "Israel",
    capital: "Jerusalem",
    region: "Asia",
    subregion: "Western Asia",
    states: [
      {
        code: "D",
        name: "HaDarom",
      },
      {
        code: "M",
        name: "HaMerkaz",
      },
      {
        code: "Z",
        name: "HaTsafon",
      },
      {
        code: "HA",
        name: "H̱efa",
      },
      {
        code: "TA",
        name: "Tel-Aviv",
      },
      {
        code: "JM",
        name: "Yerushalayim",
      },
    ],
  },
  {
    code2: "IT",
    code3: "ITA",
    name: "Italy",
    capital: "Rome",
    region: "Europe",
    subregion: "Southern Europe",
    states: [
      {
        code: "65",
        name: "Abruzzo",
      },
      {
        code: "77",
        name: "Basilicata",
      },
      {
        code: "78",
        name: "Calabria",
      },
      {
        code: "72",
        name: "Campania",
      },
      {
        code: "45",
        name: "Emilia-Romagna",
      },
      {
        code: "36",
        name: "Friuli-Venezia Giulia",
      },
      {
        code: "62",
        name: "Lazio",
      },
      {
        code: "42",
        name: "Liguria",
      },
      {
        code: "25",
        name: "Lombardia",
      },
      {
        code: "57",
        name: "Marche",
      },
      {
        code: "67",
        name: "Molise",
      },
      {
        code: "21",
        name: "Piemonte",
      },
      {
        code: "75",
        name: "Puglia",
      },
      {
        code: "88",
        name: "Sardegna",
      },
      {
        code: "82",
        name: "Sicilia",
      },
      {
        code: "52",
        name: "Toscana",
      },
      {
        code: "32",
        name: "Trentino-Alto Adige",
      },
      {
        code: "55",
        name: "Umbria",
      },
      {
        code: "23",
        name: "Valle d'Aosta",
      },
      {
        code: "34",
        name: "Veneto",
      },
    ],
  },
  {
    code2: "JM",
    code3: "JAM",
    name: "Jamaica",
    capital: "Kingston",
    region: "Americas",
    subregion: "Caribbean",
    states: [
      {
        code: "13",
        name: "Clarendon",
      },
      {
        code: "09",
        name: "Hanover",
      },
      {
        code: "01",
        name: "Kingston",
      },
      {
        code: "12",
        name: "Manchester",
      },
      {
        code: "04",
        name: "Portland",
      },
      {
        code: "02",
        name: "Saint Andrew",
      },
      {
        code: "06",
        name: "Saint Ann",
      },
      {
        code: "14",
        name: "Saint Catherine",
      },
      {
        code: "11",
        name: "Saint Elizabeth",
      },
      {
        code: "08",
        name: "Saint James",
      },
      {
        code: "05",
        name: "Saint Mary",
      },
      {
        code: "03",
        name: "Saint Thomas",
      },
      {
        code: "07",
        name: "Trelawny",
      },
      {
        code: "10",
        name: "Westmoreland",
      },
    ],
  },
  {
    code2: "JP",
    code3: "JPN",
    name: "Japan",
    capital: "Tokyo",
    region: "Asia",
    subregion: "Eastern Asia",
    states: [
      {
        code: "23",
        name: "Aiti",
      },
      {
        code: "05",
        name: "Akita",
      },
      {
        code: "02",
        name: "Aomori",
      },
      {
        code: "38",
        name: "Ehime",
      },
      {
        code: "21",
        name: "Gihu",
      },
      {
        code: "10",
        name: "Gunma",
      },
      {
        code: "34",
        name: "Hirosima",
      },
      {
        code: "01",
        name: "Hokkaidô",
      },
      {
        code: "18",
        name: "Hukui",
      },
      {
        code: "40",
        name: "Hukuoka",
      },
      {
        code: "07",
        name: "Hukusima",
      },
      {
        code: "28",
        name: "Hyôgo",
      },
      {
        code: "08",
        name: "Ibaraki",
      },
      {
        code: "17",
        name: "Isikawa",
      },
      {
        code: "03",
        name: "Iwate",
      },
      {
        code: "37",
        name: "Kagawa",
      },
      {
        code: "46",
        name: "Kagosima",
      },
      {
        code: "14",
        name: "Kanagawa",
      },
      {
        code: "43",
        name: "Kumamoto",
      },
      {
        code: "26",
        name: "Kyôto",
      },
      {
        code: "39",
        name: "Kôti",
      },
      {
        code: "24",
        name: "Mie",
      },
      {
        code: "04",
        name: "Miyagi",
      },
      {
        code: "45",
        name: "Miyazaki",
      },
      {
        code: "20",
        name: "Nagano",
      },
      {
        code: "42",
        name: "Nagasaki",
      },
      {
        code: "29",
        name: "Nara",
      },
      {
        code: "15",
        name: "Niigata",
      },
      {
        code: "33",
        name: "Okayama",
      },
      {
        code: "47",
        name: "Okinawa",
      },
      {
        code: "41",
        name: "Saga",
      },
      {
        code: "11",
        name: "Saitama",
      },
      {
        code: "25",
        name: "Siga",
      },
      {
        code: "32",
        name: "Simane",
      },
      {
        code: "22",
        name: "Sizuoka",
      },
      {
        code: "12",
        name: "Tiba",
      },
      {
        code: "36",
        name: "Tokusima",
      },
      {
        code: "09",
        name: "Totigi",
      },
      {
        code: "31",
        name: "Tottori",
      },
      {
        code: "16",
        name: "Toyama",
      },
      {
        code: "13",
        name: "Tôkyô",
      },
      {
        code: "30",
        name: "Wakayama",
      },
      {
        code: "06",
        name: "Yamagata",
      },
      {
        code: "35",
        name: "Yamaguti",
      },
      {
        code: "19",
        name: "Yamanasi",
      },
      {
        code: "44",
        name: "Ôita",
      },
      {
        code: "27",
        name: "Ôsaka",
      },
    ],
  },
  {
    code2: "JE",
    code3: "JEY",
    name: "Jersey",
    capital: "Saint Helier",
    region: "Europe",
    subregion: "Northern Europe",
    states: [],
  },
  {
    code2: "JO",
    code3: "JOR",
    name: "Jordan",
    capital: "Amman",
    region: "Asia",
    subregion: "Western Asia",
    states: [
      {
        code: "BA",
        name: "Al Balqā'",
      },
      {
        code: "AQ",
        name: "Al ʽAqabah",
      },
      {
        code: "AZ",
        name: "Az Zarqā'",
      },
      {
        code: "AT",
        name: "Aţ Ţafīlah",
      },
      {
        code: "IR",
        name: "Irbid",
      },
      {
        code: "JA",
        name: "Jerash",
      },
      {
        code: "KA",
        name: "Karak",
      },
      {
        code: "MN",
        name: "Ma'ān",
      },
      {
        code: "MA",
        name: "Mafraq",
      },
      {
        code: "MD",
        name: "Mādabā",
      },
      {
        code: "AJ",
        name: "ʽAjlūn",
      },
      {
        code: "AM",
        name: "‘Ammān",
      },
    ],
  },
  {
    code2: "KZ",
    code3: "KAZ",
    name: "Kazakhstan",
    capital: "Astana",
    region: "Asia",
    subregion: "Central Asia",
    states: [
      {
        code: "ALA",
        name: "Almaty",
      },
      {
        code: "ALM",
        name: "Almaty oblysy",
      },
      {
        code: "AKM",
        name: "Aqmola oblysy",
      },
      {
        code: "AKT",
        name: "Aqtöbe oblysy",
      },
      {
        code: "AST",
        name: "Astana",
      },
      {
        code: "ATY",
        name: "Atyraū oblysy",
      },
      {
        code: "ZAP",
        name: "Batys Qazaqstan oblysy",
      },
      {
        code: "MAN",
        name: "Mangghystaū oblysy",
      },
      {
        code: "YUZ",
        name: "Ongtüstik Qazaqstan oblysy",
      },
      {
        code: "PAV",
        name: "Pavlodar oblysy",
      },
      {
        code: "KAR",
        name: "Qaraghandy oblysy",
      },
      {
        code: "KUS",
        name: "Qostanay oblysy",
      },
      {
        code: "KZY",
        name: "Qyzylorda oblysy",
      },
      {
        code: "VOS",
        name: "Shyghys Qazaqstan oblysy",
      },
      {
        code: "SEV",
        name: "Soltüstik Qazaqstan oblysy",
      },
      {
        code: "ZHA",
        name: "Zhambyl oblysy",
      },
    ],
  },
  {
    code2: "KE",
    code3: "KEN",
    name: "Kenya",
    capital: "Nairobi",
    region: "Africa",
    subregion: "Eastern Africa",
    states: [
      {
        code: "200",
        name: "Central",
      },
      {
        code: "300",
        name: "Coast",
      },
      {
        code: "400",
        name: "Eastern",
      },
      {
        code: "110",
        name: "Nairobi",
      },
      {
        code: "500",
        name: "North-Eastern",
      },
      {
        code: "600",
        name: "Nyanza",
      },
      {
        code: "700",
        name: "Rift Valley",
      },
      {
        code: "800",
        name: "Western",
      },
    ],
  },
  {
    code2: "KI",
    code3: "KIR",
    name: "Kiribati",
    capital: "South Tarawa",
    region: "Oceania",
    subregion: "Micronesia",
    states: [
      {
        code: "G",
        name: "Gilbert Islands",
      },
      {
        code: "L",
        name: "Line Islands",
      },
      {
        code: "P",
        name: "Phoenix Islands",
      },
    ],
  },
  {
    code2: "KW",
    code3: "KWT",
    name: "Kuwait",
    capital: "Kuwait City",
    region: "Asia",
    subregion: "Western Asia",
    states: [
      {
        code: "AH",
        name: "Al Aḩmadi",
      },
      {
        code: "FA",
        name: "Al Farwānīyah",
      },
      {
        code: "JA",
        name: "Al Jahrā’",
      },
      {
        code: "KU",
        name: "Al Kuwayt",
      },
      {
        code: "MU",
        name: "Mubārak al Kabīr",
      },
      {
        code: "HA",
        name: "Ḩawallī",
      },
    ],
  },
  {
    code2: "KG",
    code3: "KGZ",
    name: "Kyrgyzstan",
    capital: "Bishkek",
    region: "Asia",
    subregion: "Central Asia",
    states: [
      {
        code: "B",
        name: "Batken",
      },
      {
        code: "GB",
        name: "Bishkek",
      },
      {
        code: "C",
        name: "Chü",
      },
      {
        code: "J",
        name: "Jalal-Abad",
      },
      {
        code: "N",
        name: "Naryn",
      },
      {
        code: "O",
        name: "Osh",
      },
      {
        code: "T",
        name: "Talas",
      },
      {
        code: "Y",
        name: "Ysyk-Köl",
      },
    ],
  },
  {
    code2: "LA",
    code3: "LAO",
    name: "Laos",
    capital: "Vientiane",
    region: "Asia",
    subregion: "South-Eastern Asia",
    states: [
      {
        code: "AT",
        name: "Attapu",
      },
      {
        code: "BK",
        name: "Bokèo",
      },
      {
        code: "BL",
        name: "Bolikhamxai",
      },
      {
        code: "CH",
        name: "Champasak",
      },
      {
        code: "HO",
        name: "Houaphan",
      },
      {
        code: "KH",
        name: "Khammouan",
      },
      {
        code: "LM",
        name: "Louang Namtha",
      },
      {
        code: "LP",
        name: "Louangphabang",
      },
      {
        code: "OU",
        name: "Oudômxai",
      },
      {
        code: "PH",
        name: "Phôngsali",
      },
      {
        code: "SL",
        name: "Salavan",
      },
      {
        code: "SV",
        name: "Savannakhét",
      },
      {
        code: "VT",
        name: "Vientiane",
      },
      {
        code: "VI",
        name: "Vientiane",
      },
      {
        code: "XA",
        name: "Xaignabouli",
      },
      {
        code: "XN",
        name: "Xaisômboun",
      },
      {
        code: "XI",
        name: "Xiangkhoang",
      },
      {
        code: "XE",
        name: "Xékong",
      },
    ],
  },
  {
    code2: "LV",
    code3: "LVA",
    name: "Latvia",
    capital: "Riga",
    region: "Europe",
    subregion: "Northern Europe",
    states: [
      {
        code: "001",
        name: "Aglonas novads",
      },
      {
        code: "002",
        name: "Aizkraukles novads",
      },
      {
        code: "003",
        name: "Aizputes novads",
      },
      {
        code: "004",
        name: "Aknīstes novads",
      },
      {
        code: "005",
        name: "Alojas novads",
      },
      {
        code: "006",
        name: "Alsungas novads",
      },
      {
        code: "007",
        name: "Alūksnes novads",
      },
      {
        code: "008",
        name: "Amatas novads",
      },
      {
        code: "009",
        name: "Apes novads",
      },
      {
        code: "010",
        name: "Auces novads",
      },
      {
        code: "012",
        name: "Babītes novads",
      },
      {
        code: "013",
        name: "Baldones novads",
      },
      {
        code: "014",
        name: "Baltinavas novads",
      },
      {
        code: "015",
        name: "Balvu novads",
      },
      {
        code: "016",
        name: "Bauskas novads",
      },
      {
        code: "017",
        name: "Beverīnas novads",
      },
      {
        code: "018",
        name: "Brocēnu novads",
      },
      {
        code: "019",
        name: "Burtnieku novads",
      },
      {
        code: "020",
        name: "Carnikavas novads",
      },
      {
        code: "021",
        name: "Cesvaines novads",
      },
      {
        code: "023",
        name: "Ciblas novads",
      },
      {
        code: "022",
        name: "Cēsu novads",
      },
      {
        code: "024",
        name: "Dagdas novads",
      },
      {
        code: "DGV",
        name: "Daugavpils",
      },
      {
        code: "025",
        name: "Daugavpils novads",
      },
      {
        code: "026",
        name: "Dobeles novads",
      },
      {
        code: "027",
        name: "Dundagas novads",
      },
      {
        code: "028",
        name: "Durbes novads",
      },
      {
        code: "029",
        name: "Engures novads",
      },
      {
        code: "031",
        name: "Garkalnes novads",
      },
      {
        code: "032",
        name: "Grobiņas novads",
      },
      {
        code: "033",
        name: "Gulbenes novads",
      },
      {
        code: "034",
        name: "Iecavas novads",
      },
      {
        code: "035",
        name: "Ikšķiles novads",
      },
      {
        code: "036",
        name: "Ilūkstes novads",
      },
      {
        code: "037",
        name: "Inčukalna novads",
      },
      {
        code: "038",
        name: "Jaunjelgavas novads",
      },
      {
        code: "039",
        name: "Jaunpiebalgas novads",
      },
      {
        code: "040",
        name: "Jaunpils novads",
      },
      {
        code: "JEL",
        name: "Jelgava",
      },
      {
        code: "041",
        name: "Jelgavas novads",
      },
      {
        code: "JKB",
        name: "Jēkabpils",
      },
      {
        code: "042",
        name: "Jēkabpils novads",
      },
      {
        code: "JUR",
        name: "Jūrmala",
      },
      {
        code: "043",
        name: "Kandavas novads",
      },
      {
        code: "045",
        name: "Kocēnu novads",
      },
      {
        code: "046",
        name: "Kokneses novads",
      },
      {
        code: "048",
        name: "Krimuldas novads",
      },
      {
        code: "049",
        name: "Krustpils novads",
      },
      {
        code: "047",
        name: "Krāslavas novads",
      },
      {
        code: "050",
        name: "Kuldīgas novads",
      },
      {
        code: "044",
        name: "Kārsavas novads",
      },
      {
        code: "053",
        name: "Lielvārdes novads",
      },
      {
        code: "LPX",
        name: "Liepāja",
      },
      {
        code: "054",
        name: "Limbažu novads",
      },
      {
        code: "057",
        name: "Lubānas novads",
      },
      {
        code: "058",
        name: "Ludzas novads",
      },
      {
        code: "055",
        name: "Līgatnes novads",
      },
      {
        code: "056",
        name: "Līvānu novads",
      },
      {
        code: "059",
        name: "Madonas novads",
      },
      {
        code: "060",
        name: "Mazsalacas novads",
      },
      {
        code: "061",
        name: "Mālpils novads",
      },
      {
        code: "062",
        name: "Mārupes novads",
      },
      {
        code: "063",
        name: "Mērsraga novads",
      },
      {
        code: "064",
        name: "Naukšēnu novads",
      },
      {
        code: "065",
        name: "Neretas novads",
      },
      {
        code: "066",
        name: "Nīcas novads",
      },
      {
        code: "067",
        name: "Ogres novads",
      },
      {
        code: "068",
        name: "Olaines novads",
      },
      {
        code: "069",
        name: "Ozolnieku novads",
      },
      {
        code: "073",
        name: "Preiļu novads",
      },
      {
        code: "074",
        name: "Priekules novads",
      },
      {
        code: "075",
        name: "Priekuļu novads",
      },
      {
        code: "070",
        name: "Pārgaujas novads",
      },
      {
        code: "071",
        name: "Pāvilostas novads",
      },
      {
        code: "072",
        name: "Pļaviņu novads",
      },
      {
        code: "076",
        name: "Raunas novads",
      },
      {
        code: "078",
        name: "Riebiņu novads",
      },
      {
        code: "079",
        name: "Rojas novads",
      },
      {
        code: "080",
        name: "Ropažu novads",
      },
      {
        code: "081",
        name: "Rucavas novads",
      },
      {
        code: "082",
        name: "Rugāju novads",
      },
      {
        code: "083",
        name: "Rundāles novads",
      },
      {
        code: "REZ",
        name: "Rēzekne",
      },
      {
        code: "077",
        name: "Rēzeknes novads",
      },
      {
        code: "RIX",
        name: "Rīga",
      },
      {
        code: "084",
        name: "Rūjienas novads",
      },
      {
        code: "086",
        name: "Salacgrīvas novads",
      },
      {
        code: "085",
        name: "Salas novads",
      },
      {
        code: "087",
        name: "Salaspils novads",
      },
      {
        code: "088",
        name: "Saldus novads",
      },
      {
        code: "089",
        name: "Saulkrastu novads",
      },
      {
        code: "091",
        name: "Siguldas novads",
      },
      {
        code: "093",
        name: "Skrundas novads",
      },
      {
        code: "092",
        name: "Skrīveru novads",
      },
      {
        code: "094",
        name: "Smiltenes novads",
      },
      {
        code: "095",
        name: "Stopiņu novads",
      },
      {
        code: "096",
        name: "Strenču novads",
      },
      {
        code: "090",
        name: "Sējas novads",
      },
      {
        code: "097",
        name: "Talsu novads",
      },
      {
        code: "099",
        name: "Tukuma novads",
      },
      {
        code: "098",
        name: "Tērvetes novads",
      },
      {
        code: "100",
        name: "Vaiņodes novads",
      },
      {
        code: "101",
        name: "Valkas novads",
      },
      {
        code: "VMR",
        name: "Valmiera",
      },
      {
        code: "102",
        name: "Varakļānu novads",
      },
      {
        code: "104",
        name: "Vecpiebalgas novads",
      },
      {
        code: "105",
        name: "Vecumnieku novads",
      },
      {
        code: "VEN",
        name: "Ventspils",
      },
      {
        code: "106",
        name: "Ventspils novads",
      },
      {
        code: "107",
        name: "Viesītes novads",
      },
      {
        code: "108",
        name: "Viļakas novads",
      },
      {
        code: "109",
        name: "Viļānu novads",
      },
      {
        code: "103",
        name: "Vārkavas novads",
      },
      {
        code: "110",
        name: "Zilupes novads",
      },
      {
        code: "011",
        name: "Ādažu novads",
      },
      {
        code: "030",
        name: "Ērgļu novads",
      },
      {
        code: "051",
        name: "Ķeguma novads",
      },
      {
        code: "052",
        name: "Ķekavas novads",
      },
    ],
  },
  {
    code2: "LB",
    code3: "LBN",
    name: "Lebanon",
    capital: "Beirut",
    region: "Asia",
    subregion: "Western Asia",
    states: [
      {
        code: "AK",
        name: "Aakkâr",
      },
      {
        code: "BH",
        name: "Baalbek-Hermel",
      },
      {
        code: "BA",
        name: "Beyrouth",
      },
      {
        code: "BI",
        name: "Béqaa",
      },
      {
        code: "AS",
        name: "Liban-Nord",
      },
      {
        code: "JA",
        name: "Liban-Sud",
      },
      {
        code: "JL",
        name: "Mont-Liban",
      },
      {
        code: "NA",
        name: "Nabatîyé",
      },
    ],
  },
  {
    code2: "LS",
    code3: "LSO",
    name: "Lesotho",
    capital: "Maseru",
    region: "Africa",
    subregion: "Southern Africa",
    states: [
      {
        code: "D",
        name: "Berea",
      },
      {
        code: "B",
        name: "Butha-Buthe",
      },
      {
        code: "C",
        name: "Leribe",
      },
      {
        code: "E",
        name: "Mafeteng",
      },
      {
        code: "A",
        name: "Maseru",
      },
      {
        code: "F",
        name: "Mohale's Hoek",
      },
      {
        code: "J",
        name: "Mokhotlong",
      },
      {
        code: "H",
        name: "Qacha's Nek",
      },
      {
        code: "G",
        name: "Quthing",
      },
      {
        code: "K",
        name: "Thaba-Tseka",
      },
    ],
  },
  {
    code2: "LR",
    code3: "LBR",
    name: "Liberia",
    capital: "Monrovia",
    region: "Africa",
    subregion: "Western Africa",
    states: [
      {
        code: "BM",
        name: "Bomi",
      },
      {
        code: "BG",
        name: "Bong",
      },
      {
        code: "GP",
        name: "Gbarpolu",
      },
      {
        code: "GB",
        name: "Grand Bassa",
      },
      {
        code: "CM",
        name: "Grand Cape Mount",
      },
      {
        code: "GG",
        name: "Grand Gedeh",
      },
      {
        code: "GK",
        name: "Grand Kru",
      },
      {
        code: "LO",
        name: "Lofa",
      },
      {
        code: "MG",
        name: "Margibi",
      },
      {
        code: "MY",
        name: "Maryland",
      },
      {
        code: "MO",
        name: "Montserrado",
      },
      {
        code: "NI",
        name: "Nimba",
      },
      {
        code: "RG",
        name: "River Gee",
      },
      {
        code: "RI",
        name: "Rivercess",
      },
      {
        code: "SI",
        name: "Sinoe",
      },
    ],
  },
  {
    code2: "LY",
    code3: "LBY",
    name: "Libya",
    capital: "Tripoli",
    region: "Africa",
    subregion: "Northern Africa",
    states: [
      {
        code: "BU",
        name: "Al Buţnān",
      },
      {
        code: "JA",
        name: "Al Jabal al Akhḑar",
      },
      {
        code: "JG",
        name: "Al Jabal al Gharbī",
      },
      {
        code: "JI",
        name: "Al Jifārah",
      },
      {
        code: "JU",
        name: "Al Jufrah",
      },
      {
        code: "KF",
        name: "Al Kufrah",
      },
      {
        code: "MJ",
        name: "Al Marj",
      },
      {
        code: "MB",
        name: "Al Marqab",
      },
      {
        code: "WA",
        name: "Al Wāḩāt",
      },
      {
        code: "NQ",
        name: "An Nuqaţ al Khams",
      },
      {
        code: "ZA",
        name: "Az Zāwiyah",
      },
      {
        code: "BA",
        name: "Banghāzī",
      },
      {
        code: "DR",
        name: "Darnah",
      },
      {
        code: "GT",
        name: "Ghāt",
      },
      {
        code: "MI",
        name: "Mişrātah",
      },
      {
        code: "MQ",
        name: "Murzuq",
      },
      {
        code: "NL",
        name: "Nālūt",
      },
      {
        code: "SB",
        name: "Sabhā",
      },
      {
        code: "SR",
        name: "Surt",
      },
      {
        code: "WD",
        name: "Wādī al Ḩayāt",
      },
      {
        code: "WS",
        name: "Wādī ash Shāţiʾ",
      },
      {
        code: "TB",
        name: "Ţarābulus",
      },
    ],
  },
  {
    code2: "LI",
    code3: "LIE",
    name: "Liechtenstein",
    capital: "Vaduz",
    region: "Europe",
    subregion: "Western Europe",
    states: [
      {
        code: "01",
        name: "Balzers",
      },
      {
        code: "02",
        name: "Eschen",
      },
      {
        code: "03",
        name: "Gamprin",
      },
      {
        code: "04",
        name: "Mauren",
      },
      {
        code: "05",
        name: "Planken",
      },
      {
        code: "06",
        name: "Ruggell",
      },
      {
        code: "07",
        name: "Schaan",
      },
      {
        code: "08",
        name: "Schellenberg",
      },
      {
        code: "09",
        name: "Triesen",
      },
      {
        code: "10",
        name: "Triesenberg",
      },
      {
        code: "11",
        name: "Vaduz",
      },
    ],
  },
  {
    code2: "LT",
    code3: "LTU",
    name: "Lithuania",
    capital: "Vilnius",
    region: "Europe",
    subregion: "Northern Europe",
    states: [
      {
        code: "AL",
        name: "Alytaus Apskritis",
      },
      {
        code: "KU",
        name: "Kauno Apskritis",
      },
      {
        code: "KL",
        name: "Klaipėdos Apskritis",
      },
      {
        code: "MR",
        name: "Marijampolės Apskritis",
      },
      {
        code: "PN",
        name: "Panevėžio Apskritis",
      },
      {
        code: "TA",
        name: "Tauragės Apskritis",
      },
      {
        code: "TE",
        name: "Telšių Apskritis",
      },
      {
        code: "UT",
        name: "Utenos Apskritis",
      },
      {
        code: "VL",
        name: "Vilniaus Apskritis",
      },
      {
        code: "SA",
        name: "Šiaulių Apskritis",
      },
    ],
  },
  {
    code2: "LU",
    code3: "LUX",
    name: "Luxembourg",
    capital: "Luxembourg",
    region: "Europe",
    subregion: "Western Europe",
    states: [
      {
        code: "D",
        name: "Diekirch",
      },
      {
        code: "G",
        name: "Grevenmacher",
      },
      {
        code: "L",
        name: "Luxembourg",
      },
    ],
  },
  {
    code2: "MO",
    code3: "MAC",
    name: "Macau",
    capital: "",
    region: "Asia",
    subregion: "Eastern Asia",
    states: [],
  },
  {
    code2: "MK",
    code3: "MKD",
    name: "Republic of Macedonia",
    capital: "Skopje",
    region: "Europe",
    subregion: "Southern Europe",
    states: [
      {
        code: "01",
        name: "Aerodrom",
      },
      {
        code: "02",
        name: "Aračinovo",
      },
      {
        code: "03",
        name: "Berovo",
      },
      {
        code: "04",
        name: "Bitola",
      },
      {
        code: "05",
        name: "Bogdanci",
      },
      {
        code: "06",
        name: "Bogovinje",
      },
      {
        code: "07",
        name: "Bosilovo",
      },
      {
        code: "08",
        name: "Brvenica",
      },
      {
        code: "09",
        name: "Butel",
      },
      {
        code: "77",
        name: "Centar",
      },
      {
        code: "78",
        name: "Centar Župa",
      },
      {
        code: "21",
        name: "Debar",
      },
      {
        code: "22",
        name: "Debarca",
      },
      {
        code: "23",
        name: "Delčevo",
      },
      {
        code: "25",
        name: "Demir Hisar",
      },
      {
        code: "24",
        name: "Demir Kapija",
      },
      {
        code: "26",
        name: "Dojran",
      },
      {
        code: "27",
        name: "Dolneni",
      },
      {
        code: "28",
        name: "Drugovo",
      },
      {
        code: "17",
        name: "Gazi Baba",
      },
      {
        code: "18",
        name: "Gevgelija",
      },
      {
        code: "29",
        name: "Gjorče Petrov",
      },
      {
        code: "19",
        name: "Gostivar",
      },
      {
        code: "20",
        name: "Gradsko",
      },
      {
        code: "34",
        name: "Ilinden",
      },
      {
        code: "35",
        name: "Jegunovce",
      },
      {
        code: "37",
        name: "Karbinci",
      },
      {
        code: "38",
        name: "Karpoš",
      },
      {
        code: "36",
        name: "Kavadarci",
      },
      {
        code: "39",
        name: "Kisela Voda",
      },
      {
        code: "40",
        name: "Kičevo",
      },
      {
        code: "41",
        name: "Konče",
      },
      {
        code: "42",
        name: "Kočani",
      },
      {
        code: "43",
        name: "Kratovo",
      },
      {
        code: "44",
        name: "Kriva Palanka",
      },
      {
        code: "45",
        name: "Krivogaštani",
      },
      {
        code: "46",
        name: "Kruševo",
      },
      {
        code: "47",
        name: "Kumanovo",
      },
      {
        code: "48",
        name: "Lipkovo",
      },
      {
        code: "49",
        name: "Lozovo",
      },
      {
        code: "51",
        name: "Makedonska Kamenica",
      },
      {
        code: "52",
        name: "Makedonski Brod",
      },
      {
        code: "50",
        name: "Mavrovo i Rostuša",
      },
      {
        code: "53",
        name: "Mogila",
      },
      {
        code: "54",
        name: "Negotino",
      },
      {
        code: "55",
        name: "Novaci",
      },
      {
        code: "56",
        name: "Novo Selo",
      },
      {
        code: "58",
        name: "Ohrid",
      },
      {
        code: "57",
        name: "Oslomej",
      },
      {
        code: "60",
        name: "Pehčevo",
      },
      {
        code: "59",
        name: "Petrovec",
      },
      {
        code: "61",
        name: "Plasnica",
      },
      {
        code: "62",
        name: "Prilep",
      },
      {
        code: "63",
        name: "Probištip",
      },
      {
        code: "64",
        name: "Radoviš",
      },
      {
        code: "65",
        name: "Rankovce",
      },
      {
        code: "66",
        name: "Resen",
      },
      {
        code: "67",
        name: "Rosoman",
      },
      {
        code: "68",
        name: "Saraj",
      },
      {
        code: "70",
        name: "Sopište",
      },
      {
        code: "71",
        name: "Staro Nagoričane",
      },
      {
        code: "72",
        name: "Struga",
      },
      {
        code: "73",
        name: "Strumica",
      },
      {
        code: "74",
        name: "Studeničani",
      },
      {
        code: "69",
        name: "Sveti Nikole",
      },
      {
        code: "75",
        name: "Tearce",
      },
      {
        code: "76",
        name: "Tetovo",
      },
      {
        code: "10",
        name: "Valandovo",
      },
      {
        code: "11",
        name: "Vasilevo",
      },
      {
        code: "13",
        name: "Veles",
      },
      {
        code: "12",
        name: "Vevčani",
      },
      {
        code: "14",
        name: "Vinica",
      },
      {
        code: "15",
        name: "Vraneštica",
      },
      {
        code: "16",
        name: "Vrapčište",
      },
      {
        code: "31",
        name: "Zajas",
      },
      {
        code: "32",
        name: "Zelenikovo",
      },
      {
        code: "33",
        name: "Zrnovci",
      },
      {
        code: "79",
        name: "Čair",
      },
      {
        code: "80",
        name: "Čaška",
      },
      {
        code: "81",
        name: "Češinovo-Obleševo",
      },
      {
        code: "82",
        name: "Čučer Sandevo",
      },
      {
        code: "83",
        name: "Štip",
      },
      {
        code: "84",
        name: "Šuto Orizari",
      },
      {
        code: "30",
        name: "Želino",
      },
    ],
  },
  {
    code2: "MG",
    code3: "MDG",
    name: "Madagascar",
    capital: "Antananarivo",
    region: "Africa",
    subregion: "Eastern Africa",
    states: [
      {
        code: "T",
        name: "Antananarivo",
      },
      {
        code: "D",
        name: "Antsiranana",
      },
      {
        code: "F",
        name: "Fianarantsoa",
      },
      {
        code: "M",
        name: "Mahajanga",
      },
      {
        code: "A",
        name: "Toamasina",
      },
      {
        code: "U",
        name: "Toliara",
      },
    ],
  },
  {
    code2: "MW",
    code3: "MWI",
    name: "Malawi",
    capital: "Lilongwe",
    region: "Africa",
    subregion: "Eastern Africa",
    states: [
      {
        code: "C",
        name: "Central Region",
      },
      {
        code: "N",
        name: "Northern Region",
      },
      {
        code: "S",
        name: "Southern Region",
      },
    ],
  },
  {
    code2: "MY",
    code3: "MYS",
    name: "Malaysia",
    capital: "Kuala Lumpur",
    region: "Asia",
    subregion: "South-Eastern Asia",
    states: [
      {
        code: "14",
        name: "Wilayah Persekutuan Kuala Lumpur",
      },
      {
        code: "15",
        name: "Wilayah Persekutuan Labuan",
      },
      {
        code: "16",
        name: "Wilayah Persekutuan Putrajaya",
      },
      {
        code: "01",
        name: "Johor",
      },
      {
        code: "02",
        name: "Kedah",
      },
      {
        code: "03",
        name: "Kelantan",
      },
      {
        code: "04",
        name: "Melaka",
      },
      {
        code: "05",
        name: "Negeri Sembilan",
      },
      {
        code: "06",
        name: "Pahang",
      },
      {
        code: "08",
        name: "Perak",
      },
      {
        code: "09",
        name: "Perlis",
      },
      {
        code: "07",
        name: "Pulau Pinang",
      },
      {
        code: "12",
        name: "Sabah",
      },
      {
        code: "13",
        name: "Sarawak",
      },
      {
        code: "10",
        name: "Selangor",
      },
      {
        code: "11",
        name: "Terengganu",
      },
    ],
  },
  {
    code2: "MV",
    code3: "MDV",
    name: "Maldives",
    capital: "Malé",
    region: "Asia",
    subregion: "Southern Asia",
    states: [
      {
        code: "CE",
        name: "Central",
      },
      {
        code: "MLE",
        name: "Male",
      },
      {
        code: "NO",
        name: "North",
      },
      {
        code: "NC",
        name: "North Central",
      },
      {
        code: "SU",
        name: "South",
      },
      {
        code: "SC",
        name: "South Central",
      },
      {
        code: "UN",
        name: "Upper North",
      },
      {
        code: "US",
        name: "Upper South",
      },
    ],
  },
  {
    code2: "ML",
    code3: "MLI",
    name: "Mali",
    capital: "Bamako",
    region: "Africa",
    subregion: "Western Africa",
    states: [
      {
        code: "BKO",
        name: "Bamako",
      },
      {
        code: "7",
        name: "Gao",
      },
      {
        code: "1",
        name: "Kayes",
      },
      {
        code: "8",
        name: "Kidal",
      },
      {
        code: "2",
        name: "Koulikoro",
      },
      {
        code: "5",
        name: "Mopti",
      },
      {
        code: "3",
        name: "Sikasso",
      },
      {
        code: "4",
        name: "Ségou",
      },
      {
        code: "6",
        name: "Tombouctou",
      },
    ],
  },
  {
    code2: "MT",
    code3: "MLT",
    name: "Malta",
    capital: "Valletta",
    region: "Europe",
    subregion: "Southern Europe",
    states: [
      {
        code: "01",
        name: "Attard",
      },
      {
        code: "02",
        name: "Balzan",
      },
      {
        code: "03",
        name: "Birgu",
      },
      {
        code: "04",
        name: "Birkirkara",
      },
      {
        code: "05",
        name: "Birżebbuġa",
      },
      {
        code: "06",
        name: "Bormla",
      },
      {
        code: "07",
        name: "Dingli",
      },
      {
        code: "08",
        name: "Fgura",
      },
      {
        code: "09",
        name: "Floriana",
      },
      {
        code: "10",
        name: "Fontana",
      },
      {
        code: "11",
        name: "Gudja",
      },
      {
        code: "13",
        name: "Għajnsielem",
      },
      {
        code: "14",
        name: "Għarb",
      },
      {
        code: "15",
        name: "Għargħur",
      },
      {
        code: "16",
        name: "Għasri",
      },
      {
        code: "17",
        name: "Għaxaq",
      },
      {
        code: "12",
        name: "Gżira",
      },
      {
        code: "19",
        name: "Iklin",
      },
      {
        code: "20",
        name: "Isla",
      },
      {
        code: "21",
        name: "Kalkara",
      },
      {
        code: "22",
        name: "Kerċem",
      },
      {
        code: "23",
        name: "Kirkop",
      },
      {
        code: "24",
        name: "Lija",
      },
      {
        code: "25",
        name: "Luqa",
      },
      {
        code: "26",
        name: "Marsa",
      },
      {
        code: "27",
        name: "Marsaskala",
      },
      {
        code: "28",
        name: "Marsaxlokk",
      },
      {
        code: "29",
        name: "Mdina",
      },
      {
        code: "30",
        name: "Mellieħa",
      },
      {
        code: "32",
        name: "Mosta",
      },
      {
        code: "33",
        name: "Mqabba",
      },
      {
        code: "34",
        name: "Msida",
      },
      {
        code: "35",
        name: "Mtarfa",
      },
      {
        code: "36",
        name: "Munxar",
      },
      {
        code: "31",
        name: "Mġarr",
      },
      {
        code: "37",
        name: "Nadur",
      },
      {
        code: "38",
        name: "Naxxar",
      },
      {
        code: "39",
        name: "Paola",
      },
      {
        code: "40",
        name: "Pembroke",
      },
      {
        code: "41",
        name: "Pietà",
      },
      {
        code: "42",
        name: "Qala",
      },
      {
        code: "43",
        name: "Qormi",
      },
      {
        code: "44",
        name: "Qrendi",
      },
      {
        code: "45",
        name: "Rabat Għawdex",
      },
      {
        code: "46",
        name: "Rabat Malta",
      },
      {
        code: "47",
        name: "Safi",
      },
      {
        code: "50",
        name: "San Lawrenz",
      },
      {
        code: "51",
        name: "San Pawl il-Baħar",
      },
      {
        code: "48",
        name: "San Ġiljan",
      },
      {
        code: "49",
        name: "San Ġwann",
      },
      {
        code: "52",
        name: "Sannat",
      },
      {
        code: "53",
        name: "Santa Luċija",
      },
      {
        code: "54",
        name: "Santa Venera",
      },
      {
        code: "55",
        name: "Siġġiewi",
      },
      {
        code: "56",
        name: "Sliema",
      },
      {
        code: "57",
        name: "Swieqi",
      },
      {
        code: "58",
        name: "Ta' Xbiex",
      },
      {
        code: "59",
        name: "Tarxien",
      },
      {
        code: "60",
        name: "Valletta",
      },
      {
        code: "61",
        name: "Xagħra",
      },
      {
        code: "62",
        name: "Xewkija",
      },
      {
        code: "63",
        name: "Xgħajra",
      },
      {
        code: "18",
        name: "Ħamrun",
      },
      {
        code: "64",
        name: "Żabbar",
      },
      {
        code: "65",
        name: "Żebbuġ Għawdex",
      },
      {
        code: "66",
        name: "Żebbuġ Malta",
      },
      {
        code: "67",
        name: "Żejtun",
      },
      {
        code: "68",
        name: "Żurrieq",
      },
    ],
  },
  {
    code2: "MH",
    code3: "MHL",
    name: "Marshall Islands",
    capital: "Majuro",
    region: "Oceania",
    subregion: "Micronesia",
    states: [
      {
        code: "L",
        name: "Ralik chain",
      },
      {
        code: "T",
        name: "Ratak chain",
      },
    ],
  },
  {
    code2: "MQ",
    code3: "MTQ",
    name: "Martinique",
    capital: "Fort-de-France",
    region: "Americas",
    subregion: "Caribbean",
    states: [],
  },
  {
    code2: "MR",
    code3: "MRT",
    name: "Mauritania",
    capital: "Nouakchott",
    region: "Africa",
    subregion: "Western Africa",
    states: [
      {
        code: "07",
        name: "Adrar",
      },
      {
        code: "03",
        name: "Assaba",
      },
      {
        code: "05",
        name: "Brakna",
      },
      {
        code: "08",
        name: "Dakhlet Nouâdhibou",
      },
      {
        code: "04",
        name: "Gorgol",
      },
      {
        code: "10",
        name: "Guidimaka",
      },
      {
        code: "01",
        name: "Hodh ech Chargui",
      },
      {
        code: "02",
        name: "Hodh el Gharbi",
      },
      {
        code: "12",
        name: "Inchiri",
      },
      {
        code: "NKC",
        name: "Nouakchott",
      },
      {
        code: "09",
        name: "Tagant",
      },
      {
        code: "11",
        name: "Tiris Zemmour",
      },
      {
        code: "06",
        name: "Trarza",
      },
    ],
  },
  {
    code2: "MU",
    code3: "MUS",
    name: "Mauritius",
    capital: "Port Louis",
    region: "Africa",
    subregion: "Eastern Africa",
    states: [
      {
        code: "AG",
        name: "Agalega Islands",
      },
      {
        code: "BR",
        name: "Beau Bassin-Rose Hill",
      },
      {
        code: "BL",
        name: "Black River",
      },
      {
        code: "CC",
        name: "Cargados Carajos Shoals",
      },
      {
        code: "CU",
        name: "Curepipe",
      },
      {
        code: "FL",
        name: "Flacq",
      },
      {
        code: "GP",
        name: "Grand Port",
      },
      {
        code: "MO",
        name: "Moka",
      },
      {
        code: "PA",
        name: "Pamplemousses",
      },
      {
        code: "PW",
        name: "Plaines Wilhems",
      },
      {
        code: "PL",
        name: "Port Louis",
      },
      {
        code: "PU",
        name: "Port Louis",
      },
      {
        code: "QB",
        name: "Quatre Bornes",
      },
      {
        code: "RR",
        name: "Rivière du Rempart",
      },
      {
        code: "RO",
        name: "Rodrigues Island",
      },
      {
        code: "SA",
        name: "Savanne",
      },
      {
        code: "VP",
        name: "Vacoas-Phoenix",
      },
    ],
  },
  {
    code2: "YT",
    code3: "MYT",
    name: "Mayotte",
    capital: "Mamoudzou",
    region: "Africa",
    subregion: "Eastern Africa",
    states: [],
  },
  {
    code2: "MX",
    code3: "MEX",
    name: "Mexico",
    capital: "Mexico City",
    region: "Americas",
    subregion: "Central America",
    states: [
      {
        code: "DIF",
        name: "Distrito Federal",
      },
      {
        code: "AGU",
        name: "Aguascalientes",
      },
      {
        code: "BCN",
        name: "Baja California",
      },
      {
        code: "BCS",
        name: "Baja California Sur",
      },
      {
        code: "CAM",
        name: "Campeche",
      },
      {
        code: "CHP",
        name: "Chiapas",
      },
      {
        code: "CHH",
        name: "Chihuahua",
      },
      {
        code: "COA",
        name: "Coahuila",
      },
      {
        code: "COL",
        name: "Colima",
      },
      {
        code: "DUR",
        name: "Durango",
      },
      {
        code: "GUA",
        name: "Guanajuato",
      },
      {
        code: "GRO",
        name: "Guerrero",
      },
      {
        code: "HID",
        name: "Hidalgo",
      },
      {
        code: "JAL",
        name: "Jalisco",
      },
      {
        code: "MIC",
        name: "Michoacán",
      },
      {
        code: "MOR",
        name: "Morelos",
      },
      {
        code: "MEX",
        name: "México",
      },
      {
        code: "NAY",
        name: "Nayarit",
      },
      {
        code: "NLE",
        name: "Nuevo León",
      },
      {
        code: "OAX",
        name: "Oaxaca",
      },
      {
        code: "PUE",
        name: "Puebla",
      },
      {
        code: "QUE",
        name: "Querétaro",
      },
      {
        code: "ROO",
        name: "Quintana Roo",
      },
      {
        code: "SLP",
        name: "San Luis Potosí",
      },
      {
        code: "SIN",
        name: "Sinaloa",
      },
      {
        code: "SON",
        name: "Sonora",
      },
      {
        code: "TAB",
        name: "Tabasco",
      },
      {
        code: "TAM",
        name: "Tamaulipas",
      },
      {
        code: "TLA",
        name: "Tlaxcala",
      },
      {
        code: "VER",
        name: "Veracruz",
      },
      {
        code: "YUC",
        name: "Yucatán",
      },
      {
        code: "ZAC",
        name: "Zacatecas",
      },
    ],
  },
  {
    code2: "FM",
    code3: "FSM",
    name: "Federated States of Micronesia",
    capital: "Palikir",
    region: "Oceania",
    subregion: "Micronesia",
    states: [
      {
        code: "TRK",
        name: "Chuuk",
      },
      {
        code: "KSA",
        name: "Kosrae",
      },
      {
        code: "PNI",
        name: "Pohnpei",
      },
      {
        code: "YAP",
        name: "Yap",
      },
    ],
  },
  {
    code2: "MD",
    code3: "MDA",
    name: "Moldova",
    capital: "Chișinău",
    region: "Europe",
    subregion: "Eastern Europe",
    states: [
      {
        code: "AN",
        name: "Anenii Noi",
      },
      {
        code: "BS",
        name: "Basarabeasca",
      },
      {
        code: "BR",
        name: "Briceni",
      },
      {
        code: "BA",
        name: "Bălţi",
      },
      {
        code: "CA",
        name: "Cahul",
      },
      {
        code: "CT",
        name: "Cantemir",
      },
      {
        code: "CU",
        name: "Chişinău",
      },
      {
        code: "CM",
        name: "Cimişlia",
      },
      {
        code: "CR",
        name: "Criuleni",
      },
      {
        code: "CL",
        name: "Călăraşi",
      },
      {
        code: "CS",
        name: "Căuşeni",
      },
      {
        code: "DO",
        name: "Donduşeni",
      },
      {
        code: "DR",
        name: "Drochia",
      },
      {
        code: "DU",
        name: "Dubăsari",
      },
      {
        code: "ED",
        name: "Edineţ",
      },
      {
        code: "FL",
        name: "Floreşti",
      },
      {
        code: "FA",
        name: "Făleşti",
      },
      {
        code: "GL",
        name: "Glodeni",
      },
      {
        code: "GA",
        name: "Găgăuzia, Unitatea teritorială autonomă",
      },
      {
        code: "HI",
        name: "Hînceşti",
      },
      {
        code: "IA",
        name: "Ialoveni",
      },
      {
        code: "LE",
        name: "Leova",
      },
      {
        code: "NI",
        name: "Nisporeni",
      },
      {
        code: "OC",
        name: "Ocniţa",
      },
      {
        code: "OR",
        name: "Orhei",
      },
      {
        code: "RE",
        name: "Rezina",
      },
      {
        code: "RI",
        name: "Rîşcani",
      },
      {
        code: "SO",
        name: "Soroca",
      },
      {
        code: "ST",
        name: "Străşeni",
      },
      {
        code: "SN",
        name: "Stînga Nistrului, unitatea teritorială din",
      },
      {
        code: "SI",
        name: "Sîngerei",
      },
      {
        code: "TA",
        name: "Taraclia",
      },
      {
        code: "TE",
        name: "Teleneşti",
      },
      {
        code: "BD",
        name: "Tighina",
      },
      {
        code: "UN",
        name: "Ungheni",
      },
      {
        code: "SD",
        name: "Şoldăneşti",
      },
      {
        code: "SV",
        name: "Ştefan Vodă",
      },
    ],
  },
  {
    code2: "MC",
    code3: "MCO",
    name: "Monaco",
    capital: "Monaco",
    region: "Europe",
    subregion: "Western Europe",
    states: [
      {
        code: "FO",
        name: "Fontvieille",
      },
      {
        code: "JE",
        name: "Jardin Exotique",
      },
      {
        code: "CL",
        name: "La Colle",
      },
      {
        code: "CO",
        name: "La Condamine",
      },
      {
        code: "GA",
        name: "La Gare",
      },
      {
        code: "SO",
        name: "La Source",
      },
      {
        code: "LA",
        name: "Larvotto",
      },
      {
        code: "MA",
        name: "Malbousquet",
      },
      {
        code: "MO",
        name: "Monaco-Ville",
      },
      {
        code: "MG",
        name: "Moneghetti",
      },
      {
        code: "MC",
        name: "Monte-Carlo",
      },
      {
        code: "MU",
        name: "Moulins",
      },
      {
        code: "PH",
        name: "Port-Hercule",
      },
      {
        code: "SR",
        name: "Saint-Roman",
      },
      {
        code: "SD",
        name: "Sainte-Dévote",
      },
      {
        code: "SP",
        name: "Spélugues",
      },
      {
        code: "VR",
        name: "Vallon de la Rousse",
      },
    ],
  },
  {
    code2: "MN",
    code3: "MNG",
    name: "Mongolia",
    capital: "Ulan Bator",
    region: "Asia",
    subregion: "Eastern Asia",
    states: [
      {
        code: "073",
        name: "Arhangay",
      },
      {
        code: "071",
        name: "Bayan-Ölgiy",
      },
      {
        code: "069",
        name: "Bayanhongor",
      },
      {
        code: "067",
        name: "Bulgan",
      },
      {
        code: "037",
        name: "Darhan uul",
      },
      {
        code: "061",
        name: "Dornod",
      },
      {
        code: "063",
        name: "Dornogovĭ",
      },
      {
        code: "059",
        name: "Dundgovĭ",
      },
      {
        code: "057",
        name: "Dzavhan",
      },
      {
        code: "065",
        name: "Govĭ-Altay",
      },
      {
        code: "064",
        name: "Govĭ-Sümber",
      },
      {
        code: "039",
        name: "Hentiy",
      },
      {
        code: "043",
        name: "Hovd",
      },
      {
        code: "041",
        name: "Hövsgöl",
      },
      {
        code: "035",
        name: "Orhon",
      },
      {
        code: "049",
        name: "Selenge",
      },
      {
        code: "051",
        name: "Sühbaatar",
      },
      {
        code: "047",
        name: "Töv",
      },
      {
        code: "1",
        name: "Ulaanbaatar",
      },
      {
        code: "046",
        name: "Uvs",
      },
      {
        code: "053",
        name: "Ömnögovĭ",
      },
      {
        code: "055",
        name: "Övörhangay",
      },
    ],
  },
  {
    code2: "ME",
    code3: "MNE",
    name: "Montenegro",
    capital: "Podgorica",
    region: "Europe",
    subregion: "Southern Europe",
    states: [
      {
        code: "01",
        name: "Andrijevica",
      },
      {
        code: "02",
        name: "Bar",
      },
      {
        code: "03",
        name: "Berane",
      },
      {
        code: "04",
        name: "Bijelo Polje",
      },
      {
        code: "05",
        name: "Budva",
      },
      {
        code: "06",
        name: "Cetinje",
      },
      {
        code: "07",
        name: "Danilovgrad",
      },
      {
        code: "22",
        name: "Gusinje",
      },
      {
        code: "08",
        name: "Herceg-Novi",
      },
      {
        code: "09",
        name: "Kolašin",
      },
      {
        code: "10",
        name: "Kotor",
      },
      {
        code: "11",
        name: "Mojkovac",
      },
      {
        code: "12",
        name: "Nikšić",
      },
      {
        code: "23",
        name: "Petnjica",
      },
      {
        code: "13",
        name: "Plav",
      },
      {
        code: "14",
        name: "Pljevlja",
      },
      {
        code: "15",
        name: "Plužine",
      },
      {
        code: "16",
        name: "Podgorica",
      },
      {
        code: "17",
        name: "Rožaje",
      },
      {
        code: "19",
        name: "Tivat",
      },
      {
        code: "20",
        name: "Ulcinj",
      },
      {
        code: "18",
        name: "Šavnik",
      },
      {
        code: "21",
        name: "Žabljak",
      },
    ],
  },
  {
    code2: "MS",
    code3: "MSR",
    name: "Montserrat",
    capital: "Plymouth",
    region: "Americas",
    subregion: "Caribbean",
    states: [],
  },
  {
    code2: "MA",
    code3: "MAR",
    name: "Morocco",
    capital: "Rabat",
    region: "Africa",
    subregion: "Northern Africa",
    states: [
      {
        code: "09",
        name: "Chaouia-Ouardigha",
      },
      {
        code: "10",
        name: "Doukhala-Abda",
      },
      {
        code: "05",
        name: "Fès-Boulemane",
      },
      {
        code: "02",
        name: "Gharb-Chrarda-Beni Hssen",
      },
      {
        code: "08",
        name: "Grand Casablanca",
      },
      {
        code: "14",
        name: "Guelmim-Es Smara",
      },
      {
        code: "04",
        name: "L'Oriental",
      },
      {
        code: "15",
        name: "Laâyoune-Boujdour-Sakia el Hamra",
      },
      {
        code: "11",
        name: "Marrakech-Tensift-Al Haouz",
      },
      {
        code: "06",
        name: "Meknès-Tafilalet",
      },
      {
        code: "16",
        name: "Oued ed Dahab-Lagouira",
      },
      {
        code: "07",
        name: "Rabat-Salé-Zemmour-Zaer",
      },
      {
        code: "13",
        name: "Souss-Massa-Drâa",
      },
      {
        code: "12",
        name: "Tadla-Azilal",
      },
      {
        code: "01",
        name: "Tanger-Tétouan",
      },
      {
        code: "03",
        name: "Taza-Al Hoceima-Taounate",
      },
    ],
  },
  {
    code2: "MZ",
    code3: "MOZ",
    name: "Mozambique",
    capital: "Maputo",
    region: "Africa",
    subregion: "Eastern Africa",
    states: [
      {
        code: "P",
        name: "Cabo Delgado",
      },
      {
        code: "G",
        name: "Gaza",
      },
      {
        code: "I",
        name: "Inhambane",
      },
      {
        code: "B",
        name: "Manica",
      },
      {
        code: "MPM",
        name: "Maputo",
      },
      {
        code: "L",
        name: "Maputo",
      },
      {
        code: "N",
        name: "Nampula",
      },
      {
        code: "A",
        name: "Niassa",
      },
      {
        code: "S",
        name: "Sofala",
      },
      {
        code: "T",
        name: "Tete",
      },
      {
        code: "Q",
        name: "Zambézia",
      },
    ],
  },
  {
    code2: "MM",
    code3: "MMR",
    name: "Myanmar",
    capital: "Naypyidaw",
    region: "Asia",
    subregion: "South-Eastern Asia",
    states: [
      {
        code: "07",
        name: "Ayeyarwady",
      },
      {
        code: "02",
        name: "Bago",
      },
      {
        code: "14",
        name: "Chin",
      },
      {
        code: "11",
        name: "Kachin",
      },
      {
        code: "12",
        name: "Kayah",
      },
      {
        code: "13",
        name: "Kayin",
      },
      {
        code: "03",
        name: "Magway",
      },
      {
        code: "04",
        name: "Mandalay",
      },
      {
        code: "15",
        name: "Mon",
      },
      {
        code: "16",
        name: "Rakhine",
      },
      {
        code: "01",
        name: "Sagaing",
      },
      {
        code: "17",
        name: "Shan",
      },
      {
        code: "05",
        name: "Tanintharyi",
      },
      {
        code: "06",
        name: "Yangon",
      },
    ],
  },
  {
    code2: "NA",
    code3: "NAM",
    name: "Namibia",
    capital: "Windhoek",
    region: "Africa",
    subregion: "Southern Africa",
    states: [
      {
        code: "ER",
        name: "Erongo",
      },
      {
        code: "HA",
        name: "Hardap",
      },
      {
        code: "KA",
        name: "Karas",
      },
      {
        code: "KE",
        name: "Kavango East",
      },
      {
        code: "KW",
        name: "Kavango West",
      },
      {
        code: "KH",
        name: "Khomas",
      },
      {
        code: "KU",
        name: "Kunene",
      },
      {
        code: "OW",
        name: "Ohangwena",
      },
      {
        code: "OH",
        name: "Omaheke",
      },
      {
        code: "OS",
        name: "Omusati",
      },
      {
        code: "ON",
        name: "Oshana",
      },
      {
        code: "OT",
        name: "Oshikoto",
      },
      {
        code: "OD",
        name: "Otjozondjupa",
      },
      {
        code: "CA",
        name: "Zambezi",
      },
    ],
  },
  {
    code2: "NR",
    code3: "NRU",
    name: "Nauru",
    capital: "Yaren",
    region: "Oceania",
    subregion: "Micronesia",
    states: [
      {
        code: "01",
        name: "Aiwo",
      },
      {
        code: "02",
        name: "Anabar",
      },
      {
        code: "03",
        name: "Anetan",
      },
      {
        code: "04",
        name: "Anibare",
      },
      {
        code: "05",
        name: "Baiti",
      },
      {
        code: "06",
        name: "Boe",
      },
      {
        code: "07",
        name: "Buada",
      },
      {
        code: "08",
        name: "Denigomodu",
      },
      {
        code: "09",
        name: "Ewa",
      },
      {
        code: "10",
        name: "Ijuw",
      },
      {
        code: "11",
        name: "Meneng",
      },
      {
        code: "12",
        name: "Nibok",
      },
      {
        code: "13",
        name: "Uaboe",
      },
      {
        code: "14",
        name: "Yaren",
      },
    ],
  },
  {
    code2: "NP",
    code3: "NPL",
    name: "Nepal",
    capital: "Kathmandu",
    region: "Asia",
    subregion: "Southern Asia",
    states: [
      {
        code: "2",
        name: "Madhya Pashchimanchal",
      },
      {
        code: "1",
        name: "Madhyamanchal",
      },
      {
        code: "3",
        name: "Pashchimanchal",
      },
      {
        code: "4",
        name: "Purwanchal",
      },
      {
        code: "5",
        name: "Sudur Pashchimanchal",
      },
    ],
  },
  {
    code2: "NL",
    code3: "NLD",
    name: "Netherlands",
    capital: "Amsterdam",
    region: "Europe",
    subregion: "Western Europe",
    states: [
      {
        code: "DR",
        name: "Drenthe",
      },
      {
        code: "FL",
        name: "Flevoland",
      },
      {
        code: "FR",
        name: "Fryslân",
      },
      {
        code: "GE",
        name: "Gelderland",
      },
      {
        code: "GR",
        name: "Groningen",
      },
      {
        code: "LI",
        name: "Limburg",
      },
      {
        code: "NB",
        name: "Noord-Brabant",
      },
      {
        code: "NH",
        name: "Noord-Holland",
      },
      {
        code: "OV",
        name: "Overijssel",
      },
      {
        code: "UT",
        name: "Utrecht",
      },
      {
        code: "ZE",
        name: "Zeeland",
      },
      {
        code: "ZH",
        name: "Zuid-Holland",
      },
      {
        code: "AW",
        name: "Aruba",
      },
      {
        code: "CW",
        name: "Curaçao",
      },
      {
        code: "SX",
        name: "Sint Maarten",
      },
      {
        code: "BQ1",
        name: "Bonaire",
      },
      {
        code: "BQ2",
        name: "Saba",
      },
      {
        code: "BQ3",
        name: "Sint Eustatius",
      },
    ],
  },
  {
    code2: "NC",
    code3: "NCL",
    name: "New Caledonia",
    capital: "Nouméa",
    region: "Oceania",
    subregion: "Melanesia",
    states: [],
  },
  {
    code2: "NZ",
    code3: "NZL",
    name: "New Zealand",
    capital: "Wellington",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    states: [
      {
        code: "N",
        name: "North Island",
      },
      {
        code: "S",
        name: "South Island",
      },
      {
        code: "AUK",
        name: "Auckland",
      },
      {
        code: "BOP",
        name: "Bay of Plenty",
      },
      {
        code: "CAN",
        name: "Canterbury",
      },
      {
        code: "HKB",
        name: "Hawke's Bay",
      },
      {
        code: "MWT",
        name: "Manawatu-Wanganui",
      },
      {
        code: "NTL",
        name: "Northland",
      },
      {
        code: "OTA",
        name: "Otago",
      },
      {
        code: "STL",
        name: "Southland",
      },
      {
        code: "TKI",
        name: "Taranaki",
      },
      {
        code: "WKO",
        name: "Waikato",
      },
      {
        code: "WGN",
        name: "Wellington",
      },
      {
        code: "WTC",
        name: "West Coast",
      },
      {
        code: "CIT",
        name: "Chatham Islands Territory",
      },
      {
        code: "GIS",
        name: "Gisborne District",
      },
      {
        code: "MBH",
        name: "Marlborough District",
      },
      {
        code: "NSN",
        name: "Nelson City",
      },
      {
        code: "TAS",
        name: "Tasman District",
      },
    ],
  },
  {
    code2: "NI",
    code3: "NIC",
    name: "Nicaragua",
    capital: "Managua",
    region: "Americas",
    subregion: "Central America",
    states: [
      {
        code: "AN",
        name: "Atlántico Norte",
      },
      {
        code: "AS",
        name: "Atlántico Sur",
      },
      {
        code: "BO",
        name: "Boaco",
      },
      {
        code: "CA",
        name: "Carazo",
      },
      {
        code: "CI",
        name: "Chinandega",
      },
      {
        code: "CO",
        name: "Chontales",
      },
      {
        code: "ES",
        name: "Estelí",
      },
      {
        code: "GR",
        name: "Granada",
      },
      {
        code: "JI",
        name: "Jinotega",
      },
      {
        code: "LE",
        name: "León",
      },
      {
        code: "MD",
        name: "Madriz",
      },
      {
        code: "MN",
        name: "Managua",
      },
      {
        code: "MS",
        name: "Masaya",
      },
      {
        code: "MT",
        name: "Matagalpa",
      },
      {
        code: "NS",
        name: "Nueva Segovia",
      },
      {
        code: "RI",
        name: "Rivas",
      },
      {
        code: "SJ",
        name: "Río San Juan",
      },
    ],
  },
  {
    code2: "NE",
    code3: "NER",
    name: "Niger",
    capital: "Niamey",
    region: "Africa",
    subregion: "Western Africa",
    states: [
      {
        code: "1",
        name: "Agadez",
      },
      {
        code: "2",
        name: "Diffa",
      },
      {
        code: "3",
        name: "Dosso",
      },
      {
        code: "4",
        name: "Maradi",
      },
      {
        code: "8",
        name: "Niamey",
      },
      {
        code: "5",
        name: "Tahoua",
      },
      {
        code: "6",
        name: "Tillabéri",
      },
      {
        code: "7",
        name: "Zinder",
      },
    ],
  },
  {
    code2: "NG",
    code3: "NGA",
    name: "Nigeria",
    capital: "Abuja",
    region: "Africa",
    subregion: "Western Africa",
    states: [
      {
        code: "AB",
        name: "Abia",
      },
      {
        code: "FC",
        name: "Abuja Federal Capital Territory",
      },
      {
        code: "AD",
        name: "Adamawa",
      },
      {
        code: "AK",
        name: "Akwa Ibom",
      },
      {
        code: "AN",
        name: "Anambra",
      },
      {
        code: "BA",
        name: "Bauchi",
      },
      {
        code: "BY",
        name: "Bayelsa",
      },
      {
        code: "BE",
        name: "Benue",
      },
      {
        code: "BO",
        name: "Borno",
      },
      {
        code: "CR",
        name: "Cross River",
      },
      {
        code: "DE",
        name: "Delta",
      },
      {
        code: "EB",
        name: "Ebonyi",
      },
      {
        code: "ED",
        name: "Edo",
      },
      {
        code: "EK",
        name: "Ekiti",
      },
      {
        code: "EN",
        name: "Enugu",
      },
      {
        code: "GO",
        name: "Gombe",
      },
      {
        code: "IM",
        name: "Imo",
      },
      {
        code: "JI",
        name: "Jigawa",
      },
      {
        code: "KD",
        name: "Kaduna",
      },
      {
        code: "KN",
        name: "Kano",
      },
      {
        code: "KT",
        name: "Katsina",
      },
      {
        code: "KE",
        name: "Kebbi",
      },
      {
        code: "KO",
        name: "Kogi",
      },
      {
        code: "KW",
        name: "Kwara",
      },
      {
        code: "LA",
        name: "Lagos",
      },
      {
        code: "NA",
        name: "Nassarawa",
      },
      {
        code: "NI",
        name: "Niger",
      },
      {
        code: "OG",
        name: "Ogun",
      },
      {
        code: "ON",
        name: "Ondo",
      },
      {
        code: "OS",
        name: "Osun",
      },
      {
        code: "OY",
        name: "Oyo",
      },
      {
        code: "PL",
        name: "Plateau",
      },
      {
        code: "RI",
        name: "Rivers",
      },
      {
        code: "SO",
        name: "Sokoto",
      },
      {
        code: "TA",
        name: "Taraba",
      },
      {
        code: "YO",
        name: "Yobe",
      },
      {
        code: "ZA",
        name: "Zamfara",
      },
    ],
  },
  {
    code2: "NU",
    code3: "NIU",
    name: "Niue",
    capital: "Alofi",
    region: "Oceania",
    subregion: "Polynesia",
    states: [],
  },
  {
    code2: "NF",
    code3: "NFK",
    name: "Norfolk Island",
    capital: "Kingston",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    states: [],
  },
  {
    code2: "KP",
    code3: "PRK",
    name: "North Korea",
    capital: "Pyongyang",
    region: "Asia",
    subregion: "Eastern Asia",
    states: [
      {
        code: "04",
        name: "Chagang",
      },
      {
        code: "07",
        name: "Kangwon",
      },
      {
        code: "09",
        name: "North Hamgyong",
      },
      {
        code: "06",
        name: "North Hwanghae",
      },
      {
        code: "03",
        name: "North Pyongan",
      },
      {
        code: "01",
        name: "Pyongyang",
      },
      {
        code: "13",
        name: "Rason",
      },
      {
        code: "10",
        name: "Ryanggang",
      },
      {
        code: "08",
        name: "South Hamgyong",
      },
      {
        code: "05",
        name: "South Hwanghae",
      },
      {
        code: "02",
        name: "South Pyongan",
      },
    ],
  },
  {
    code2: "MP",
    code3: "MNP",
    name: "Northern Mariana Islands",
    capital: "Saipan",
    region: "Oceania",
    subregion: "Micronesia",
    states: [],
  },
  {
    code2: "NO",
    code3: "NOR",
    name: "Norway",
    capital: "Oslo",
    region: "Europe",
    subregion: "Northern Europe",
    states: [
      {
        code: "02",
        name: "Akershus",
      },
      {
        code: "09",
        name: "Aust-Agder",
      },
      {
        code: "06",
        name: "Buskerud",
      },
      {
        code: "20",
        name: "Finnmark",
      },
      {
        code: "04",
        name: "Hedmark",
      },
      {
        code: "12",
        name: "Hordaland",
      },
      {
        code: "22",
        name: "Jan Mayen",
      },
      {
        code: "15",
        name: "Møre og Romsdal",
      },
      {
        code: "17",
        name: "Nord-Trøndelag",
      },
      {
        code: "18",
        name: "Nordland",
      },
      {
        code: "05",
        name: "Oppland",
      },
      {
        code: "03",
        name: "Oslo",
      },
      {
        code: "11",
        name: "Rogaland",
      },
      {
        code: "14",
        name: "Sogn og Fjordane",
      },
      {
        code: "21",
        name: "Svalbard",
      },
      {
        code: "16",
        name: "Sør-Trøndelag",
      },
      {
        code: "08",
        name: "Telemark",
      },
      {
        code: "19",
        name: "Troms",
      },
      {
        code: "10",
        name: "Vest-Agder",
      },
      {
        code: "07",
        name: "Vestfold",
      },
      {
        code: "01",
        name: "Østfold",
      },
    ],
  },
  {
    code2: "OM",
    code3: "OMN",
    name: "Oman",
    capital: "Muscat",
    region: "Asia",
    subregion: "Western Asia",
    states: [
      {
        code: "DA",
        name: "Ad Dākhilīyah",
      },
      {
        code: "BU",
        name: "Al Buraymī",
      },
      {
        code: "BA",
        name: "Al Bāţinah",
      },
      {
        code: "WU",
        name: "Al Wusţá",
      },
      {
        code: "SH",
        name: "Ash Sharqīyah",
      },
      {
        code: "ZA",
        name: "Az̧ Z̧āhirah",
      },
      {
        code: "MA",
        name: "Masqaţ",
      },
      {
        code: "MU",
        name: "Musandam",
      },
      {
        code: "ZU",
        name: "Z̧ufār",
      },
    ],
  },
  {
    code2: "PK",
    code3: "PAK",
    name: "Pakistan",
    capital: "Islamabad",
    region: "Asia",
    subregion: "Southern Asia",
    states: [
      {
        code: "JK",
        name: "Azad Kashmir",
      },
      {
        code: "BA",
        name: "Balochistan",
      },
      {
        code: "TA",
        name: "Federally Administered Tribal Areas",
      },
      {
        code: "GB",
        name: "Gilgit-Baltistan",
      },
      {
        code: "IS",
        name: "Islamabad",
      },
      {
        code: "KP",
        name: "Khyber Pakhtunkhwa",
      },
      {
        code: "PB",
        name: "Punjab",
      },
      {
        code: "SD",
        name: "Sindh",
      },
    ],
  },
  {
    code2: "PW",
    code3: "PLW",
    name: "Palau",
    capital: "Ngerulmud",
    region: "Oceania",
    subregion: "Micronesia",
    states: [
      {
        code: "002",
        name: "Aimeliik",
      },
      {
        code: "004",
        name: "Airai",
      },
      {
        code: "010",
        name: "Angaur",
      },
      {
        code: "050",
        name: "Hatobohei",
      },
      {
        code: "100",
        name: "Kayangel",
      },
      {
        code: "150",
        name: "Koror",
      },
      {
        code: "212",
        name: "Melekeok",
      },
      {
        code: "214",
        name: "Ngaraard",
      },
      {
        code: "218",
        name: "Ngarchelong",
      },
      {
        code: "222",
        name: "Ngardmau",
      },
      {
        code: "224",
        name: "Ngatpang",
      },
      {
        code: "226",
        name: "Ngchesar",
      },
      {
        code: "227",
        name: "Ngeremlengui",
      },
      {
        code: "228",
        name: "Ngiwal",
      },
      {
        code: "350",
        name: "Peleliu",
      },
      {
        code: "370",
        name: "Sonsorol",
      },
    ],
  },
  {
    code2: "PS",
    code3: "PSE",
    name: "Palestine",
    capital: "Ramallah",
    region: "Asia",
    subregion: "Western Asia",
    states: [
      {
        code: "BTH",
        name: "Bethlehem",
      },
      {
        code: "DEB",
        name: "Deir El Balah",
      },
      {
        code: "GZA",
        name: "Gaza",
      },
      {
        code: "HBN",
        name: "Hebron",
      },
      {
        code: "JEN",
        name: "Jenin",
      },
      {
        code: "JRH",
        name: "Jericho – Al Aghwar",
      },
      {
        code: "JEM",
        name: "Jerusalem",
      },
      {
        code: "KYS",
        name: "Khan Yunis",
      },
      {
        code: "NBS",
        name: "Nablus",
      },
      {
        code: "NGZ",
        name: "North Gaza",
      },
      {
        code: "QQA",
        name: "Qalqilya",
      },
      {
        code: "RFH",
        name: "Rafah",
      },
      {
        code: "RBH",
        name: "Ramallah",
      },
      {
        code: "SLT",
        name: "Salfit",
      },
      {
        code: "TBS",
        name: "Tubas",
      },
      {
        code: "TKM",
        name: "Tulkarm",
      },
    ],
  },
  {
    code2: "PA",
    code3: "PAN",
    name: "Panama",
    capital: "Panama City",
    region: "Americas",
    subregion: "Central America",
    states: [
      {
        code: "1",
        name: "Bocas del Toro",
      },
      {
        code: "4",
        name: "Chiriquí",
      },
      {
        code: "2",
        name: "Coclé",
      },
      {
        code: "3",
        name: "Colón",
      },
      {
        code: "5",
        name: "Darién",
      },
      {
        code: "EM",
        name: "Emberá",
      },
      {
        code: "6",
        name: "Herrera",
      },
      {
        code: "KY",
        name: "Kuna Yala",
      },
      {
        code: "7",
        name: "Los Santos",
      },
      {
        code: "NB",
        name: "Ngöbe-Buglé",
      },
      {
        code: "8",
        name: "Panamá",
      },
      {
        code: "10",
        name: "Panamá Oeste",
      },
      {
        code: "9",
        name: "Veraguas",
      },
    ],
  },
  {
    code2: "PG",
    code3: "PNG",
    name: "Papua New Guinea",
    capital: "Port Moresby",
    region: "Oceania",
    subregion: "Melanesia",
    states: [
      {
        code: "NSB",
        name: "Bougainville",
      },
      {
        code: "CPM",
        name: "Central",
      },
      {
        code: "CPK",
        name: "Chimbu",
      },
      {
        code: "EBR",
        name: "East New Britain",
      },
      {
        code: "ESW",
        name: "East Sepik",
      },
      {
        code: "EHG",
        name: "Eastern Highlands",
      },
      {
        code: "EPW",
        name: "Enga",
      },
      {
        code: "GPK",
        name: "Gulf",
      },
      {
        code: "MPM",
        name: "Madang",
      },
      {
        code: "MRL",
        name: "Manus",
      },
      {
        code: "MBA",
        name: "Milne Bay",
      },
      {
        code: "MPL",
        name: "Morobe",
      },
      {
        code: "NCD",
        name: "National Capital District",
      },
      {
        code: "NIK",
        name: "New Ireland",
      },
      {
        code: "NPP",
        name: "Northern",
      },
      {
        code: "SAN",
        name: "Sandaun",
      },
      {
        code: "SHM",
        name: "Southern Highlands",
      },
      {
        code: "WBK",
        name: "West New Britain",
      },
      {
        code: "WPD",
        name: "Western",
      },
      {
        code: "WHM",
        name: "Western Highlands",
      },
    ],
  },
  {
    code2: "PY",
    code3: "PRY",
    name: "Paraguay",
    capital: "Asunción",
    region: "Americas",
    subregion: "South America",
    states: [
      {
        code: "16",
        name: "Alto Paraguay",
      },
      {
        code: "10",
        name: "Alto Paraná",
      },
      {
        code: "13",
        name: "Amambay",
      },
      {
        code: "ASU",
        name: "Asunción",
      },
      {
        code: "19",
        name: "Boquerón",
      },
      {
        code: "5",
        name: "Caaguazú",
      },
      {
        code: "6",
        name: "Caazapá",
      },
      {
        code: "14",
        name: "Canindeyú",
      },
      {
        code: "11",
        name: "Central",
      },
      {
        code: "1",
        name: "Concepción",
      },
      {
        code: "3",
        name: "Cordillera",
      },
      {
        code: "4",
        name: "Guairá",
      },
      {
        code: "7",
        name: "Itapúa",
      },
      {
        code: "8",
        name: "Misiones",
      },
      {
        code: "9",
        name: "Paraguarí",
      },
      {
        code: "15",
        name: "Presidente Hayes",
      },
      {
        code: "2",
        name: "San Pedro",
      },
      {
        code: "12",
        name: "Ñeembucú",
      },
    ],
  },
  {
    code2: "PE",
    code3: "PER",
    name: "Peru",
    capital: "Lima",
    region: "Americas",
    subregion: "South America",
    states: [
      {
        code: "AMA",
        name: "Amazonas",
      },
      {
        code: "ANC",
        name: "Ancash",
      },
      {
        code: "APU",
        name: "Apurímac",
      },
      {
        code: "ARE",
        name: "Arequipa",
      },
      {
        code: "AYA",
        name: "Ayacucho",
      },
      {
        code: "CAJ",
        name: "Cajamarca",
      },
      {
        code: "CUS",
        name: "Cusco",
      },
      {
        code: "CAL",
        name: "El Callao",
      },
      {
        code: "HUV",
        name: "Huancavelica",
      },
      {
        code: "HUC",
        name: "Huánuco",
      },
      {
        code: "ICA",
        name: "Ica",
      },
      {
        code: "JUN",
        name: "Junín",
      },
      {
        code: "LAL",
        name: "La Libertad",
      },
      {
        code: "LAM",
        name: "Lambayeque",
      },
      {
        code: "LIM",
        name: "Lima",
      },
      {
        code: "LOR",
        name: "Loreto",
      },
      {
        code: "MDD",
        name: "Madre de Dios",
      },
      {
        code: "MOQ",
        name: "Moquegua",
      },
      {
        code: "LMA",
        name: "Municipalidad Metropolitana de Lima",
      },
      {
        code: "PAS",
        name: "Pasco",
      },
      {
        code: "PIU",
        name: "Piura",
      },
      {
        code: "PUN",
        name: "Puno",
      },
      {
        code: "SAM",
        name: "San Martín",
      },
      {
        code: "TAC",
        name: "Tacna",
      },
      {
        code: "TUM",
        name: "Tumbes",
      },
      {
        code: "UCA",
        name: "Ucayali",
      },
    ],
  },
  {
    code2: "PH",
    code3: "PHL",
    name: "Philippines",
    capital: "Manila",
    region: "Asia",
    subregion: "South-Eastern Asia",
    states: [
      {
        code: "14",
        name: "Autonomous Region in Muslim Mindanao",
      },
      {
        code: "05",
        name: "Bicol",
      },
      {
        code: "02",
        name: "Cagayan Valley",
      },
      {
        code: "40",
        name: "Calabarzon",
      },
      {
        code: "13",
        name: "Caraga",
      },
      {
        code: "03",
        name: "Central Luzon",
      },
      {
        code: "07",
        name: "Central Visayas",
      },
      {
        code: "15",
        name: "Cordillera Administrative Region",
      },
      {
        code: "11",
        name: "Davao",
      },
      {
        code: "08",
        name: "Eastern Visayas",
      },
      {
        code: "01",
        name: "Ilocos",
      },
      {
        code: "41",
        name: "Mimaropa",
      },
      {
        code: "00",
        name: "National Capital Region",
      },
      {
        code: "10",
        name: "Northern Mindanao",
      },
      {
        code: "12",
        name: "Soccsksargen",
      },
      {
        code: "06",
        name: "Western Visayas",
      },
      {
        code: "09",
        name: "Zamboanga Peninsula",
      },
    ],
  },
  {
    code2: "PN",
    code3: "PCN",
    name: "Pitcairn Islands",
    capital: "Adamstown",
    region: "Oceania",
    subregion: "Polynesia",
    states: [],
  },
  {
    code2: "PL",
    code3: "POL",
    name: "Poland",
    capital: "Warsaw",
    region: "Europe",
    subregion: "Eastern Europe",
    states: [
      {
        code: "DS",
        name: "Dolnośląskie",
      },
      {
        code: "KP",
        name: "Kujawsko-pomorskie",
      },
      {
        code: "LU",
        name: "Lubelskie",
      },
      {
        code: "LB",
        name: "Lubuskie",
      },
      {
        code: "MZ",
        name: "Mazowieckie",
      },
      {
        code: "MA",
        name: "Małopolskie",
      },
      {
        code: "OP",
        name: "Opolskie",
      },
      {
        code: "PK",
        name: "Podkarpackie",
      },
      {
        code: "PD",
        name: "Podlaskie",
      },
      {
        code: "PM",
        name: "Pomorskie",
      },
      {
        code: "WN",
        name: "Warmińsko-mazurskie",
      },
      {
        code: "WP",
        name: "Wielkopolskie",
      },
      {
        code: "ZP",
        name: "Zachodniopomorskie",
      },
      {
        code: "LD",
        name: "Łódzkie",
      },
      {
        code: "SL",
        name: "Śląskie",
      },
      {
        code: "SK",
        name: "Świętokrzyskie",
      },
    ],
  },
  {
    code2: "PT",
    code3: "PRT",
    name: "Portugal",
    capital: "Lisbon",
    region: "Europe",
    subregion: "Southern Europe",
    states: [
      {
        code: "01",
        name: "Aveiro",
      },
      {
        code: "02",
        name: "Beja",
      },
      {
        code: "03",
        name: "Braga",
      },
      {
        code: "04",
        name: "Bragança",
      },
      {
        code: "05",
        name: "Castelo Branco",
      },
      {
        code: "06",
        name: "Coimbra",
      },
      {
        code: "08",
        name: "Faro",
      },
      {
        code: "09",
        name: "Guarda",
      },
      {
        code: "10",
        name: "Leiria",
      },
      {
        code: "11",
        name: "Lisboa",
      },
      {
        code: "12",
        name: "Portalegre",
      },
      {
        code: "13",
        name: "Porto",
      },
      {
        code: "30",
        name: "Região Autónoma da Madeira",
      },
      {
        code: "20",
        name: "Região Autónoma dos Açores",
      },
      {
        code: "14",
        name: "Santarém",
      },
      {
        code: "15",
        name: "Setúbal",
      },
      {
        code: "16",
        name: "Viana do Castelo",
      },
      {
        code: "17",
        name: "Vila Real",
      },
      {
        code: "18",
        name: "Viseu",
      },
      {
        code: "07",
        name: "Évora",
      },
    ],
  },
  {
    code2: "PR",
    code3: "PRI",
    name: "Puerto Rico",
    capital: "San Juan",
    region: "Americas",
    subregion: "Caribbean",
    states: [],
  },
  {
    code2: "QA",
    code3: "QAT",
    name: "Qatar",
    capital: "Doha",
    region: "Asia",
    subregion: "Western Asia",
    states: [
      {
        code: "DA",
        name: "Ad Dawḩah",
      },
      {
        code: "KH",
        name: "Al Khawr wa adh Dhakhīrah",
      },
      {
        code: "WA",
        name: "Al Wakrah",
      },
      {
        code: "RA",
        name: "Ar Rayyān",
      },
      {
        code: "MS",
        name: "Ash Shamāl",
      },
      {
        code: "ZA",
        name: "Az̧ Za̧`āyin",
      },
      {
        code: "US",
        name: "Umm Şalāl",
      },
    ],
  },
  {
    code2: "XK",
    code3: "KOS",
    name: "Republic of Kosovo",
    capital: "Pristina",
    region: "Europe",
    subregion: "Eastern Europe",
    states: [],
  },
  {
    code2: "RE",
    code3: "REU",
    name: "Réunion",
    capital: "Saint-Denis",
    region: "Africa",
    subregion: "Eastern Africa",
    states: [],
  },
  {
    code2: "RO",
    code3: "ROU",
    name: "Romania",
    capital: "Bucharest",
    region: "Europe",
    subregion: "Eastern Europe",
    states: [
      {
        code: "AB",
        name: "Alba",
      },
      {
        code: "AR",
        name: "Arad",
      },
      {
        code: "AG",
        name: "Argeș",
      },
      {
        code: "BC",
        name: "Bacău",
      },
      {
        code: "BH",
        name: "Bihor",
      },
      {
        code: "BN",
        name: "Bistrița-Năsăud",
      },
      {
        code: "BT",
        name: "Botoșani",
      },
      {
        code: "BV",
        name: "Brașov",
      },
      {
        code: "BR",
        name: "Brăila",
      },
      {
        code: "B",
        name: "București",
      },
      {
        code: "BZ",
        name: "Buzău",
      },
      {
        code: "CS",
        name: "Caraș-Severin",
      },
      {
        code: "CJ",
        name: "Cluj",
      },
      {
        code: "CT",
        name: "Constanța",
      },
      {
        code: "CV",
        name: "Covasna",
      },
      {
        code: "CL",
        name: "Călărași",
      },
      {
        code: "DJ",
        name: "Dolj",
      },
      {
        code: "DB",
        name: "Dâmbovița",
      },
      {
        code: "GL",
        name: "Galați",
      },
      {
        code: "GR",
        name: "Giurgiu",
      },
      {
        code: "GJ",
        name: "Gorj",
      },
      {
        code: "HR",
        name: "Harghita",
      },
      {
        code: "HD",
        name: "Hunedoara",
      },
      {
        code: "IL",
        name: "Ialomița",
      },
      {
        code: "IS",
        name: "Iași",
      },
      {
        code: "IF",
        name: "Ilfov",
      },
      {
        code: "MM",
        name: "Maramureș",
      },
      {
        code: "MH",
        name: "Mehedinți",
      },
      {
        code: "MS",
        name: "Mureș",
      },
      {
        code: "NT",
        name: "Neamț",
      },
      {
        code: "OT",
        name: "Olt",
      },
      {
        code: "PH",
        name: "Prahova",
      },
      {
        code: "SM",
        name: "Satu Mare",
      },
      {
        code: "SB",
        name: "Sibiu",
      },
      {
        code: "SV",
        name: "Suceava",
      },
      {
        code: "SJ",
        name: "Sălaj",
      },
      {
        code: "TR",
        name: "Teleorman",
      },
      {
        code: "TM",
        name: "Timiș",
      },
      {
        code: "TL",
        name: "Tulcea",
      },
      {
        code: "VS",
        name: "Vaslui",
      },
      {
        code: "VN",
        name: "Vrancea",
      },
      {
        code: "VL",
        name: "Vâlcea",
      },
    ],
  },
  {
    code2: "RU",
    code3: "RUS",
    name: "Russia",
    capital: "Moscow",
    region: "Europe",
    subregion: "Eastern Europe",
    states: [
      {
        code: "AMU",
        name: "Amurskaya oblast'",
      },
      {
        code: "ARK",
        name: "Arkhangel'skaya oblast'",
      },
      {
        code: "AST",
        name: "Astrakhanskaya oblast'",
      },
      {
        code: "BEL",
        name: "Belgorodskaya oblast'",
      },
      {
        code: "BRY",
        name: "Bryanskaya oblast'",
      },
      {
        code: "CHE",
        name: "Chelyabinskaya oblast'",
      },
      {
        code: "IRK",
        name: "Irkutskaya oblast'",
      },
      {
        code: "IVA",
        name: "Ivanovskaya oblast'",
      },
      {
        code: "KGD",
        name: "Kaliningradskaya oblast'",
      },
      {
        code: "KLU",
        name: "Kaluzhskaya oblast'",
      },
      {
        code: "KEM",
        name: "Kemerovskaya oblast'",
      },
      {
        code: "KIR",
        name: "Kirovskaya oblast'",
      },
      {
        code: "KOS",
        name: "Kostromskaya oblast'",
      },
      {
        code: "KGN",
        name: "Kurganskaya oblast'",
      },
      {
        code: "KRS",
        name: "Kurskaya oblast'",
      },
      {
        code: "LEN",
        name: "Leningradskaya oblast'",
      },
      {
        code: "LIP",
        name: "Lipetskaya oblast'",
      },
      {
        code: "MAG",
        name: "Magadanskaya oblast'",
      },
      {
        code: "MOS",
        name: "Moskovskaya oblast'",
      },
      {
        code: "MUR",
        name: "Murmanskaya oblast'",
      },
      {
        code: "NIZ",
        name: "Nizhegorodskaya oblast'",
      },
      {
        code: "NGR",
        name: "Novgorodskaya oblast'",
      },
      {
        code: "NVS",
        name: "Novosibirskaya oblast'",
      },
      {
        code: "OMS",
        name: "Omskaya oblast'",
      },
      {
        code: "ORE",
        name: "Orenburgskaya oblast'",
      },
      {
        code: "ORL",
        name: "Orlovskaya oblast'",
      },
      {
        code: "PNZ",
        name: "Penzenskaya oblast'",
      },
      {
        code: "PSK",
        name: "Pskovskaya oblast'",
      },
      {
        code: "ROS",
        name: "Rostovskaya oblast'",
      },
      {
        code: "RYA",
        name: "Ryazanskaya oblast'",
      },
      {
        code: "SAK",
        name: "Sakhalinskaya oblast'",
      },
      {
        code: "SAM",
        name: "Samarskaya oblast'",
      },
      {
        code: "SAR",
        name: "Saratovskaya oblast'",
      },
      {
        code: "SMO",
        name: "Smolenskaya oblast'",
      },
      {
        code: "SVE",
        name: "Sverdlovskaya oblast'",
      },
      {
        code: "TAM",
        name: "Tambovskaya oblast'",
      },
      {
        code: "TOM",
        name: "Tomskaya oblast'",
      },
      {
        code: "TUL",
        name: "Tul'skaya oblast'",
      },
      {
        code: "TVE",
        name: "Tverskaya oblast'",
      },
      {
        code: "TYU",
        name: "Tyumenskaya oblast'",
      },
      {
        code: "ULY",
        name: "Ul'yanovskaya oblast'",
      },
      {
        code: "VLA",
        name: "Vladimirskaya oblast'",
      },
      {
        code: "VGG",
        name: "Volgogradskaya oblast'",
      },
      {
        code: "VLG",
        name: "Vologodskaya oblast'",
      },
      {
        code: "VOR",
        name: "Voronezhskaya oblast'",
      },
      {
        code: "YAR",
        name: "Yaroslavskaya oblast'",
      },
      {
        code: "ALT",
        name: "Altayskiy kray",
      },
      {
        code: "KAM",
        name: "Kamchatskiy kray",
      },
      {
        code: "KHA",
        name: "Khabarovskiy kray",
      },
      {
        code: "KDA",
        name: "Krasnodarskiy kray",
      },
      {
        code: "KYA",
        name: "Krasnoyarskiy kray",
      },
      {
        code: "PER",
        name: "Permskiy kray",
      },
      {
        code: "PRI",
        name: "Primorskiy kray",
      },
      {
        code: "STA",
        name: "Stavropol'skiy kray",
      },
      {
        code: "ZAB",
        name: "Zabaykal'skiy kray",
      },
      {
        code: "MOW",
        name: "Moskva",
      },
      {
        code: "SPE",
        name: "Sankt-Peterburg",
      },
      {
        code: "CHU",
        name: "Chukotskiy avtonomnyy okrug",
      },
      {
        code: "KHM",
        name: "Khanty-Mansiyskiy avtonomnyy okrug-Yugra",
      },
      {
        code: "NEN",
        name: "Nenetskiy avtonomnyy okrug",
      },
      {
        code: "YAN",
        name: "Yamalo-Nenetskiy avtonomnyy okrug",
      },
      {
        code: "YEV",
        name: "Yevreyskaya avtonomnaya oblast'",
      },
      {
        code: "AD",
        name: "Adygeya, Respublika",
      },
      {
        code: "AL",
        name: "Altay, Respublika",
      },
      {
        code: "BA",
        name: "Bashkortostan, Respublika",
      },
      {
        code: "BU",
        name: "Buryatiya, Respublika",
      },
      {
        code: "CE",
        name: "Chechenskaya Respublika",
      },
      {
        code: "CU",
        name: "Chuvashskaya Respublika",
      },
      {
        code: "DA",
        name: "Dagestan, Respublika",
      },
      {
        code: "IN",
        name: "Ingushetiya, Respublika",
      },
      {
        code: "KB",
        name: "Kabardino-Balkarskaya Respublika",
      },
      {
        code: "KL",
        name: "Kalmykiya, Respublika",
      },
      {
        code: "KC",
        name: "Karachayevo-Cherkesskaya Respublika",
      },
      {
        code: "KR",
        name: "Kareliya, Respublika",
      },
      {
        code: "KK",
        name: "Khakasiya, Respublika",
      },
      {
        code: "KO",
        name: "Komi, Respublika",
      },
      {
        code: "ME",
        name: "Mariy El, Respublika",
      },
      {
        code: "MO",
        name: "Mordoviya, Respublika",
      },
      {
        code: "SA",
        name: "Sakha, Respublika",
      },
      {
        code: "SE",
        name: "Severnaya Osetiya-Alaniya, Respublika",
      },
      {
        code: "TA",
        name: "Tatarstan, Respublika",
      },
      {
        code: "TY",
        name: "Tyva, Respublika",
      },
      {
        code: "UD",
        name: "Udmurtskaya Respublika",
      },
    ],
  },
  {
    code2: "RW",
    code3: "RWA",
    name: "Rwanda",
    capital: "Kigali",
    region: "Africa",
    subregion: "Eastern Africa",
    states: [
      {
        code: "02",
        name: "Est",
      },
      {
        code: "03",
        name: "Nord",
      },
      {
        code: "04",
        name: "Ouest",
      },
      {
        code: "05",
        name: "Sud",
      },
      {
        code: "01",
        name: "Ville de Kigali",
      },
    ],
  },
  {
    code2: "BL",
    code3: "BLM",
    name: "Saint Barthélemy",
    capital: "Gustavia",
    region: "Americas",
    subregion: "Caribbean",
    states: [],
  },
  {
    code2: "SH",
    code3: "SHN",
    name: "Saint Helena",
    capital: "Jamestown",
    region: "Africa",
    subregion: "Western Africa",
    states: [
      {
        code: "AC",
        name: "Ascension",
      },
      {
        code: "HL",
        name: "Saint Helena",
      },
      {
        code: "TA",
        name: "Tristan da Cunha",
      },
    ],
  },
  {
    code2: "KN",
    code3: "KNA",
    name: "Saint Kitts and Nevis",
    capital: "Basseterre",
    region: "Americas",
    subregion: "Caribbean",
    states: [
      {
        code: "N",
        name: "Nevis",
      },
      {
        code: "K",
        name: "Saint Kitts",
      },
    ],
  },
  {
    code2: "LC",
    code3: "LCA",
    name: "Saint Lucia",
    capital: "Castries",
    region: "Americas",
    subregion: "Caribbean",
    states: [
      {
        code: "01",
        name: "Anse la Raye",
      },
      {
        code: "02",
        name: "Castries",
      },
      {
        code: "03",
        name: "Choiseul",
      },
      {
        code: "04",
        name: "Dauphin",
      },
      {
        code: "05",
        name: "Dennery",
      },
      {
        code: "06",
        name: "Gros Islet",
      },
      {
        code: "07",
        name: "Laborie",
      },
      {
        code: "08",
        name: "Micoud",
      },
      {
        code: "09",
        name: "Praslin",
      },
      {
        code: "10",
        name: "Soufrière",
      },
      {
        code: "11",
        name: "Vieux Fort",
      },
    ],
  },
  {
    code2: "MF",
    code3: "MAF",
    name: "Saint Martin",
    capital: "Marigot",
    region: "Americas",
    subregion: "Caribbean",
    states: [],
  },
  {
    code2: "PM",
    code3: "SPM",
    name: "Saint Pierre and Miquelon",
    capital: "Saint-Pierre",
    region: "Americas",
    subregion: "Northern America",
    states: [],
  },
  {
    code2: "VC",
    code3: "VCT",
    name: "Saint Vincent and the Grenadines",
    capital: "Kingstown",
    region: "Americas",
    subregion: "Caribbean",
    states: [
      {
        code: "01",
        name: "Charlotte",
      },
      {
        code: "06",
        name: "Grenadines",
      },
      {
        code: "02",
        name: "Saint Andrew",
      },
      {
        code: "03",
        name: "Saint David",
      },
      {
        code: "04",
        name: "Saint George",
      },
      {
        code: "05",
        name: "Saint Patrick",
      },
    ],
  },
  {
    code2: "WS",
    code3: "WSM",
    name: "Samoa",
    capital: "Apia",
    region: "Oceania",
    subregion: "Polynesia",
    states: [
      {
        code: "AA",
        name: "A'ana",
      },
      {
        code: "AL",
        name: "Aiga-i-le-Tai",
      },
      {
        code: "AT",
        name: "Atua",
      },
      {
        code: "FA",
        name: "Fa'asaleleaga",
      },
      {
        code: "GE",
        name: "Gaga'emauga",
      },
      {
        code: "GI",
        name: "Gagaifomauga",
      },
      {
        code: "PA",
        name: "Palauli",
      },
      {
        code: "SA",
        name: "Satupa'itea",
      },
      {
        code: "TU",
        name: "Tuamasaga",
      },
      {
        code: "VF",
        name: "Va'a-o-Fonoti",
      },
      {
        code: "VS",
        name: "Vaisigano",
      },
    ],
  },
  {
    code2: "SM",
    code3: "SMR",
    name: "San Marino",
    capital: "City of San Marino",
    region: "Europe",
    subregion: "Southern Europe",
    states: [
      {
        code: "01",
        name: "Acquaviva",
      },
      {
        code: "06",
        name: "Borgo Maggiore",
      },
      {
        code: "02",
        name: "Chiesanuova",
      },
      {
        code: "03",
        name: "Domagnano",
      },
      {
        code: "04",
        name: "Faetano",
      },
      {
        code: "05",
        name: "Fiorentino",
      },
      {
        code: "08",
        name: "Montegiardino",
      },
      {
        code: "07",
        name: "San Marino",
      },
      {
        code: "09",
        name: "Serravalle",
      },
    ],
  },
  {
    code2: "ST",
    code3: "STP",
    name: "São Tomé and Príncipe",
    capital: "São Tomé",
    region: "Africa",
    subregion: "Middle Africa",
    states: [
      {
        code: "P",
        name: "Príncipe",
      },
      {
        code: "S",
        name: "São Tomé",
      },
    ],
  },
  {
    code2: "SA",
    code3: "SAU",
    name: "Saudi Arabia",
    capital: "Riyadh",
    region: "Asia",
    subregion: "Western Asia",
    states: [
      {
        code: "11",
        name: "Al Bāḩah",
      },
      {
        code: "12",
        name: "Al Jawf",
      },
      {
        code: "03",
        name: "Al Madīnah",
      },
      {
        code: "05",
        name: "Al Qaşīm",
      },
      {
        code: "08",
        name: "Al Ḩudūd ash Shamālīyah",
      },
      {
        code: "01",
        name: "Ar Riyāḑ",
      },
      {
        code: "04",
        name: "Ash Sharqīyah",
      },
      {
        code: "09",
        name: "Jīzān",
      },
      {
        code: "02",
        name: "Makkah",
      },
      {
        code: "10",
        name: "Najrān",
      },
      {
        code: "07",
        name: "Tabūk",
      },
      {
        code: "14",
        name: "ٰĀsīr",
      },
      {
        code: "06",
        name: "Ḩā'il",
      },
    ],
  },
  {
    code2: "SN",
    code3: "SEN",
    name: "Senegal",
    capital: "Dakar",
    region: "Africa",
    subregion: "Western Africa",
    states: [
      {
        code: "DK",
        name: "Dakar",
      },
      {
        code: "DB",
        name: "Diourbel",
      },
      {
        code: "FK",
        name: "Fatick",
      },
      {
        code: "KA",
        name: "Kaffrine",
      },
      {
        code: "KL",
        name: "Kaolack",
      },
      {
        code: "KD",
        name: "Kolda",
      },
      {
        code: "KE",
        name: "Kédougou",
      },
      {
        code: "LG",
        name: "Louga",
      },
      {
        code: "MT",
        name: "Matam",
      },
      {
        code: "SL",
        name: "Saint-Louis",
      },
      {
        code: "SE",
        name: "Sédhiou",
      },
      {
        code: "TC",
        name: "Tambacounda",
      },
      {
        code: "TH",
        name: "Thiès",
      },
      {
        code: "ZG",
        name: "Ziguinchor",
      },
    ],
  },
  {
    code2: "RS",
    code3: "SRB",
    name: "Serbia",
    capital: "Belgrade",
    region: "Europe",
    subregion: "Southern Europe",
    states: [
      {
        code: "KM",
        name: "Kosovo-Metohija",
      },
      {
        code: "VO",
        name: "Vojvodina",
      },
    ],
  },
  {
    code2: "SC",
    code3: "SYC",
    name: "Seychelles",
    capital: "Victoria",
    region: "Africa",
    subregion: "Eastern Africa",
    states: [
      {
        code: "02",
        name: "Anse Boileau",
      },
      {
        code: "03",
        name: "Anse Etoile",
      },
      {
        code: "05",
        name: "Anse Royale",
      },
      {
        code: "01",
        name: "Anse aux Pins",
      },
      {
        code: "04",
        name: "Au Cap",
      },
      {
        code: "06",
        name: "Baie Lazare",
      },
      {
        code: "07",
        name: "Baie Sainte Anne",
      },
      {
        code: "08",
        name: "Beau Vallon",
      },
      {
        code: "09",
        name: "Bel Air",
      },
      {
        code: "10",
        name: "Bel Ombre",
      },
      {
        code: "11",
        name: "Cascade",
      },
      {
        code: "16",
        name: "English River",
      },
      {
        code: "12",
        name: "Glacis",
      },
      {
        code: "13",
        name: "Grand Anse Mahe",
      },
      {
        code: "14",
        name: "Grand Anse Praslin",
      },
      {
        code: "15",
        name: "La Digue",
      },
      {
        code: "24",
        name: "Les Mamelles",
      },
      {
        code: "17",
        name: "Mont Buxton",
      },
      {
        code: "18",
        name: "Mont Fleuri",
      },
      {
        code: "19",
        name: "Plaisance",
      },
      {
        code: "20",
        name: "Pointe Larue",
      },
      {
        code: "21",
        name: "Port Glaud",
      },
      {
        code: "25",
        name: "Roche Caiman",
      },
      {
        code: "22",
        name: "Saint Louis",
      },
      {
        code: "23",
        name: "Takamaka",
      },
    ],
  },
  {
    code2: "SL",
    code3: "SLE",
    name: "Sierra Leone",
    capital: "Freetown",
    region: "Africa",
    subregion: "Western Africa",
    states: [
      {
        code: "E",
        name: "Eastern",
      },
      {
        code: "N",
        name: "Northern",
      },
      {
        code: "S",
        name: "Southern",
      },
      {
        code: "W",
        name: "Western Area",
      },
    ],
  },
  {
    code2: "SG",
    code3: "SGP",
    name: "Singapore",
    capital: "Singapore",
    region: "Asia",
    subregion: "South-Eastern Asia",
    states: [
      {
        code: "01",
        name: "Central Singapore",
      },
      {
        code: "02",
        name: "North East",
      },
      {
        code: "03",
        name: "North West",
      },
      {
        code: "04",
        name: "South East",
      },
      {
        code: "05",
        name: "South West",
      },
    ],
  },
  {
    code2: "SX",
    code3: "SXM",
    name: "Sint Maarten",
    capital: "Philipsburg",
    region: "Americas",
    subregion: "Caribbean",
    states: [],
  },
  {
    code2: "SK",
    code3: "SVK",
    name: "Slovakia",
    capital: "Bratislava",
    region: "Europe",
    subregion: "Eastern Europe",
    states: [
      {
        code: "BC",
        name: "Banskobystrický kraj",
      },
      {
        code: "BL",
        name: "Bratislavský kraj",
      },
      {
        code: "KI",
        name: "Košický kraj",
      },
      {
        code: "NI",
        name: "Nitriansky kraj",
      },
      {
        code: "PV",
        name: "Prešovský kraj",
      },
      {
        code: "TC",
        name: "Trenčiansky kraj",
      },
      {
        code: "TA",
        name: "Trnavský kraj",
      },
      {
        code: "ZI",
        name: "Žilinský kraj",
      },
    ],
  },
  {
    code2: "SI",
    code3: "SVN",
    name: "Slovenia",
    capital: "Ljubljana",
    region: "Europe",
    subregion: "Southern Europe",
    states: [
      {
        code: "001",
        name: "Ajdovščina",
      },
      {
        code: "195",
        name: "Apače",
      },
      {
        code: "002",
        name: "Beltinci",
      },
      {
        code: "148",
        name: "Benedikt",
      },
      {
        code: "149",
        name: "Bistrica ob Sotli",
      },
      {
        code: "003",
        name: "Bled",
      },
      {
        code: "150",
        name: "Bloke",
      },
      {
        code: "004",
        name: "Bohinj",
      },
      {
        code: "005",
        name: "Borovnica",
      },
      {
        code: "006",
        name: "Bovec",
      },
      {
        code: "151",
        name: "Braslovče",
      },
      {
        code: "007",
        name: "Brda",
      },
      {
        code: "008",
        name: "Brezovica",
      },
      {
        code: "009",
        name: "Brežice",
      },
      {
        code: "152",
        name: "Cankova",
      },
      {
        code: "011",
        name: "Celje",
      },
      {
        code: "012",
        name: "Cerklje na Gorenjskem",
      },
      {
        code: "013",
        name: "Cerknica",
      },
      {
        code: "014",
        name: "Cerkno",
      },
      {
        code: "153",
        name: "Cerkvenjak",
      },
      {
        code: "196",
        name: "Cirkulane",
      },
      {
        code: "018",
        name: "Destrnik",
      },
      {
        code: "019",
        name: "Divača",
      },
      {
        code: "154",
        name: "Dobje",
      },
      {
        code: "020",
        name: "Dobrepolje",
      },
      {
        code: "155",
        name: "Dobrna",
      },
      {
        code: "021",
        name: "Dobrova–Polhov Gradec",
      },
      {
        code: "156",
        name: "Dobrovnik",
      },
      {
        code: "022",
        name: "Dol pri Ljubljani",
      },
      {
        code: "157",
        name: "Dolenjske Toplice",
      },
      {
        code: "023",
        name: "Domžale",
      },
      {
        code: "024",
        name: "Dornava",
      },
      {
        code: "025",
        name: "Dravograd",
      },
      {
        code: "026",
        name: "Duplek",
      },
      {
        code: "027",
        name: "Gorenja vas–Poljane",
      },
      {
        code: "028",
        name: "Gorišnica",
      },
      {
        code: "207",
        name: "Gorje",
      },
      {
        code: "029",
        name: "Gornja Radgona",
      },
      {
        code: "030",
        name: "Gornji Grad",
      },
      {
        code: "031",
        name: "Gornji Petrovci",
      },
      {
        code: "158",
        name: "Grad",
      },
      {
        code: "032",
        name: "Grosuplje",
      },
      {
        code: "159",
        name: "Hajdina",
      },
      {
        code: "161",
        name: "Hodoš",
      },
      {
        code: "162",
        name: "Horjul",
      },
      {
        code: "160",
        name: "Hoče–Slivnica",
      },
      {
        code: "034",
        name: "Hrastnik",
      },
      {
        code: "035",
        name: "Hrpelje-Kozina",
      },
      {
        code: "036",
        name: "Idrija",
      },
      {
        code: "037",
        name: "Ig",
      },
      {
        code: "038",
        name: "Ilirska Bistrica",
      },
      {
        code: "039",
        name: "Ivančna Gorica",
      },
      {
        code: "040",
        name: "Izola",
      },
      {
        code: "041",
        name: "Jesenice",
      },
      {
        code: "163",
        name: "Jezersko",
      },
      {
        code: "042",
        name: "Juršinci",
      },
      {
        code: "043",
        name: "Kamnik",
      },
      {
        code: "044",
        name: "Kanal",
      },
      {
        code: "045",
        name: "Kidričevo",
      },
      {
        code: "046",
        name: "Kobarid",
      },
      {
        code: "047",
        name: "Kobilje",
      },
      {
        code: "049",
        name: "Komen",
      },
      {
        code: "164",
        name: "Komenda",
      },
      {
        code: "050",
        name: "Koper",
      },
      {
        code: "197",
        name: "Kosanjevica na Krki",
      },
      {
        code: "165",
        name: "Kostel",
      },
      {
        code: "051",
        name: "Kozje",
      },
      {
        code: "048",
        name: "Kočevje",
      },
      {
        code: "052",
        name: "Kranj",
      },
      {
        code: "053",
        name: "Kranjska Gora",
      },
      {
        code: "166",
        name: "Križevci",
      },
      {
        code: "054",
        name: "Krško",
      },
      {
        code: "055",
        name: "Kungota",
      },
      {
        code: "056",
        name: "Kuzma",
      },
      {
        code: "057",
        name: "Laško",
      },
      {
        code: "058",
        name: "Lenart",
      },
      {
        code: "059",
        name: "Lendava",
      },
      {
        code: "060",
        name: "Litija",
      },
      {
        code: "061",
        name: "Ljubljana",
      },
      {
        code: "062",
        name: "Ljubno",
      },
      {
        code: "063",
        name: "Ljutomer",
      },
      {
        code: "208",
        name: "Log-Dragomer",
      },
      {
        code: "064",
        name: "Logatec",
      },
      {
        code: "167",
        name: "Lovrenc na Pohorju",
      },
      {
        code: "065",
        name: "Loška Dolina",
      },
      {
        code: "066",
        name: "Loški Potok",
      },
      {
        code: "068",
        name: "Lukovica",
      },
      {
        code: "067",
        name: "Luče",
      },
      {
        code: "069",
        name: "Majšperk",
      },
      {
        code: "198",
        name: "Makole",
      },
      {
        code: "070",
        name: "Maribor",
      },
      {
        code: "168",
        name: "Markovci",
      },
      {
        code: "071",
        name: "Medvode",
      },
      {
        code: "072",
        name: "Mengeš",
      },
      {
        code: "073",
        name: "Metlika",
      },
      {
        code: "074",
        name: "Mežica",
      },
      {
        code: "169",
        name: "Miklavž na Dravskem Polju",
      },
      {
        code: "075",
        name: "Miren–Kostanjevica",
      },
      {
        code: "170",
        name: "Mirna Peč",
      },
      {
        code: "076",
        name: "Mislinja",
      },
      {
        code: "199",
        name: "Mokronog–Trebelno",
      },
      {
        code: "078",
        name: "Moravske Toplice",
      },
      {
        code: "077",
        name: "Moravče",
      },
      {
        code: "079",
        name: "Mozirje",
      },
      {
        code: "080",
        name: "Murska Sobota",
      },
      {
        code: "081",
        name: "Muta",
      },
      {
        code: "082",
        name: "Naklo",
      },
      {
        code: "083",
        name: "Nazarje",
      },
      {
        code: "084",
        name: "Nova Gorica",
      },
      {
        code: "085",
        name: "Novo Mesto",
      },
      {
        code: "086",
        name: "Odranci",
      },
      {
        code: "171",
        name: "Oplotnica",
      },
      {
        code: "087",
        name: "Ormož",
      },
      {
        code: "088",
        name: "Osilnica",
      },
      {
        code: "089",
        name: "Pesnica",
      },
      {
        code: "090",
        name: "Piran",
      },
      {
        code: "091",
        name: "Pivka",
      },
      {
        code: "172",
        name: "Podlehnik",
      },
      {
        code: "093",
        name: "Podvelka",
      },
      {
        code: "092",
        name: "Podčetrtek",
      },
      {
        code: "200",
        name: "Poljčane",
      },
      {
        code: "173",
        name: "Polzela",
      },
      {
        code: "094",
        name: "Postojna",
      },
      {
        code: "174",
        name: "Prebold",
      },
      {
        code: "095",
        name: "Preddvor",
      },
      {
        code: "175",
        name: "Prevalje",
      },
      {
        code: "096",
        name: "Ptuj",
      },
      {
        code: "097",
        name: "Puconci",
      },
      {
        code: "100",
        name: "Radenci",
      },
      {
        code: "099",
        name: "Radeče",
      },
      {
        code: "101",
        name: "Radlje ob Dravi",
      },
      {
        code: "102",
        name: "Radovljica",
      },
      {
        code: "103",
        name: "Ravne na Koroškem",
      },
      {
        code: "176",
        name: "Razkrižje",
      },
      {
        code: "098",
        name: "Rače–Fram",
      },
      {
        code: "201",
        name: "Renče-Vogrsko",
      },
      {
        code: "209",
        name: "Rečica ob Savinji",
      },
      {
        code: "104",
        name: "Ribnica",
      },
      {
        code: "177",
        name: "Ribnica na Pohorju",
      },
      {
        code: "107",
        name: "Rogatec",
      },
      {
        code: "106",
        name: "Rogaška Slatina",
      },
      {
        code: "105",
        name: "Rogašovci",
      },
      {
        code: "108",
        name: "Ruše",
      },
      {
        code: "178",
        name: "Selnica ob Dravi",
      },
      {
        code: "109",
        name: "Semič",
      },
      {
        code: "110",
        name: "Sevnica",
      },
      {
        code: "111",
        name: "Sežana",
      },
      {
        code: "112",
        name: "Slovenj Gradec",
      },
      {
        code: "113",
        name: "Slovenska Bistrica",
      },
      {
        code: "114",
        name: "Slovenske Konjice",
      },
      {
        code: "179",
        name: "Sodražica",
      },
      {
        code: "180",
        name: "Solčava",
      },
      {
        code: "202",
        name: "Središče ob Dravi",
      },
      {
        code: "115",
        name: "Starše",
      },
      {
        code: "203",
        name: "Straža",
      },
      {
        code: "181",
        name: "Sveta Ana",
      },
      {
        code: "204",
        name: "Sveta Trojica v Slovenskih Goricah",
      },
      {
        code: "182",
        name: "Sveti Andraž v Slovenskih Goricah",
      },
      {
        code: "116",
        name: "Sveti Jurij",
      },
      {
        code: "210",
        name: "Sveti Jurij v Slovenskih Goricah",
      },
      {
        code: "205",
        name: "Sveti Tomaž",
      },
      {
        code: "184",
        name: "Tabor",
      },
      {
        code: "010",
        name: "Tišina",
      },
      {
        code: "128",
        name: "Tolmin",
      },
      {
        code: "129",
        name: "Trbovlje",
      },
      {
        code: "130",
        name: "Trebnje",
      },
      {
        code: "185",
        name: "Trnovska Vas",
      },
      {
        code: "186",
        name: "Trzin",
      },
      {
        code: "131",
        name: "Tržič",
      },
      {
        code: "132",
        name: "Turnišče",
      },
      {
        code: "133",
        name: "Velenje",
      },
      {
        code: "187",
        name: "Velika Polana",
      },
      {
        code: "134",
        name: "Velike Lašče",
      },
      {
        code: "188",
        name: "Veržej",
      },
      {
        code: "135",
        name: "Videm",
      },
      {
        code: "136",
        name: "Vipava",
      },
      {
        code: "137",
        name: "Vitanje",
      },
      {
        code: "138",
        name: "Vodice",
      },
      {
        code: "139",
        name: "Vojnik",
      },
      {
        code: "189",
        name: "Vransko",
      },
      {
        code: "140",
        name: "Vrhnika",
      },
      {
        code: "141",
        name: "Vuzenica",
      },
      {
        code: "142",
        name: "Zagorje ob Savi",
      },
      {
        code: "143",
        name: "Zavrč",
      },
      {
        code: "144",
        name: "Zreče",
      },
      {
        code: "015",
        name: "Črenšovci",
      },
      {
        code: "016",
        name: "Črna na Koroškem",
      },
      {
        code: "017",
        name: "Črnomelj",
      },
      {
        code: "033",
        name: "Šalovci",
      },
      {
        code: "183",
        name: "Šempeter–Vrtojba",
      },
      {
        code: "118",
        name: "Šentilj",
      },
      {
        code: "119",
        name: "Šentjernej",
      },
      {
        code: "120",
        name: "Šentjur",
      },
      {
        code: "211",
        name: "Šentrupert",
      },
      {
        code: "117",
        name: "Šenčur",
      },
      {
        code: "121",
        name: "Škocjan",
      },
      {
        code: "122",
        name: "Škofja Loka",
      },
      {
        code: "123",
        name: "Škofljica",
      },
      {
        code: "124",
        name: "Šmarje pri Jelšah",
      },
      {
        code: "206",
        name: "Šmarješke Toplice",
      },
      {
        code: "125",
        name: "Šmartno ob Paki",
      },
      {
        code: "194",
        name: "Šmartno pri Litiji",
      },
      {
        code: "126",
        name: "Šoštanj",
      },
      {
        code: "127",
        name: "Štore",
      },
      {
        code: "190",
        name: "Žalec",
      },
      {
        code: "146",
        name: "Železniki",
      },
      {
        code: "191",
        name: "Žetale",
      },
      {
        code: "147",
        name: "Žiri",
      },
      {
        code: "192",
        name: "Žirovnica",
      },
      {
        code: "193",
        name: "Žužemberk",
      },
    ],
  },
  {
    code2: "SB",
    code3: "SLB",
    name: "Solomon Islands",
    capital: "Honiara",
    region: "Oceania",
    subregion: "Melanesia",
    states: [
      {
        code: "CT",
        name: "Capital Territory",
      },
      {
        code: "CE",
        name: "Central",
      },
      {
        code: "CH",
        name: "Choiseul",
      },
      {
        code: "GU",
        name: "Guadalcanal",
      },
      {
        code: "IS",
        name: "Isabel",
      },
      {
        code: "MK",
        name: "Makira-Ulawa",
      },
      {
        code: "ML",
        name: "Malaita",
      },
      {
        code: "RB",
        name: "Rennell and Bellona",
      },
      {
        code: "TE",
        name: "Temotu",
      },
      {
        code: "WE",
        name: "Western",
      },
    ],
  },
  {
    code2: "SO",
    code3: "SOM",
    name: "Somalia",
    capital: "Mogadishu",
    region: "Africa",
    subregion: "Eastern Africa",
    states: [
      {
        code: "AW",
        name: "Awdal",
      },
      {
        code: "BK",
        name: "Bakool",
      },
      {
        code: "BN",
        name: "Banaadir",
      },
      {
        code: "BR",
        name: "Bari",
      },
      {
        code: "BY",
        name: "Bay",
      },
      {
        code: "GA",
        name: "Galguduud",
      },
      {
        code: "GE",
        name: "Gedo",
      },
      {
        code: "HI",
        name: "Hiiraan",
      },
      {
        code: "JD",
        name: "Jubbada Dhexe",
      },
      {
        code: "JH",
        name: "Jubbada Hoose",
      },
      {
        code: "MU",
        name: "Mudug",
      },
      {
        code: "NU",
        name: "Nugaal",
      },
      {
        code: "SA",
        name: "Sanaag",
      },
      {
        code: "SD",
        name: "Shabeellaha Dhexe",
      },
      {
        code: "SH",
        name: "Shabeellaha Hoose",
      },
      {
        code: "SO",
        name: "Sool",
      },
      {
        code: "TO",
        name: "Togdheer",
      },
      {
        code: "WO",
        name: "Woqooyi Galbeed",
      },
    ],
  },
  {
    code2: "ZA",
    code3: "ZAF",
    name: "South Africa",
    capital: "Pretoria",
    region: "Africa",
    subregion: "Southern Africa",
    states: [
      {
        code: "EC",
        name: "Eastern Cape",
      },
      {
        code: "FS",
        name: "Free State",
      },
      {
        code: "GT",
        name: "Gauteng",
      },
      {
        code: "NL",
        name: "KwaZulu-Natal",
      },
      {
        code: "LP",
        name: "Limpopo",
      },
      {
        code: "MP",
        name: "Mpumalanga",
      },
      {
        code: "NW",
        name: "North West",
      },
      {
        code: "NC",
        name: "Northern Cape",
      },
      {
        code: "WC",
        name: "Western Cape",
      },
    ],
  },
  {
    code2: "GS",
    code3: "SGS",
    name: "South Georgia",
    capital: "King Edward Point",
    region: "Americas",
    subregion: "South America",
    states: [],
  },
  {
    code2: "KR",
    code3: "KOR",
    name: "South Korea",
    capital: "Seoul",
    region: "Asia",
    subregion: "Eastern Asia",
    states: [
      {
        code: "26",
        name: "Busan-gwangyeoksi",
      },
      {
        code: "43",
        name: "Chungcheongbuk-do",
      },
      {
        code: "44",
        name: "Chungcheongnam-do",
      },
      {
        code: "27",
        name: "Daegu-gwangyeoksi",
      },
      {
        code: "30",
        name: "Daejeon-gwangyeoksi",
      },
      {
        code: "42",
        name: "Gangwon-do",
      },
      {
        code: "29",
        name: "Gwangju-gwangyeoksi",
      },
      {
        code: "41",
        name: "Gyeonggi-do",
      },
      {
        code: "47",
        name: "Gyeongsangbuk-do",
      },
      {
        code: "48",
        name: "Gyeongsangnam-do",
      },
      {
        code: "28",
        name: "Incheon-gwangyeoksi",
      },
      {
        code: "49",
        name: "Jeju-teukbyeoljachido",
      },
      {
        code: "45",
        name: "Jeollabuk-do",
      },
      {
        code: "46",
        name: "Jeollanam-do",
      },
      {
        code: "50",
        name: "Sejong",
      },
      {
        code: "11",
        name: "Seoul-teukbyeolsi",
      },
      {
        code: "31",
        name: "Ulsan-gwangyeoksi",
      },
    ],
  },
  {
    code2: "SS",
    code3: "SSD",
    name: "South Sudan",
    capital: "Juba",
    region: "Africa",
    subregion: "Middle Africa",
    states: [
      {
        code: "EC",
        name: "Central Equatoria",
      },
      {
        code: "EE",
        name: "Eastern Equatoria",
      },
      {
        code: "JG",
        name: "Jonglei",
      },
      {
        code: "LK",
        name: "Lakes",
      },
      {
        code: "BN",
        name: "Northern Bahr el Ghazal",
      },
      {
        code: "UY",
        name: "Unity",
      },
      {
        code: "NU",
        name: "Upper Nile",
      },
      {
        code: "WR",
        name: "Warrap",
      },
      {
        code: "BW",
        name: "Western Bahr el Ghazal",
      },
      {
        code: "EW",
        name: "Western Equatoria",
      },
    ],
  },
  {
    code2: "ES",
    code3: "ESP",
    name: "Spain",
    capital: "Madrid",
    region: "Europe",
    subregion: "Southern Europe",
    states: [
      {
        code: "C",
        name: "A Coruña",
      },
      {
        code: "AB",
        name: "Albacete",
      },
      {
        code: "A",
        name: "Alicante",
      },
      {
        code: "AL",
        name: "Almería",
      },
      {
        code: "O",
        name: "Asturias",
      },
      {
        code: "BA",
        name: "Badajoz",
      },
      {
        code: "PM",
        name: "Balears",
      },
      {
        code: "B",
        name: "Barcelona",
      },
      {
        code: "BU",
        name: "Burgos",
      },
      {
        code: "S",
        name: "Cantabria",
      },
      {
        code: "CS",
        name: "Castellón",
      },
      {
        code: "CR",
        name: "Ciudad Real",
      },
      {
        code: "CU",
        name: "Cuenca",
      },
      {
        code: "CC",
        name: "Cáceres",
      },
      {
        code: "CA",
        name: "Cádiz",
      },
      {
        code: "CO",
        name: "Córdoba",
      },
      {
        code: "GI",
        name: "Girona",
      },
      {
        code: "GR",
        name: "Granada",
      },
      {
        code: "GU",
        name: "Guadalajara",
      },
      {
        code: "SS",
        name: "Guipúzcoa",
      },
      {
        code: "H",
        name: "Huelva",
      },
      {
        code: "HU",
        name: "Huesca",
      },
      {
        code: "J",
        name: "Jaén",
      },
      {
        code: "LO",
        name: "La Rioja",
      },
      {
        code: "GC",
        name: "Las Palmas",
      },
      {
        code: "LE",
        name: "León",
      },
      {
        code: "L",
        name: "Lleida",
      },
      {
        code: "LU",
        name: "Lugo",
      },
      {
        code: "M",
        name: "Madrid",
      },
      {
        code: "MU",
        name: "Murcia",
      },
      {
        code: "MA",
        name: "Málaga",
      },
      {
        code: "NA",
        name: "Navarra",
      },
      {
        code: "OR",
        name: "Ourense",
      },
      {
        code: "P",
        name: "Palencia",
      },
      {
        code: "PO",
        name: "Pontevedra",
      },
      {
        code: "SA",
        name: "Salamanca",
      },
      {
        code: "TF",
        name: "Santa Cruz de Tenerife",
      },
      {
        code: "SG",
        name: "Segovia",
      },
      {
        code: "SE",
        name: "Sevilla",
      },
      {
        code: "SO",
        name: "Soria",
      },
      {
        code: "T",
        name: "Tarragona",
      },
      {
        code: "TE",
        name: "Teruel",
      },
      {
        code: "TO",
        name: "Toledo",
      },
      {
        code: "V",
        name: "Valencia",
      },
      {
        code: "VA",
        name: "Valladolid",
      },
      {
        code: "BI",
        name: "Vizcaya",
      },
      {
        code: "ZA",
        name: "Zamora",
      },
      {
        code: "Z",
        name: "Zaragoza",
      },
      {
        code: "VI",
        name: "Álava",
      },
      {
        code: "AV",
        name: "Ávila",
      },
      {
        code: "CE",
        name: "Ceuta",
      },
      {
        code: "ML",
        name: "Melilla",
      },
      {
        code: "AN",
        name: "Andalucía",
      },
      {
        code: "AR",
        name: "Aragón",
      },
      {
        code: "AS",
        name: "Asturias, Principado de",
      },
      {
        code: "CN",
        name: "Canarias",
      },
      {
        code: "CB",
        name: "Cantabria",
      },
      {
        code: "CL",
        name: "Castilla y León",
      },
      {
        code: "CM",
        name: "Castilla-La Mancha",
      },
      {
        code: "CT",
        name: "Catalunya",
      },
      {
        code: "EX",
        name: "Extremadura",
      },
      {
        code: "GA",
        name: "Galicia",
      },
      {
        code: "IB",
        name: "Illes Balears",
      },
      {
        code: "RI",
        name: "La Rioja",
      },
      {
        code: "MD",
        name: "Madrid, Comunidad de",
      },
      {
        code: "MC",
        name: "Murcia, Región de",
      },
      {
        code: "NC",
        name: "Navarra, Comunidad Foral de",
      },
      {
        code: "PV",
        name: "País Vasco",
      },
      {
        code: "VC",
        name: "Valenciana, Comunidad",
      },
    ],
  },
  {
    code2: "LK",
    code3: "LKA",
    name: "Sri Lanka",
    capital: "Colombo",
    region: "Asia",
    subregion: "Southern Asia",
    states: [
      {
        code: "2",
        name: "Central Province",
      },
      {
        code: "5",
        name: "Eastern Province",
      },
      {
        code: "7",
        name: "North Central Province",
      },
      {
        code: "6",
        name: "North Western Province",
      },
      {
        code: "4",
        name: "Northern Province",
      },
      {
        code: "9",
        name: "Sabaragamuwa Province",
      },
      {
        code: "3",
        name: "Southern Province",
      },
      {
        code: "8",
        name: "Uva Province",
      },
      {
        code: "1",
        name: "Western Province",
      },
    ],
  },
  {
    code2: "SD",
    code3: "SDN",
    name: "Sudan",
    capital: "Khartoum",
    region: "Africa",
    subregion: "Northern Africa",
    states: [
      {
        code: "RS",
        name: "Al Baḩr al Aḩmar",
      },
      {
        code: "GZ",
        name: "Al Jazīrah",
      },
      {
        code: "KH",
        name: "Al Kharţūm",
      },
      {
        code: "GD",
        name: "Al Qaḑārif",
      },
      {
        code: "NR",
        name: "An Nīl",
      },
      {
        code: "NW",
        name: "An Nīl al Abyaḑ",
      },
      {
        code: "NB",
        name: "An Nīl al Azraq",
      },
      {
        code: "NO",
        name: "Ash Shamālīyah",
      },
      {
        code: "DW",
        name: "Gharb Dārfūr",
      },
      {
        code: "DS",
        name: "Janūb Dārfūr",
      },
      {
        code: "KS",
        name: "Janūb Kurdufān",
      },
      {
        code: "KA",
        name: "Kassalā",
      },
      {
        code: "DN",
        name: "Shamāl Dārfūr",
      },
      {
        code: "KN",
        name: "Shamāl Kurdufān",
      },
      {
        code: "DE",
        name: "Sharq Dārfūr",
      },
      {
        code: "SI",
        name: "Sinnār",
      },
      {
        code: "DC",
        name: "Zalingei",
      },
    ],
  },
  {
    code2: "SR",
    code3: "SUR",
    name: "Suriname",
    capital: "Paramaribo",
    region: "Americas",
    subregion: "South America",
    states: [
      {
        code: "BR",
        name: "Brokopondo",
      },
      {
        code: "CM",
        name: "Commewijne",
      },
      {
        code: "CR",
        name: "Coronie",
      },
      {
        code: "MA",
        name: "Marowijne",
      },
      {
        code: "NI",
        name: "Nickerie",
      },
      {
        code: "PR",
        name: "Para",
      },
      {
        code: "PM",
        name: "Paramaribo",
      },
      {
        code: "SA",
        name: "Saramacca",
      },
      {
        code: "SI",
        name: "Sipaliwini",
      },
      {
        code: "WA",
        name: "Wanica",
      },
    ],
  },
  {
    code2: "SJ",
    code3: "SJM",
    name: "Svalbard and Jan Mayen",
    capital: "Longyearbyen",
    region: "Europe",
    subregion: "Northern Europe",
    states: [],
  },
  {
    code2: "SZ",
    code3: "SWZ",
    name: "Swaziland",
    capital: "Lobamba",
    region: "Africa",
    subregion: "Southern Africa",
    states: [
      {
        code: "HH",
        name: "Hhohho",
      },
      {
        code: "LU",
        name: "Lubombo",
      },
      {
        code: "MA",
        name: "Manzini",
      },
      {
        code: "SH",
        name: "Shiselweni",
      },
    ],
  },
  {
    code2: "SE",
    code3: "SWE",
    name: "Sweden",
    capital: "Stockholm",
    region: "Europe",
    subregion: "Northern Europe",
    states: [
      {
        code: "K",
        name: "Blekinge län",
      },
      {
        code: "W",
        name: "Dalarnas län",
      },
      {
        code: "I",
        name: "Gotlands län",
      },
      {
        code: "X",
        name: "Gävleborgs län",
      },
      {
        code: "N",
        name: "Hallands län",
      },
      {
        code: "Z",
        name: "Jämtlands län",
      },
      {
        code: "F",
        name: "Jönköpings län",
      },
      {
        code: "H",
        name: "Kalmar län",
      },
      {
        code: "G",
        name: "Kronobergs län",
      },
      {
        code: "BD",
        name: "Norrbottens län",
      },
      {
        code: "M",
        name: "Skåne län",
      },
      {
        code: "AB",
        name: "Stockholms län",
      },
      {
        code: "D",
        name: "Södermanlands län",
      },
      {
        code: "C",
        name: "Uppsala län",
      },
      {
        code: "S",
        name: "Värmlands län",
      },
      {
        code: "AC",
        name: "Västerbottens län",
      },
      {
        code: "Y",
        name: "Västernorrlands län",
      },
      {
        code: "U",
        name: "Västmanlands län",
      },
      {
        code: "O",
        name: "Västra Götalands län",
      },
      {
        code: "T",
        name: "Örebro län",
      },
      {
        code: "E",
        name: "Östergötlands län",
      },
    ],
  },
  {
    code2: "CH",
    code3: "CHE",
    name: "Switzerland",
    capital: "Bern",
    region: "Europe",
    subregion: "Western Europe",
    states: [
      {
        code: "AG",
        name: "Aargau",
      },
      {
        code: "AR",
        name: "Appenzell Ausserrhoden",
      },
      {
        code: "AI",
        name: "Appenzell Innerrhoden",
      },
      {
        code: "BL",
        name: "Basel-Landschaft",
      },
      {
        code: "BS",
        name: "Basel-Stadt",
      },
      {
        code: "BE",
        name: "Bern",
      },
      {
        code: "FR",
        name: "Fribourg",
      },
      {
        code: "GE",
        name: "Genève",
      },
      {
        code: "GL",
        name: "Glarus",
      },
      {
        code: "GR",
        name: "Graubünden",
      },
      {
        code: "JU",
        name: "Jura",
      },
      {
        code: "LU",
        name: "Luzern",
      },
      {
        code: "NE",
        name: "Neuchâtel",
      },
      {
        code: "NW",
        name: "Nidwalden",
      },
      {
        code: "OW",
        name: "Obwalden",
      },
      {
        code: "SG",
        name: "Sankt Gallen",
      },
      {
        code: "SH",
        name: "Schaffhausen",
      },
      {
        code: "SZ",
        name: "Schwyz",
      },
      {
        code: "SO",
        name: "Solothurn",
      },
      {
        code: "TG",
        name: "Thurgau",
      },
      {
        code: "TI",
        name: "Ticino",
      },
      {
        code: "UR",
        name: "Uri",
      },
      {
        code: "VS",
        name: "Valais",
      },
      {
        code: "VD",
        name: "Vaud",
      },
      {
        code: "ZG",
        name: "Zug",
      },
      {
        code: "ZH",
        name: "Zürich",
      },
    ],
  },
  {
    code2: "SY",
    code3: "SYR",
    name: "Syria",
    capital: "Damascus",
    region: "Asia",
    subregion: "Western Asia",
    states: [
      {
        code: "LA",
        name: "Al Lādhiqīyah",
      },
      {
        code: "QU",
        name: "Al Qunayţirah",
      },
      {
        code: "HA",
        name: "Al Ḩasakah",
      },
      {
        code: "RA",
        name: "Ar Raqqah",
      },
      {
        code: "SU",
        name: "As Suwaydā'",
      },
      {
        code: "DR",
        name: "Darٰā",
      },
      {
        code: "DY",
        name: "Dayr az Zawr",
      },
      {
        code: "DI",
        name: "Dimashq",
      },
      {
        code: "ID",
        name: "Idlib",
      },
      {
        code: "RD",
        name: "Rīf Dimashq",
      },
      {
        code: "TA",
        name: "Ţarţūs",
      },
      {
        code: "HL",
        name: "Ḩalab",
      },
      {
        code: "HM",
        name: "Ḩamāh",
      },
      {
        code: "HI",
        name: "Ḩimş",
      },
    ],
  },
  {
    code2: "TW",
    code3: "TWN",
    name: "Taiwan",
    capital: "Taipei",
    region: "Asia",
    subregion: "Eastern Asia",
    states: [
      {
        code: "CHA",
        name: "Changhua",
      },
      {
        code: "CYQ",
        name: "Chiayi",
      },
      {
        code: "CYI",
        name: "Chiayi",
      },
      {
        code: "HSZ",
        name: "Hsinchu",
      },
      {
        code: "HSQ",
        name: "Hsinchu",
      },
      {
        code: "HUA",
        name: "Hualien",
      },
      {
        code: "ILA",
        name: "Ilan",
      },
      {
        code: "KHQ",
        name: "Kaohsiung",
      },
      {
        code: "KHH",
        name: "Kaohsiung",
      },
      {
        code: "KEE",
        name: "Keelung",
      },
      {
        code: "MIA",
        name: "Miaoli",
      },
      {
        code: "NAN",
        name: "Nantou",
      },
      {
        code: "PEN",
        name: "Penghu",
      },
      {
        code: "PIF",
        name: "Pingtung",
      },
      {
        code: "TXG",
        name: "Taichung",
      },
      {
        code: "TXQ",
        name: "Taichung",
      },
      {
        code: "TNN",
        name: "Tainan",
      },
      {
        code: "TNQ",
        name: "Tainan",
      },
      {
        code: "TPE",
        name: "Taipei",
      },
      {
        code: "TPQ",
        name: "Taipei",
      },
      {
        code: "TTT",
        name: "Taitung",
      },
      {
        code: "TAO",
        name: "Taoyuan",
      },
      {
        code: "YUN",
        name: "Yunlin",
      },
    ],
  },
  {
    code2: "TJ",
    code3: "TJK",
    name: "Tajikistan",
    capital: "Dushanbe",
    region: "Asia",
    subregion: "Central Asia",
    states: [
      {
        code: "DU",
        name: "Dushanbe",
      },
      {
        code: "KT",
        name: "Khatlon",
      },
      {
        code: "GB",
        name: "Kŭhistoni Badakhshon",
      },
      {
        code: "SU",
        name: "Sughd",
      },
    ],
  },
  {
    code2: "TZ",
    code3: "TZA",
    name: "Tanzania",
    capital: "Dodoma",
    region: "Africa",
    subregion: "Eastern Africa",
    states: [
      {
        code: "01",
        name: "Arusha",
      },
      {
        code: "02",
        name: "Dar es Salaam",
      },
      {
        code: "03",
        name: "Dodoma",
      },
      {
        code: "04",
        name: "Iringa",
      },
      {
        code: "05",
        name: "Kagera",
      },
      {
        code: "06",
        name: "Kaskazini Pemba",
      },
      {
        code: "07",
        name: "Kaskazini Unguja",
      },
      {
        code: "08",
        name: "Kigoma",
      },
      {
        code: "09",
        name: "Kilimanjaro",
      },
      {
        code: "10",
        name: "Kusini Pemba",
      },
      {
        code: "11",
        name: "Kusini Unguja",
      },
      {
        code: "12",
        name: "Lindi",
      },
      {
        code: "26",
        name: "Manyara",
      },
      {
        code: "13",
        name: "Mara",
      },
      {
        code: "14",
        name: "Mbeya",
      },
      {
        code: "15",
        name: "Mjini Magharibi",
      },
      {
        code: "16",
        name: "Morogoro",
      },
      {
        code: "17",
        name: "Mtwara",
      },
      {
        code: "18",
        name: "Mwanza",
      },
      {
        code: "19",
        name: "Pwani",
      },
      {
        code: "20",
        name: "Rukwa",
      },
      {
        code: "21",
        name: "Ruvuma",
      },
      {
        code: "22",
        name: "Shinyanga",
      },
      {
        code: "23",
        name: "Singida",
      },
      {
        code: "24",
        name: "Tabora",
      },
      {
        code: "25",
        name: "Tanga",
      },
    ],
  },
  {
    code2: "TH",
    code3: "THA",
    name: "Thailand",
    capital: "Bangkok",
    region: "Asia",
    subregion: "South-Eastern Asia",
    states: [
      {
        code: "37",
        name: "Amnat Charoen",
      },
      {
        code: "15",
        name: "Ang Thong",
      },
      {
        code: "38",
        name: "Bueng Kan",
      },
      {
        code: "31",
        name: "Buri Ram",
      },
      {
        code: "24",
        name: "Chachoengsao",
      },
      {
        code: "18",
        name: "Chai Nat",
      },
      {
        code: "36",
        name: "Chaiyaphum",
      },
      {
        code: "22",
        name: "Chanthaburi",
      },
      {
        code: "50",
        name: "Chiang Mai",
      },
      {
        code: "57",
        name: "Chiang Rai",
      },
      {
        code: "20",
        name: "Chon Buri",
      },
      {
        code: "86",
        name: "Chumphon",
      },
      {
        code: "46",
        name: "Kalasin",
      },
      {
        code: "62",
        name: "Kamphaeng Phet",
      },
      {
        code: "71",
        name: "Kanchanaburi",
      },
      {
        code: "40",
        name: "Khon Kaen",
      },
      {
        code: "81",
        name: "Krabi",
      },
      {
        code: "10",
        name: "Krung Thep Maha Nakhon",
      },
      {
        code: "52",
        name: "Lampang",
      },
      {
        code: "51",
        name: "Lamphun",
      },
      {
        code: "42",
        name: "Loei",
      },
      {
        code: "16",
        name: "Lop Buri",
      },
      {
        code: "58",
        name: "Mae Hong Son",
      },
      {
        code: "44",
        name: "Maha Sarakham",
      },
      {
        code: "49",
        name: "Mukdahan",
      },
      {
        code: "26",
        name: "Nakhon Nayok",
      },
      {
        code: "73",
        name: "Nakhon Pathom",
      },
      {
        code: "48",
        name: "Nakhon Phanom",
      },
      {
        code: "30",
        name: "Nakhon Ratchasima",
      },
      {
        code: "60",
        name: "Nakhon Sawan",
      },
      {
        code: "80",
        name: "Nakhon Si Thammarat",
      },
      {
        code: "55",
        name: "Nan",
      },
      {
        code: "96",
        name: "Narathiwat",
      },
      {
        code: "39",
        name: "Nong Bua Lam Phu",
      },
      {
        code: "43",
        name: "Nong Khai",
      },
      {
        code: "12",
        name: "Nonthaburi",
      },
      {
        code: "13",
        name: "Pathum Thani",
      },
      {
        code: "94",
        name: "Pattani",
      },
      {
        code: "82",
        name: "Phangnga",
      },
      {
        code: "93",
        name: "Phatthalung",
      },
      {
        code: "S",
        name: "Phatthaya",
      },
      {
        code: "56",
        name: "Phayao",
      },
      {
        code: "67",
        name: "Phetchabun",
      },
      {
        code: "76",
        name: "Phetchaburi",
      },
      {
        code: "66",
        name: "Phichit",
      },
      {
        code: "65",
        name: "Phitsanulok",
      },
      {
        code: "14",
        name: "Phra Nakhon Si Ayutthaya",
      },
      {
        code: "54",
        name: "Phrae",
      },
      {
        code: "83",
        name: "Phuket",
      },
      {
        code: "25",
        name: "Prachin Buri",
      },
      {
        code: "77",
        name: "Prachuap Khiri Khan",
      },
      {
        code: "85",
        name: "Ranong",
      },
      {
        code: "70",
        name: "Ratchaburi",
      },
      {
        code: "21",
        name: "Rayong",
      },
      {
        code: "45",
        name: "Roi Et",
      },
      {
        code: "27",
        name: "Sa Kaeo",
      },
      {
        code: "47",
        name: "Sakon Nakhon",
      },
      {
        code: "11",
        name: "Samut Prakan",
      },
      {
        code: "74",
        name: "Samut Sakhon",
      },
      {
        code: "75",
        name: "Samut Songkhram",
      },
      {
        code: "19",
        name: "Saraburi",
      },
      {
        code: "91",
        name: "Satun",
      },
      {
        code: "33",
        name: "Si Sa Ket",
      },
      {
        code: "17",
        name: "Sing Buri",
      },
      {
        code: "90",
        name: "Songkhla",
      },
      {
        code: "64",
        name: "Sukhothai",
      },
      {
        code: "72",
        name: "Suphan Buri",
      },
      {
        code: "84",
        name: "Surat Thani",
      },
      {
        code: "32",
        name: "Surin",
      },
      {
        code: "63",
        name: "Tak",
      },
      {
        code: "92",
        name: "Trang",
      },
      {
        code: "23",
        name: "Trat",
      },
      {
        code: "34",
        name: "Ubon Ratchathani",
      },
      {
        code: "41",
        name: "Udon Thani",
      },
      {
        code: "61",
        name: "Uthai Thani",
      },
      {
        code: "53",
        name: "Uttaradit",
      },
      {
        code: "95",
        name: "Yala",
      },
      {
        code: "35",
        name: "Yasothon",
      },
    ],
  },
  {
    code2: "TL",
    code3: "TLS",
    name: "East Timor",
    capital: "Dili",
    region: "Asia",
    subregion: "South-Eastern Asia",
    states: [
      {
        code: "AL",
        name: "Aileu",
      },
      {
        code: "AN",
        name: "Ainaro",
      },
      {
        code: "BA",
        name: "Baucau",
      },
      {
        code: "BO",
        name: "Bobonaro",
      },
      {
        code: "CO",
        name: "Cova Lima",
      },
      {
        code: "DI",
        name: "Díli",
      },
      {
        code: "ER",
        name: "Ermera",
      },
      {
        code: "LA",
        name: "Lautem",
      },
      {
        code: "LI",
        name: "Liquiça",
      },
      {
        code: "MT",
        name: "Manatuto",
      },
      {
        code: "MF",
        name: "Manufahi",
      },
      {
        code: "OE",
        name: "Oecussi",
      },
      {
        code: "VI",
        name: "Viqueque",
      },
    ],
  },
  {
    code2: "TG",
    code3: "TGO",
    name: "Togo",
    capital: "Lomé",
    region: "Africa",
    subregion: "Western Africa",
    states: [
      {
        code: "C",
        name: "Centre",
      },
      {
        code: "K",
        name: "Kara",
      },
      {
        code: "M",
        name: "Maritime",
      },
      {
        code: "P",
        name: "Plateaux",
      },
      {
        code: "S",
        name: "Savannes",
      },
    ],
  },
  {
    code2: "TK",
    code3: "TKL",
    name: "Tokelau",
    capital: "Fakaofo",
    region: "Oceania",
    subregion: "Polynesia",
    states: [],
  },
  {
    code2: "TO",
    code3: "TON",
    name: "Tonga",
    capital: "Nuku'alofa",
    region: "Oceania",
    subregion: "Polynesia",
    states: [
      {
        code: "01",
        name: "'Eua",
      },
      {
        code: "02",
        name: "Ha'apai",
      },
      {
        code: "03",
        name: "Niuas",
      },
      {
        code: "04",
        name: "Tongatapu",
      },
      {
        code: "05",
        name: "Vava'u",
      },
    ],
  },
  {
    code2: "TT",
    code3: "TTO",
    name: "Trinidad and Tobago",
    capital: "Port of Spain",
    region: "Americas",
    subregion: "Caribbean",
    states: [
      {
        code: "ARI",
        name: "Arima",
      },
      {
        code: "CHA",
        name: "Chaguanas",
      },
      {
        code: "CTT",
        name: "Couva-Tabaquite-Talparo",
      },
      {
        code: "DMN",
        name: "Diego Martin",
      },
      {
        code: "ETO",
        name: "Eastern Tobago",
      },
      {
        code: "PED",
        name: "Penal-Debe",
      },
      {
        code: "PTF",
        name: "Point Fortin",
      },
      {
        code: "POS",
        name: "Port of Spain",
      },
      {
        code: "PRT",
        name: "Princes Town",
      },
      {
        code: "RCM",
        name: "Rio Claro-Mayaro",
      },
      {
        code: "SFO",
        name: "San Fernando",
      },
      {
        code: "SJL",
        name: "San Juan-Laventille",
      },
      {
        code: "SGE",
        name: "Sangre Grande",
      },
      {
        code: "SIP",
        name: "Siparia",
      },
      {
        code: "TUP",
        name: "Tunapuna-Piarco",
      },
      {
        code: "WTO",
        name: "Western Tobago",
      },
    ],
  },
  {
    code2: "TN",
    code3: "TUN",
    name: "Tunisia",
    capital: "Tunis",
    region: "Africa",
    subregion: "Northern Africa",
    states: [
      {
        code: "12",
        name: "Ariana",
      },
      {
        code: "13",
        name: "Ben Arous",
      },
      {
        code: "23",
        name: "Bizerte",
      },
      {
        code: "31",
        name: "Béja",
      },
      {
        code: "81",
        name: "Gabès",
      },
      {
        code: "71",
        name: "Gafsa",
      },
      {
        code: "32",
        name: "Jendouba",
      },
      {
        code: "41",
        name: "Kairouan",
      },
      {
        code: "42",
        name: "Kasserine",
      },
      {
        code: "73",
        name: "Kebili",
      },
      {
        code: "14",
        name: "La Manouba",
      },
      {
        code: "33",
        name: "Le Kef",
      },
      {
        code: "53",
        name: "Mahdia",
      },
      {
        code: "82",
        name: "Medenine",
      },
      {
        code: "52",
        name: "Monastir",
      },
      {
        code: "21",
        name: "Nabeul",
      },
      {
        code: "61",
        name: "Sfax",
      },
      {
        code: "43",
        name: "Sidi Bouzid",
      },
      {
        code: "34",
        name: "Siliana",
      },
      {
        code: "51",
        name: "Sousse",
      },
      {
        code: "83",
        name: "Tataouine",
      },
      {
        code: "72",
        name: "Tozeur",
      },
      {
        code: "11",
        name: "Tunis",
      },
      {
        code: "22",
        name: "Zaghouan",
      },
    ],
  },
  {
    code2: "TR",
    code3: "TUR",
    name: "Turkey",
    capital: "Ankara",
    region: "Asia",
    subregion: "Western Asia",
    states: [
      {
        code: "01",
        name: "Adana",
      },
      {
        code: "02",
        name: "Adıyaman",
      },
      {
        code: "03",
        name: "Afyonkarahisar",
      },
      {
        code: "68",
        name: "Aksaray",
      },
      {
        code: "05",
        name: "Amasya",
      },
      {
        code: "06",
        name: "Ankara",
      },
      {
        code: "07",
        name: "Antalya",
      },
      {
        code: "75",
        name: "Ardahan",
      },
      {
        code: "08",
        name: "Artvin",
      },
      {
        code: "09",
        name: "Aydın",
      },
      {
        code: "04",
        name: "Ağrı",
      },
      {
        code: "10",
        name: "Balıkesir",
      },
      {
        code: "74",
        name: "Bartın",
      },
      {
        code: "72",
        name: "Batman",
      },
      {
        code: "69",
        name: "Bayburt",
      },
      {
        code: "11",
        name: "Bilecik",
      },
      {
        code: "12",
        name: "Bingöl",
      },
      {
        code: "13",
        name: "Bitlis",
      },
      {
        code: "14",
        name: "Bolu",
      },
      {
        code: "15",
        name: "Burdur",
      },
      {
        code: "16",
        name: "Bursa",
      },
      {
        code: "20",
        name: "Denizli",
      },
      {
        code: "21",
        name: "Diyarbakır",
      },
      {
        code: "81",
        name: "Düzce",
      },
      {
        code: "22",
        name: "Edirne",
      },
      {
        code: "23",
        name: "Elazığ",
      },
      {
        code: "24",
        name: "Erzincan",
      },
      {
        code: "25",
        name: "Erzurum",
      },
      {
        code: "26",
        name: "Eskişehir",
      },
      {
        code: "27",
        name: "Gaziantep",
      },
      {
        code: "28",
        name: "Giresun",
      },
      {
        code: "29",
        name: "Gümüşhane",
      },
      {
        code: "30",
        name: "Hakkâri",
      },
      {
        code: "31",
        name: "Hatay",
      },
      {
        code: "32",
        name: "Isparta",
      },
      {
        code: "76",
        name: "Iğdır",
      },
      {
        code: "46",
        name: "Kahramanmaraş",
      },
      {
        code: "78",
        name: "Karabük",
      },
      {
        code: "70",
        name: "Karaman",
      },
      {
        code: "36",
        name: "Kars",
      },
      {
        code: "37",
        name: "Kastamonu",
      },
      {
        code: "38",
        name: "Kayseri",
      },
      {
        code: "79",
        name: "Kilis",
      },
      {
        code: "41",
        name: "Kocaeli",
      },
      {
        code: "42",
        name: "Konya",
      },
      {
        code: "43",
        name: "Kütahya",
      },
      {
        code: "39",
        name: "Kırklareli",
      },
      {
        code: "71",
        name: "Kırıkkale",
      },
      {
        code: "40",
        name: "Kırşehir",
      },
      {
        code: "44",
        name: "Malatya",
      },
      {
        code: "45",
        name: "Manisa",
      },
      {
        code: "47",
        name: "Mardin",
      },
      {
        code: "33",
        name: "Mersin",
      },
      {
        code: "48",
        name: "Muğla",
      },
      {
        code: "49",
        name: "Muş",
      },
      {
        code: "50",
        name: "Nevşehir",
      },
      {
        code: "51",
        name: "Niğde",
      },
      {
        code: "52",
        name: "Ordu",
      },
      {
        code: "80",
        name: "Osmaniye",
      },
      {
        code: "53",
        name: "Rize",
      },
      {
        code: "54",
        name: "Sakarya",
      },
      {
        code: "55",
        name: "Samsun",
      },
      {
        code: "56",
        name: "Siirt",
      },
      {
        code: "57",
        name: "Sinop",
      },
      {
        code: "58",
        name: "Sivas",
      },
      {
        code: "59",
        name: "Tekirdağ",
      },
      {
        code: "60",
        name: "Tokat",
      },
      {
        code: "61",
        name: "Trabzon",
      },
      {
        code: "62",
        name: "Tunceli",
      },
      {
        code: "64",
        name: "Uşak",
      },
      {
        code: "65",
        name: "Van",
      },
      {
        code: "77",
        name: "Yalova",
      },
      {
        code: "66",
        name: "Yozgat",
      },
      {
        code: "67",
        name: "Zonguldak",
      },
      {
        code: "17",
        name: "Çanakkale",
      },
      {
        code: "18",
        name: "Çankırı",
      },
      {
        code: "19",
        name: "Çorum",
      },
      {
        code: "34",
        name: "İstanbul",
      },
      {
        code: "35",
        name: "İzmir",
      },
      {
        code: "63",
        name: "Şanlıurfa",
      },
      {
        code: "73",
        name: "Şırnak",
      },
    ],
  },
  {
    code2: "TM",
    code3: "TKM",
    name: "Turkmenistan",
    capital: "Ashgabat",
    region: "Asia",
    subregion: "Central Asia",
    states: [
      {
        code: "A",
        name: "Ahal",
      },
      {
        code: "S",
        name: "Aşgabat",
      },
      {
        code: "B",
        name: "Balkan",
      },
      {
        code: "D",
        name: "Daşoguz",
      },
      {
        code: "L",
        name: "Lebap",
      },
      {
        code: "M",
        name: "Mary",
      },
    ],
  },
  {
    code2: "TC",
    code3: "TCA",
    name: "Turks and Caicos Islands",
    capital: "Cockburn Town",
    region: "Americas",
    subregion: "Caribbean",
    states: [],
  },
  {
    code2: "TV",
    code3: "TUV",
    name: "Tuvalu",
    capital: "Funafuti",
    region: "Oceania",
    subregion: "Polynesia",
    states: [
      {
        code: "FUN",
        name: "Funafuti",
      },
      {
        code: "NMG",
        name: "Nanumanga",
      },
      {
        code: "NMA",
        name: "Nanumea",
      },
      {
        code: "NIT",
        name: "Niutao",
      },
      {
        code: "NUI",
        name: "Nui",
      },
      {
        code: "NKF",
        name: "Nukufetau",
      },
      {
        code: "NKL",
        name: "Nukulaelae",
      },
      {
        code: "VAI",
        name: "Vaitupu",
      },
    ],
  },
  {
    code2: "UG",
    code3: "UGA",
    name: "Uganda",
    capital: "Kampala",
    region: "Africa",
    subregion: "Eastern Africa",
    states: [
      {
        code: "C",
        name: "Central",
      },
      {
        code: "E",
        name: "Eastern",
      },
      {
        code: "N",
        name: "Northern",
      },
      {
        code: "W",
        name: "Western",
      },
    ],
  },
  {
    code2: "UA",
    code3: "UKR",
    name: "Ukraine",
    capital: "Kiev",
    region: "Europe",
    subregion: "Eastern Europe",
    states: [
      {
        code: "43",
        name: "Avtonomna Respublika Krym",
      },
      {
        code: "71",
        name: "Cherkas'ka Oblast'",
      },
      {
        code: "74",
        name: "Chernihivs'ka Oblast'",
      },
      {
        code: "77",
        name: "Chernivets'ka Oblast'",
      },
      {
        code: "12",
        name: "Dnipropetrovs'ka Oblast'",
      },
      {
        code: "14",
        name: "Donets'ka Oblast'",
      },
      {
        code: "26",
        name: "Ivano-Frankivs'ka Oblast'",
      },
      {
        code: "63",
        name: "Kharkivs'ka Oblast'",
      },
      {
        code: "65",
        name: "Khersons'ka Oblast'",
      },
      {
        code: "68",
        name: "Khmel'nyts'ka Oblast'",
      },
      {
        code: "35",
        name: "Kirovohrads'ka Oblast'",
      },
      {
        code: "30",
        name: "Kyïv",
      },
      {
        code: "32",
        name: "Kyïvs'ka Oblast'",
      },
      {
        code: "46",
        name: "L'vivs'ka Oblast'",
      },
      {
        code: "09",
        name: "Luhans'ka Oblast'",
      },
      {
        code: "48",
        name: "Mykolaïvs'ka Oblast'",
      },
      {
        code: "51",
        name: "Odes'ka Oblast'",
      },
      {
        code: "53",
        name: "Poltavs'ka Oblast'",
      },
      {
        code: "56",
        name: "Rivnens'ka Oblast'",
      },
      {
        code: "40",
        name: "Sevastopol'",
      },
      {
        code: "59",
        name: "Sums'ka Oblast'",
      },
      {
        code: "61",
        name: "Ternopil's'ka Oblast'",
      },
      {
        code: "05",
        name: "Vinnyts'ka Oblast'",
      },
      {
        code: "07",
        name: "Volyns'ka Oblast'",
      },
      {
        code: "21",
        name: "Zakarpats'ka Oblast'",
      },
      {
        code: "23",
        name: "Zaporiz'ka Oblast'",
      },
      {
        code: "18",
        name: "Zhytomyrs'ka Oblast'",
      },
    ],
  },
  {
    code2: "AE",
    code3: "ARE",
    name: "United Arab Emirates",
    capital: "Abu Dhabi",
    region: "Asia",
    subregion: "Western Asia",
    states: [
      {
        code: "AJ",
        name: "'Ajmān",
      },
      {
        code: "AZ",
        name: "Abū Z̧aby",
      },
      {
        code: "FU",
        name: "Al Fujayrah",
      },
      {
        code: "SH",
        name: "Ash Shāriqah",
      },
      {
        code: "DU",
        name: "Dubayy",
      },
      {
        code: "RK",
        name: "Ra's al Khaymah",
      },
      {
        code: "UQ",
        name: "Umm al Qaywayn",
      },
    ],
  },
  {
    code2: "GB",
    code3: "GBR",
    name: "United Kingdom",
    capital: "London",
    region: "Europe",
    subregion: "Northern Europe",
    states: [
      {
        code: "BDG",
        name: "Barking and Dagenham",
      },
      {
        code: "BNE",
        name: "Barnet",
      },
      {
        code: "BEX",
        name: "Bexley",
      },
      {
        code: "BEN",
        name: "Brent",
      },
      {
        code: "BRY",
        name: "Bromley",
      },
      {
        code: "CMD",
        name: "Camden",
      },
      {
        code: "CRY",
        name: "Croydon",
      },
      {
        code: "EAL",
        name: "Ealing",
      },
      {
        code: "ENF",
        name: "Enfield",
      },
      {
        code: "GRE",
        name: "Greenwich",
      },
      {
        code: "HCK",
        name: "Hackney",
      },
      {
        code: "HMF",
        name: "Hammersmith and Fulham",
      },
      {
        code: "HRY",
        name: "Haringey",
      },
      {
        code: "HRW",
        name: "Harrow",
      },
      {
        code: "HAV",
        name: "Havering",
      },
      {
        code: "HIL",
        name: "Hillingdon",
      },
      {
        code: "HNS",
        name: "Hounslow",
      },
      {
        code: "ISL",
        name: "Islington",
      },
      {
        code: "KEC",
        name: "Kensington and Chelsea",
      },
      {
        code: "KTT",
        name: "Kingston upon Thames",
      },
      {
        code: "LBH",
        name: "Lambeth",
      },
      {
        code: "LEW",
        name: "Lewisham",
      },
      {
        code: "MRT",
        name: "Merton",
      },
      {
        code: "NWM",
        name: "Newham",
      },
      {
        code: "RDB",
        name: "Redbridge",
      },
      {
        code: "RIC",
        name: "Richmond upon Thames",
      },
      {
        code: "SWK",
        name: "Southwark",
      },
      {
        code: "STN",
        name: "Sutton",
      },
      {
        code: "TWH",
        name: "Tower Hamlets",
      },
      {
        code: "WFT",
        name: "Waltham Forest",
      },
      {
        code: "WND",
        name: "Wandsworth",
      },
      {
        code: "WSM",
        name: "Westminster",
      },
      {
        code: "EAW",
        name: "England and Wales",
      },
      {
        code: "GBN",
        name: "Great Britain",
      },
      {
        code: "UKM",
        name: "United Kingdom",
      },
      {
        code: "LND",
        name: "London, City of",
      },
      {
        code: "ABE",
        name: "Aberdeen City",
      },
      {
        code: "ABD",
        name: "Aberdeenshire",
      },
      {
        code: "ANS",
        name: "Angus",
      },
      {
        code: "AGB",
        name: "Argyll and Bute",
      },
      {
        code: "CLK",
        name: "Clackmannanshire",
      },
      {
        code: "DGY",
        name: "Dumfries and Galloway",
      },
      {
        code: "DND",
        name: "Dundee City",
      },
      {
        code: "EAY",
        name: "East Ayrshire",
      },
      {
        code: "EDU",
        name: "East Dunbartonshire",
      },
      {
        code: "ELN",
        name: "East Lothian",
      },
      {
        code: "ERW",
        name: "East Renfrewshire",
      },
      {
        code: "EDH",
        name: "Edinburgh, City of",
      },
      {
        code: "ELS",
        name: "Eilean Siar",
      },
      {
        code: "FAL",
        name: "Falkirk",
      },
      {
        code: "FIF",
        name: "Fife",
      },
      {
        code: "GLG",
        name: "Glasgow City",
      },
      {
        code: "HLD",
        name: "Highland",
      },
      {
        code: "IVC",
        name: "Inverclyde",
      },
      {
        code: "MLN",
        name: "Midlothian",
      },
      {
        code: "MRY",
        name: "Moray",
      },
      {
        code: "NAY",
        name: "North Ayrshire",
      },
      {
        code: "NLK",
        name: "North Lanarkshire",
      },
      {
        code: "ORK",
        name: "Orkney Islands",
      },
      {
        code: "PKN",
        name: "Perth and Kinross",
      },
      {
        code: "RFW",
        name: "Renfrewshire",
      },
      {
        code: "SCB",
        name: "Scottish Borders, The",
      },
      {
        code: "ZET",
        name: "Shetland Islands",
      },
      {
        code: "SAY",
        name: "South Ayrshire",
      },
      {
        code: "SLK",
        name: "South Lanarkshire",
      },
      {
        code: "STG",
        name: "Stirling",
      },
      {
        code: "WDU",
        name: "West Dunbartonshire",
      },
      {
        code: "WLN",
        name: "West Lothian",
      },
      {
        code: "ENG",
        name: "England",
      },
      {
        code: "SCT",
        name: "Scotland",
      },
      {
        code: "WLS",
        name: "Wales",
      },
      {
        code: "ANT",
        name: "Antrim",
      },
      {
        code: "ARD",
        name: "Ards",
      },
      {
        code: "ARM",
        name: "Armagh",
      },
      {
        code: "BLA",
        name: "Ballymena",
      },
      {
        code: "BLY",
        name: "Ballymoney",
      },
      {
        code: "BNB",
        name: "Banbridge",
      },
      {
        code: "BFS",
        name: "Belfast",
      },
      {
        code: "CKF",
        name: "Carrickfergus",
      },
      {
        code: "CSR",
        name: "Castlereagh",
      },
      {
        code: "CLR",
        name: "Coleraine",
      },
      {
        code: "CKT",
        name: "Cookstown",
      },
      {
        code: "CGV",
        name: "Craigavon",
      },
      {
        code: "DRY",
        name: "Derry",
      },
      {
        code: "DOW",
        name: "Down",
      },
      {
        code: "DGN",
        name: "Dungannon and South Tyrone",
      },
      {
        code: "FER",
        name: "Fermanagh",
      },
      {
        code: "LRN",
        name: "Larne",
      },
      {
        code: "LMV",
        name: "Limavady",
      },
      {
        code: "LSB",
        name: "Lisburn",
      },
      {
        code: "MFT",
        name: "Magherafelt",
      },
      {
        code: "MYL",
        name: "Moyle",
      },
      {
        code: "NYM",
        name: "Newry and Mourne District",
      },
      {
        code: "NTA",
        name: "Newtownabbey",
      },
      {
        code: "NDN",
        name: "North Down",
      },
      {
        code: "OMH",
        name: "Omagh",
      },
      {
        code: "STB",
        name: "Strabane",
      },
      {
        code: "BNS",
        name: "Barnsley",
      },
      {
        code: "BIR",
        name: "Birmingham",
      },
      {
        code: "BOL",
        name: "Bolton",
      },
      {
        code: "BRD",
        name: "Bradford",
      },
      {
        code: "BUR",
        name: "Bury",
      },
      {
        code: "CLD",
        name: "Calderdale",
      },
      {
        code: "COV",
        name: "Coventry",
      },
      {
        code: "DNC",
        name: "Doncaster",
      },
      {
        code: "DUD",
        name: "Dudley",
      },
      {
        code: "GAT",
        name: "Gateshead",
      },
      {
        code: "KIR",
        name: "Kirklees",
      },
      {
        code: "KWL",
        name: "Knowsley",
      },
      {
        code: "LDS",
        name: "Leeds",
      },
      {
        code: "LIV",
        name: "Liverpool",
      },
      {
        code: "MAN",
        name: "Manchester",
      },
      {
        code: "NET",
        name: "Newcastle upon Tyne",
      },
      {
        code: "NTY",
        name: "North Tyneside",
      },
      {
        code: "OLD",
        name: "Oldham",
      },
      {
        code: "RCH",
        name: "Rochdale",
      },
      {
        code: "ROT",
        name: "Rotherham",
      },
      {
        code: "SLF",
        name: "Salford",
      },
      {
        code: "SAW",
        name: "Sandwell",
      },
      {
        code: "SFT",
        name: "Sefton",
      },
      {
        code: "SHF",
        name: "Sheffield",
      },
      {
        code: "SOL",
        name: "Solihull",
      },
      {
        code: "STY",
        name: "South Tyneside",
      },
      {
        code: "SHN",
        name: "St. Helens",
      },
      {
        code: "SKP",
        name: "Stockport",
      },
      {
        code: "SND",
        name: "Sunderland",
      },
      {
        code: "TAM",
        name: "Tameside",
      },
      {
        code: "TRF",
        name: "Trafford",
      },
      {
        code: "WKF",
        name: "Wakefield",
      },
      {
        code: "WLL",
        name: "Walsall",
      },
      {
        code: "WGN",
        name: "Wigan",
      },
      {
        code: "WRL",
        name: "Wirral",
      },
      {
        code: "WLV",
        name: "Wolverhampton",
      },
      {
        code: "NIR",
        name: "Northern Ireland",
      },
      {
        code: "BKM",
        name: "Buckinghamshire",
      },
      {
        code: "CAM",
        name: "Cambridgeshire",
      },
      {
        code: "CMA",
        name: "Cumbria",
      },
      {
        code: "DBY",
        name: "Derbyshire",
      },
      {
        code: "DEV",
        name: "Devon",
      },
      {
        code: "DOR",
        name: "Dorset",
      },
      {
        code: "ESX",
        name: "East Sussex",
      },
      {
        code: "ESS",
        name: "Essex",
      },
      {
        code: "GLS",
        name: "Gloucestershire",
      },
      {
        code: "HAM",
        name: "Hampshire",
      },
      {
        code: "HRT",
        name: "Hertfordshire",
      },
      {
        code: "KEN",
        name: "Kent",
      },
      {
        code: "LAN",
        name: "Lancashire",
      },
      {
        code: "LEC",
        name: "Leicestershire",
      },
      {
        code: "LIN",
        name: "Lincolnshire",
      },
      {
        code: "NFK",
        name: "Norfolk",
      },
      {
        code: "NYK",
        name: "North Yorkshire",
      },
      {
        code: "NTH",
        name: "Northamptonshire",
      },
      {
        code: "NTT",
        name: "Nottinghamshire",
      },
      {
        code: "OXF",
        name: "Oxfordshire",
      },
      {
        code: "SOM",
        name: "Somerset",
      },
      {
        code: "STS",
        name: "Staffordshire",
      },
      {
        code: "SFK",
        name: "Suffolk",
      },
      {
        code: "SRY",
        name: "Surrey",
      },
      {
        code: "WAR",
        name: "Warwickshire",
      },
      {
        code: "WSX",
        name: "West Sussex",
      },
      {
        code: "WOR",
        name: "Worcestershire",
      },
      {
        code: "BAS",
        name: "Bath and North East Somerset",
      },
      {
        code: "BDF",
        name: "Bedford",
      },
      {
        code: "BBD",
        name: "Blackburn with Darwen",
      },
      {
        code: "BPL",
        name: "Blackpool",
      },
      {
        code: "BGW",
        name: "Blaenau Gwent",
      },
      {
        code: "BMH",
        name: "Bournemouth",
      },
      {
        code: "BRC",
        name: "Bracknell Forest",
      },
      {
        code: "BGE",
        name: "Bridgend",
      },
      {
        code: "BNH",
        name: "Brighton and Hove",
      },
      {
        code: "BST",
        name: "Bristol, City of",
      },
      {
        code: "CAY",
        name: "Caerphilly",
      },
      {
        code: "CRF",
        name: "Cardiff",
      },
      {
        code: "CMN",
        name: "Carmarthenshire",
      },
      {
        code: "CBF",
        name: "Central Bedfordshire",
      },
      {
        code: "CGN",
        name: "Ceredigion",
      },
      {
        code: "CHE",
        name: "Cheshire East",
      },
      {
        code: "CHW",
        name: "Cheshire West and Chester",
      },
      {
        code: "CWY",
        name: "Conwy",
      },
      {
        code: "CON",
        name: "Cornwall",
      },
      {
        code: "DAL",
        name: "Darlington",
      },
      {
        code: "DEN",
        name: "Denbighshire",
      },
      {
        code: "DER",
        name: "Derby",
      },
      {
        code: "DUR",
        name: "Durham, County",
      },
      {
        code: "ERY",
        name: "East Riding of Yorkshire",
      },
      {
        code: "FLN",
        name: "Flintshire",
      },
      {
        code: "GWN",
        name: "Gwynedd",
      },
      {
        code: "HAL",
        name: "Halton",
      },
      {
        code: "HPL",
        name: "Hartlepool",
      },
      {
        code: "HEF",
        name: "Herefordshire",
      },
      {
        code: "AGY",
        name: "Isle of Anglesey",
      },
      {
        code: "IOW",
        name: "Isle of Wight",
      },
      {
        code: "IOS",
        name: "Isles of Scilly",
      },
      {
        code: "KHL",
        name: "Kingston upon Hull",
      },
      {
        code: "LCE",
        name: "Leicester",
      },
      {
        code: "LUT",
        name: "Luton",
      },
      {
        code: "MDW",
        name: "Medway",
      },
      {
        code: "MTY",
        name: "Merthyr Tydfil",
      },
      {
        code: "MDB",
        name: "Middlesbrough",
      },
      {
        code: "MIK",
        name: "Milton Keynes",
      },
      {
        code: "MON",
        name: "Monmouthshire",
      },
      {
        code: "NTL",
        name: "Neath Port Talbot",
      },
      {
        code: "NWP",
        name: "Newport",
      },
      {
        code: "NEL",
        name: "North East Lincolnshire",
      },
      {
        code: "NLN",
        name: "North Lincolnshire",
      },
      {
        code: "NSM",
        name: "North Somerset",
      },
      {
        code: "NBL",
        name: "Northumberland",
      },
      {
        code: "NGM",
        name: "Nottingham",
      },
      {
        code: "PEM",
        name: "Pembrokeshire",
      },
      {
        code: "PTE",
        name: "Peterborough",
      },
      {
        code: "PLY",
        name: "Plymouth",
      },
      {
        code: "POL",
        name: "Poole",
      },
      {
        code: "POR",
        name: "Portsmouth",
      },
      {
        code: "POW",
        name: "Powys",
      },
      {
        code: "RDG",
        name: "Reading",
      },
      {
        code: "RCC",
        name: "Redcar and Cleveland",
      },
      {
        code: "RCT",
        name: "Rhondda, Cynon, Taff",
      },
      {
        code: "RUT",
        name: "Rutland",
      },
      {
        code: "SHR",
        name: "Shropshire",
      },
      {
        code: "SLG",
        name: "Slough",
      },
      {
        code: "SGC",
        name: "South Gloucestershire",
      },
      {
        code: "STH",
        name: "Southampton",
      },
      {
        code: "SOS",
        name: "Southend-on-Sea",
      },
      {
        code: "STT",
        name: "Stockton-on-Tees",
      },
      {
        code: "STE",
        name: "Stoke-on-Trent",
      },
      {
        code: "SWA",
        name: "Swansea",
      },
      {
        code: "SWD",
        name: "Swindon",
      },
      {
        code: "TFW",
        name: "Telford and Wrekin",
      },
      {
        code: "THR",
        name: "Thurrock",
      },
      {
        code: "TOB",
        name: "Torbay",
      },
      {
        code: "TOF",
        name: "Torfaen",
      },
      {
        code: "VGL",
        name: "Vale of Glamorgan, The",
      },
      {
        code: "WRT",
        name: "Warrington",
      },
      {
        code: "WBK",
        name: "West Berkshire",
      },
      {
        code: "WIL",
        name: "Wiltshire",
      },
      {
        code: "WNM",
        name: "Windsor and Maidenhead",
      },
      {
        code: "WOK",
        name: "Wokingham",
      },
      {
        code: "WRX",
        name: "Wrexham",
      },
      {
        code: "YOR",
        name: "York",
      },
    ],
  },
  {
    code2: "US",
    code3: "USA",
    name: "United States",
    capital: "Washington, D.C.",
    region: "Americas",
    subregion: "Northern America",
    states: [
      {
        code: "DC",
        name: "District of Columbia",
      },
      {
        code: "AS",
        name: "American Samoa",
      },
      {
        code: "GU",
        name: "Guam",
      },
      {
        code: "MP",
        name: "Northern Mariana Islands",
      },
      {
        code: "PR",
        name: "Puerto Rico",
      },
      {
        code: "UM",
        name: "United States Minor Outlying Islands",
      },
      {
        code: "VI",
        name: "Virgin Islands, U.S.",
      },
      {
        code: "AL",
        name: "Alabama",
      },
      {
        code: "AK",
        name: "Alaska",
      },
      {
        code: "AZ",
        name: "Arizona",
      },
      {
        code: "AR",
        name: "Arkansas",
      },
      {
        code: "CA",
        name: "California",
      },
      {
        code: "CO",
        name: "Colorado",
      },
      {
        code: "CT",
        name: "Connecticut",
      },
      {
        code: "DE",
        name: "Delaware",
      },
      {
        code: "FL",
        name: "Florida",
      },
      {
        code: "GA",
        name: "Georgia",
      },
      {
        code: "HI",
        name: "Hawaii",
      },
      {
        code: "ID",
        name: "Idaho",
      },
      {
        code: "IL",
        name: "Illinois",
      },
      {
        code: "IN",
        name: "Indiana",
      },
      {
        code: "IA",
        name: "Iowa",
      },
      {
        code: "KS",
        name: "Kansas",
      },
      {
        code: "KY",
        name: "Kentucky",
      },
      {
        code: "LA",
        name: "Louisiana",
      },
      {
        code: "ME",
        name: "Maine",
      },
      {
        code: "MD",
        name: "Maryland",
      },
      {
        code: "MA",
        name: "Massachusetts",
      },
      {
        code: "MI",
        name: "Michigan",
      },
      {
        code: "MN",
        name: "Minnesota",
      },
      {
        code: "MS",
        name: "Mississippi",
      },
      {
        code: "MO",
        name: "Missouri",
      },
      {
        code: "MT",
        name: "Montana",
      },
      {
        code: "NE",
        name: "Nebraska",
      },
      {
        code: "NV",
        name: "Nevada",
      },
      {
        code: "NH",
        name: "New Hampshire",
      },
      {
        code: "NJ",
        name: "New Jersey",
      },
      {
        code: "NM",
        name: "New Mexico",
      },
      {
        code: "NY",
        name: "New York",
      },
      {
        code: "NC",
        name: "North Carolina",
      },
      {
        code: "ND",
        name: "North Dakota",
      },
      {
        code: "OH",
        name: "Ohio",
      },
      {
        code: "OK",
        name: "Oklahoma",
      },
      {
        code: "OR",
        name: "Oregon",
      },
      {
        code: "PA",
        name: "Pennsylvania",
      },
      {
        code: "RI",
        name: "Rhode Island",
      },
      {
        code: "SC",
        name: "South Carolina",
      },
      {
        code: "SD",
        name: "South Dakota",
      },
      {
        code: "TN",
        name: "Tennessee",
      },
      {
        code: "TX",
        name: "Texas",
      },
      {
        code: "UT",
        name: "Utah",
      },
      {
        code: "VT",
        name: "Vermont",
      },
      {
        code: "VA",
        name: "Virginia",
      },
      {
        code: "WA",
        name: "Washington",
      },
      {
        code: "WV",
        name: "West Virginia",
      },
      {
        code: "WI",
        name: "Wisconsin",
      },
      {
        code: "WY",
        name: "Wyoming",
      },
    ],
  },
  {
    code2: "UY",
    code3: "URY",
    name: "Uruguay",
    capital: "Montevideo",
    region: "Americas",
    subregion: "South America",
    states: [
      {
        code: "AR",
        name: "Artigas",
      },
      {
        code: "CA",
        name: "Canelones",
      },
      {
        code: "CL",
        name: "Cerro Largo",
      },
      {
        code: "CO",
        name: "Colonia",
      },
      {
        code: "DU",
        name: "Durazno",
      },
      {
        code: "FS",
        name: "Flores",
      },
      {
        code: "FD",
        name: "Florida",
      },
      {
        code: "LA",
        name: "Lavalleja",
      },
      {
        code: "MA",
        name: "Maldonado",
      },
      {
        code: "MO",
        name: "Montevideo",
      },
      {
        code: "PA",
        name: "Paysandú",
      },
      {
        code: "RV",
        name: "Rivera",
      },
      {
        code: "RO",
        name: "Rocha",
      },
      {
        code: "RN",
        name: "Río Negro",
      },
      {
        code: "SA",
        name: "Salto",
      },
      {
        code: "SJ",
        name: "San José",
      },
      {
        code: "SO",
        name: "Soriano",
      },
      {
        code: "TA",
        name: "Tacuarembó",
      },
      {
        code: "TT",
        name: "Treinta y Tres",
      },
    ],
  },
  {
    code2: "UZ",
    code3: "UZB",
    name: "Uzbekistan",
    capital: "Tashkent",
    region: "Asia",
    subregion: "Central Asia",
    states: [
      {
        code: "AN",
        name: "Andijon",
      },
      {
        code: "BU",
        name: "Buxoro",
      },
      {
        code: "FA",
        name: "Farg‘ona",
      },
      {
        code: "JI",
        name: "Jizzax",
      },
      {
        code: "NG",
        name: "Namangan",
      },
      {
        code: "NW",
        name: "Navoiy",
      },
      {
        code: "QA",
        name: "Qashqadaryo",
      },
      {
        code: "QR",
        name: "Qoraqalpog‘iston Respublikasi",
      },
      {
        code: "SA",
        name: "Samarqand",
      },
      {
        code: "SI",
        name: "Sirdaryo",
      },
      {
        code: "SU",
        name: "Surxondaryo",
      },
      {
        code: "TO",
        name: "Toshkent",
      },
      {
        code: "TK",
        name: "Toshkent",
      },
      {
        code: "XO",
        name: "Xorazm",
      },
    ],
  },
  {
    code2: "VU",
    code3: "VUT",
    name: "Vanuatu",
    capital: "Port Vila",
    region: "Oceania",
    subregion: "Melanesia",
    states: [
      {
        code: "MAP",
        name: "Malampa",
      },
      {
        code: "PAM",
        name: "Pénama",
      },
      {
        code: "SAM",
        name: "Sanma",
      },
      {
        code: "SEE",
        name: "Shéfa",
      },
      {
        code: "TAE",
        name: "Taféa",
      },
      {
        code: "TOB",
        name: "Torba",
      },
    ],
  },
  {
    code2: "VE",
    code3: "VEN",
    name: "Venezuela",
    capital: "Caracas",
    region: "Americas",
    subregion: "South America",
    states: [
      {
        code: "Z",
        name: "Amazonas",
      },
      {
        code: "B",
        name: "Anzoátegui",
      },
      {
        code: "C",
        name: "Apure",
      },
      {
        code: "D",
        name: "Aragua",
      },
      {
        code: "E",
        name: "Barinas",
      },
      {
        code: "F",
        name: "Bolívar",
      },
      {
        code: "G",
        name: "Carabobo",
      },
      {
        code: "H",
        name: "Cojedes",
      },
      {
        code: "Y",
        name: "Delta Amacuro",
      },
      {
        code: "W",
        name: "Dependencias Federales",
      },
      {
        code: "A",
        name: "Distrito Capital",
      },
      {
        code: "I",
        name: "Falcón",
      },
      {
        code: "J",
        name: "Guárico",
      },
      {
        code: "K",
        name: "Lara",
      },
      {
        code: "M",
        name: "Miranda",
      },
      {
        code: "N",
        name: "Monagas",
      },
      {
        code: "L",
        name: "Mérida",
      },
      {
        code: "O",
        name: "Nueva Esparta",
      },
      {
        code: "P",
        name: "Portuguesa",
      },
      {
        code: "R",
        name: "Sucre",
      },
      {
        code: "T",
        name: "Trujillo",
      },
      {
        code: "S",
        name: "Táchira",
      },
      {
        code: "X",
        name: "Vargas",
      },
      {
        code: "U",
        name: "Yaracuy",
      },
      {
        code: "V",
        name: "Zulia",
      },
    ],
  },
  {
    code2: "VN",
    code3: "VNM",
    name: "Vietnam",
    capital: "Hanoi",
    region: "Asia",
    subregion: "South-Eastern Asia",
    states: [
      {
        code: "44",
        name: "An Giang",
      },
      {
        code: "43",
        name: "Bà Rịa–Vũng Tàu",
      },
      {
        code: "57",
        name: "Bình Dương",
      },
      {
        code: "58",
        name: "Bình Phước",
      },
      {
        code: "40",
        name: "Bình Thuận",
      },
      {
        code: "31",
        name: "Bình Định",
      },
      {
        code: "55",
        name: "Bạc Liêu",
      },
      {
        code: "54",
        name: "Bắc Giang",
      },
      {
        code: "53",
        name: "Bắc Kạn",
      },
      {
        code: "56",
        name: "Bắc Ninh",
      },
      {
        code: "50",
        name: "Bến Tre",
      },
      {
        code: "04",
        name: "Cao Bằng",
      },
      {
        code: "59",
        name: "Cà Mau",
      },
      {
        code: "CT",
        name: "Cần Thơ",
      },
      {
        code: "30",
        name: "Gia Lai",
      },
      {
        code: "03",
        name: "Hà Giang",
      },
      {
        code: "63",
        name: "Hà Nam",
      },
      {
        code: "HN",
        name: "Hà Nội",
      },
      {
        code: "15",
        name: "Hà Tây",
      },
      {
        code: "23",
        name: "Hà Tĩnh",
      },
      {
        code: "14",
        name: "Hòa Bình",
      },
      {
        code: "66",
        name: "Hưng Yên",
      },
      {
        code: "61",
        name: "Hải Dương",
      },
      {
        code: "HP",
        name: "Hải Phòng",
      },
      {
        code: "73",
        name: "Hậu Giang",
      },
      {
        code: "SG",
        name: "Hồ Chí Minh",
      },
      {
        code: "34",
        name: "Khánh Hòa",
      },
      {
        code: "47",
        name: "Kiên Giang",
      },
      {
        code: "28",
        name: "Kon Tum",
      },
      {
        code: "01",
        name: "Lai Châu",
      },
      {
        code: "41",
        name: "Long An",
      },
      {
        code: "02",
        name: "Lào Cai",
      },
      {
        code: "35",
        name: "Lâm Đồng",
      },
      {
        code: "09",
        name: "Lạng Sơn",
      },
      {
        code: "67",
        name: "Nam Định",
      },
      {
        code: "22",
        name: "Nghệ An",
      },
      {
        code: "18",
        name: "Ninh Bình",
      },
      {
        code: "36",
        name: "Ninh Thuận",
      },
      {
        code: "68",
        name: "Phú Thọ",
      },
      {
        code: "32",
        name: "Phú Yên",
      },
      {
        code: "24",
        name: "Quảng Bình",
      },
      {
        code: "27",
        name: "Quảng Nam",
      },
      {
        code: "29",
        name: "Quảng Ngãi",
      },
      {
        code: "13",
        name: "Quảng Ninh",
      },
      {
        code: "25",
        name: "Quảng Trị",
      },
      {
        code: "52",
        name: "Sóc Trăng",
      },
      {
        code: "05",
        name: "Sơn La",
      },
      {
        code: "21",
        name: "Thanh Hóa",
      },
      {
        code: "20",
        name: "Thái Bình",
      },
      {
        code: "69",
        name: "Thái Nguyên",
      },
      {
        code: "26",
        name: "Thừa Thiên–Huế",
      },
      {
        code: "46",
        name: "Tiền Giang",
      },
      {
        code: "51",
        name: "Trà Vinh",
      },
      {
        code: "07",
        name: "Tuyên Quang",
      },
      {
        code: "37",
        name: "Tây Ninh",
      },
      {
        code: "49",
        name: "Vĩnh Long",
      },
      {
        code: "70",
        name: "Vĩnh Phúc",
      },
      {
        code: "06",
        name: "Yên Bái",
      },
      {
        code: "71",
        name: "Điện Biên",
      },
      {
        code: "DN",
        name: "Đà Nẵng",
      },
      {
        code: "33",
        name: "Đắk Lắk",
      },
      {
        code: "72",
        name: "Đắk Nông",
      },
      {
        code: "39",
        name: "Đồng Nai",
      },
      {
        code: "45",
        name: "Đồng Tháp",
      },
    ],
  },
  {
    code2: "WF",
    code3: "WLF",
    name: "Wallis and Futuna",
    capital: "Mata-Utu",
    region: "Oceania",
    subregion: "Polynesia",
    states: [],
  },
  {
    code2: "EH",
    code3: "ESH",
    name: "Western Sahara",
    capital: "El Aaiún",
    region: "Africa",
    subregion: "Northern Africa",
    states: [],
  },
  {
    code2: "YE",
    code3: "YEM",
    name: "Yemen",
    capital: "Sana'a",
    region: "Asia",
    subregion: "Western Asia",
    states: [
      {
        code: "AD",
        name: "'Adan",
      },
      {
        code: "AM",
        name: "'Amrān",
      },
      {
        code: "AB",
        name: "Abyān",
      },
      {
        code: "BA",
        name: "Al Bayḑā'",
      },
      {
        code: "JA",
        name: "Al Jawf",
      },
      {
        code: "MR",
        name: "Al Mahrah",
      },
      {
        code: "MW",
        name: "Al Maḩwīt",
      },
      {
        code: "HU",
        name: "Al Ḩudaydah",
      },
      {
        code: "DA",
        name: "Aḑ Ḑāli'",
      },
      {
        code: "DH",
        name: "Dhamār",
      },
      {
        code: "IB",
        name: "Ibb",
      },
      {
        code: "LA",
        name: "Laḩij",
      },
      {
        code: "MA",
        name: "Ma'rib",
      },
      {
        code: "RA",
        name: "Raymah",
      },
      {
        code: "SH",
        name: "Shabwah",
      },
      {
        code: "TA",
        name: "Tā‘izz",
      },
      {
        code: "SA",
        name: "Şan‘ā'",
      },
      {
        code: "SN",
        name: "Şan‘ā'",
      },
      {
        code: "SD",
        name: "Şā‘dah",
      },
      {
        code: "HJ",
        name: "Ḩajjah",
      },
      {
        code: "HD",
        name: "Ḩaḑramawt",
      },
    ],
  },
  {
    code2: "ZM",
    code3: "ZMB",
    name: "Zambia",
    capital: "Lusaka",
    region: "Africa",
    subregion: "Eastern Africa",
    states: [
      {
        code: "02",
        name: "Central",
      },
      {
        code: "08",
        name: "Copperbelt",
      },
      {
        code: "03",
        name: "Eastern",
      },
      {
        code: "04",
        name: "Luapula",
      },
      {
        code: "09",
        name: "Lusaka",
      },
      {
        code: "06",
        name: "North-Western",
      },
      {
        code: "05",
        name: "Northern",
      },
      {
        code: "07",
        name: "Southern",
      },
      {
        code: "01",
        name: "Western",
      },
    ],
  },
  {
    code2: "ZW",
    code3: "ZWE",
    name: "Zimbabwe",
    capital: "Harare",
    region: "Africa",
    subregion: "Eastern Africa",
    states: [
      {
        code: "BU",
        name: "Bulawayo",
      },
      {
        code: "HA",
        name: "Harare",
      },
      {
        code: "MA",
        name: "Manicaland",
      },
      {
        code: "MC",
        name: "Mashonaland Central",
      },
      {
        code: "ME",
        name: "Mashonaland East",
      },
      {
        code: "MW",
        name: "Mashonaland West",
      },
      {
        code: "MV",
        name: "Masvingo",
      },
      {
        code: "MN",
        name: "Matabeleland North",
      },
      {
        code: "MS",
        name: "Matabeleland South",
      },
      {
        code: "MI",
        name: "Midlands",
      },
    ],
  },
];

export const states = countryStates.reduce(
  (a, b) => a.concat(b.states),
  [] as Array<{ code: string; name: string }>
);
