const env: "production" | "development" | "staging" = (process.env.REACT_APP_ENV ||
  "development") as any;

export const virtualCurrencyBlockchain: { [key: string]: string } = {
  BTC: "bitcoin",
  ETH: "ethereum",
  USDC: "ethereum",
  VCAD: "stellar",
  XTZ: "tezos",
};

export const ethereumChainId: {
  [key: string]: number;
} = {
  goerli: 5,
  mainnet: 1,
};

export const preferredNetwork: {
  [key: string]: string;
} = {
  development: {
    ethereum: "goerli",
    tezos: "ghostnet",
  },
  staging: {
    ethereum: "goerli",
    tezos: "ghostnet",
  },
  production: {
    ethereum: "mainnet",
    tezos: "mainnet",
  },
}[env];

// https://blog.ethereum.org/2022/06/21/testnet-deprecation/
const ethereExplorerSubdomains: any = {
  goerli: "goerli.",
  mainnet: "",
};

export const virtualCurrencyExplorer = (
  type: "address" | "tx",
  network: any,
  currencyCode: string,
  value: string
) => {
  return (
    {
      tx: {
        ETH: `https://${
          ethereExplorerSubdomains[network.ethereum]
        }etherscan.io/tx/${value}`,
        XTZ: `https://${network.tezos}.tzkt.io/${value}`,
      },
      address: {
        ETH: `https://${
          ethereExplorerSubdomains[network.ethereum]
        }etherscan.io/address/${value}`,
        XTZ: `https://${network.tezos}.tzkt.io/${value}`,
      },
    }[type] as any
  )[currencyCode];
};

export const virtualCurrencyAddress: {
  [key: string]: string;
} = {
  development: {
    USDC: "0x07865c6e87b9f70255377e024ace6630c1eaa37f",
  },
  staging: {
    USDC: "0x07865c6e87b9f70255377e024ace6630c1eaa37f",
  },
  production: {
    USDC: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  },
}[env];
