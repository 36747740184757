import {
  ADMIN_ACCOUNT_ID,
  ASSUME_ROLE,
  AUTHENTICATE_AT,
  CURRENT_ACCOUNT_ID,
} from "../constant";

export const unsetAssumeRole = () => {
  const id = localStorage.getItem(ASSUME_ROLE);
  localStorage.removeItem(ASSUME_ROLE);
  return id;
};

export const setAssumeRole = (id: string) => {
  localStorage.setItem(ASSUME_ROLE, id);
};

export const getAssumeRole = () => {
  return localStorage.getItem(ASSUME_ROLE);
};

export const getCurrentAccountId = (checkQueryString = true) => {
  // Return the preferred accountId fromt he query string
  // The accountId will be parmanently stored in the localStorage in the AppContext
  // After onCompleted fetching GET_CURRENT_USER
  return (
    (checkQueryString ? new URLSearchParams(location.search).get("accountId") : null) ||
    localStorage.getItem(CURRENT_ACCOUNT_ID)
  );
};

export const setAdminAccountId = (id: string) => {
  localStorage.setItem(ADMIN_ACCOUNT_ID, id);
};

export const getAdminAccountId = () => {
  return localStorage.getItem(ADMIN_ACCOUNT_ID);
};

export const setCurrentAccountId = (id: string) => {
  localStorage.setItem(CURRENT_ACCOUNT_ID, id);
};

export const getAuthenticatedAt = () => {
  return localStorage.getItem(AUTHENTICATE_AT);
};

export const setAuthenticatedAt = (timestamp: number) => {
  if (isNaN(timestamp)) {
    return;
  }

  localStorage.setItem(AUTHENTICATE_AT, timestamp.toString());
};
