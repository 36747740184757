import React from "react";
import { App } from "./App";
import { render } from "./render";
import reportWebVitals from "./reportWebVitals";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";

import { config } from "./config";
import { captureException } from "./lib/sentry";

Sentry.init({
  dsn: config.sentryDSN,
  environment: config.env,
  release: "investment-platform-frontend-" + process.env.npm_package_version,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.extraErrorDataIntegration({ depth: 10 }),
    Sentry.captureConsoleIntegration({ levels: ["error", "warn", "assert", "info"] })
  ],
  tracesSampleRate: 1.0,
});

render(
  <Sentry.ErrorBoundary
    onError={(e) => {
      captureException(e);
    }}
    fallback={
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <p style={{ textAlign: "center" }}>
          Something went wrong.
          <br />
          <br /> Contact investor@atlasone.ca
        </p>
      </div>
    }
  >
    <App />
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);

function sendToAnalytics({ id, name, value, delta }: any) {
  TagManager.dataLayer({
    dataLayer: {
      event: "event",
      // Custom params:
      eventLabel: id, // id unique to current page load
      eventAction: name,
      nonInteraction: true, // avoids affecting bounce rate
      eventCategory: "Web Vitals",
      eventValue: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers

      metric_id: id, // Needed to aggregate events.
      metric_value: value, // Optional.
      metric_delta: delta, // Optional.

      // OPTIONAL: any additional params or debug info here.
      // See: https://web.dev/debug-performance-in-the-field/
      // metric_rating: 'good' | 'needs-improvement' | 'poor',
      // debug_info: '...',
      // ...
    },
    dataLayerName: "InvestmentPlatformWebVitals",
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
reportWebVitals(sendToAnalytics);
