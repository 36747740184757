import { createContext, useCallback, useContext, useState } from "react";
import { config, preferredNetwork } from "../config";

const Context = createContext({
  ethereum: preferredNetwork?.ethereum,
  tezos: preferredNetwork?.tezos,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setNetworks: (network: "mainnet" | "testnet") => {},
});

export const useNetwork = () => useContext(Context);

export const NetworkContextProvider: React.FC = ({ children }) => {
  const [value, setValue] = useState<any>(
    (() => {
      if (
        config.env !== "production" &&
        localStorage.getItem(`${config.env}_network`) !== "mainnet"
      ) {
        return preferredNetwork;
      }
      return {
        ethereum: "mainnet",
        tezos: "mainnet",
      };
    })()
  );

  const setNetworks = useCallback((network: "mainnet" | "testnet") => {
    localStorage.setItem(`${config.env}_network`, network);
    setValue((prev: any) => ({
      ...prev,
      ethereum: network === "mainnet" ? "mainnet" : "goerli",
      tezos: network === "mainnet" ? "mainnet" : "ghostnet",
    }));
  }, []);

  return (
    <Context.Provider
      value={{
        ...value,
        setNetworks,
      }}
    >
      {children}
    </Context.Provider>
  );
};
