import { Account, AccountType } from "./Account";
import { UserProfile } from "./UserProfile";

export enum Permission {
  EditAccount = "can_edit_account",
  EditOffers = "can_edit_offers",
  EditAccounts = "can_edit_accounts",
  EditTransactions = "can_edit_transactions",
  ViewTransactions = "can_view_transactions",
  InitiateInvestment = "can_initiate_investment",
  AssumeRole = "can_assume_role",
  ViewAuditLogs = "can_view_audit_logs",
  EditPermissions = "can_edit_permissions",
  ViewAccounts = "can_view_accounts",
  CanExportAccountDetails = "can_export_account_details",
  CanEditAccountRiskRating = "can_edit_account_risk_rating",
}

const allAccountsPermissions: Array<Permission> = [
  Permission.EditAccount,
  Permission.ViewTransactions,
  Permission.EditPermissions,
];

const issuerPermissions: Array<Permission> = [
  ...allAccountsPermissions,
  Permission.EditOffers,
  Permission.ViewAccounts,
];

const adminPermissions: Array<Permission> = [
  ...issuerPermissions,
  Permission.EditAccounts,
  Permission.EditTransactions,
  Permission.AssumeRole,
  Permission.ViewAuditLogs,
  Permission.CanExportAccountDetails,
  Permission.CanEditAccountRiskRating,
];

const investorPermissions: Array<Permission> = [
  ...allAccountsPermissions,
  Permission.InitiateInvestment,
];

export const permissionsByAccountType: { [key in AccountType]: Array<Permission> } = {
  admin: adminPermissions,
  entity_investor: investorPermissions,
  individual_investor: investorPermissions,
  issuer: issuerPermissions,
  individual_referral_agent: allAccountsPermissions,
  entity_referral_agent: allAccountsPermissions,
  joint_investor: investorPermissions,
};

export interface AccountUser {
  id: string;
  type: string;
  user: UserProfile;
  userId: string;
  email: string;
  name: string;
  title: string;
  account: Account;
  accountId: string;
  shareholderPercentageOwnership: number;
  referralCode: string;
  isDirector: boolean;
  canLogin: boolean;
  permissions: Array<Permission>;
  canAssumeRole: boolean;
  canEditAccount: boolean;
  canEditAccounts: boolean;
  canEditOffers: boolean;
  canInitiateInvestment: boolean;
  canEditTransactions: boolean;
  canViewTransactions: boolean;
  canViewAuditLogs: boolean;
  canEditPermissions: boolean;
  canViewAccounts: boolean;
  canViewInvestors: boolean;
  canExportAccountDetails: boolean;
  updatedAt?: Date;
  lastLogin?: number;
  requiresPhone: boolean;
  requiresKYC: boolean;
}
