import { useQuery } from "@apollo/client";
import React, { createContext, useCallback, useContext, useState } from "react";
import { Account } from "./dto/Account";
import { AccountUser } from "./dto/AccountUser";
import { signOut as _signOut } from "./lib/amplify";
import {
  getAdminAccountId,
  getAssumeRole,
  getCurrentAccountId,
  setAdminAccountId,
  setAssumeRole,
  setCurrentAccountId,
  unsetAssumeRole,
} from "./lib/auth";
import { GET_CURRENT_USER } from "./lib/queries";
import * as Sentry from "@sentry/react";
import { config } from "./config";

const AppContext = createContext<{
  loading: boolean;
  currentUser?: AccountUser;
  currentAccount?: Account;
  refetchCurrentUser: () => Promise<any>;
  signOut: () => Promise<any>;
  assumeRole: (accountUserId: string) => Promise<any>;
  assumingRole?: string;
}>({
  loading: true,
  refetchCurrentUser: async () => {},
  signOut: async () => {},
  assumeRole: async () => {}
});

export const useAppContext: any = () => useContext(AppContext);

export const AppContextProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [assumingRole, setAssumingRole] = useState<any>(getAssumeRole());

  const {
    loading: loadingCurrentUser,
    data: { currentUser } = { currentUser: undefined },
    refetch: refetchCurrentUser,
  } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: "network-only",
    errorPolicy: "all",
    returnPartialData: true,
    onCompleted: ({ currentUser }) => {
      if (currentUser) {
        const scope = Sentry.getCurrentScope();

        scope.setUser({
          id: currentUser.id,
          email: currentUser.user.email,
        });
        
        scope.setTag("user profile id", currentUser.user.id);
        scope.setTag("user name", currentUser.user.name);
        scope.setTag("account user id", currentUser.id);
        scope.setTag("account id", currentUser.accountId);
        scope.setTag("account number", currentUser.account.accountNumber);

        if ((window as any).FreshworksWidget) {
          (window as any).FreshworksWidget("identify", "ticketForm", {
            name: currentUser.user.name,
            email: currentUser.user.email,
            custom_fields: {
              cf_account_id: currentUser.account.id,
              cf_account_number: currentUser.account.accountNumber,
            },
          });
        }

        setCurrentAccountId(currentUser.account.id);
      } else {
        // clear session and local storage
        signOut();
      }

      setLoading(false);
    },
  });

  const signOut = useCallback(async () => {
    await _signOut().then(() => refetchCurrentUser());
  }, [refetchCurrentUser]);

  const assumeRole = useCallback(async (accountUserId?: string) => {
    if (!accountUserId) {
      unsetAssumeRole();
      const adminAccountId = getAdminAccountId();
      if (adminAccountId) {
        setCurrentAccountId(adminAccountId);
      }
      window.location.href = `${config.baseClientURL}`;
    } else {
      setAssumeRole(accountUserId);
      const currentAccountId = getCurrentAccountId();
      if (currentAccountId) {
        setAdminAccountId(currentAccountId);
      }
    }

    setAssumingRole(accountUserId);
    return refetchCurrentUser();
  }, [refetchCurrentUser]);

  return (
    <AppContext.Provider
      value={{
        loading: loading || loadingCurrentUser,
        currentUser,
        currentAccount: currentUser?.account,
        refetchCurrentUser,
        signOut,
        assumeRole,
        assumingRole
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
