import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ethereumChainId, preferredNetwork } from "../config";
import { useNetwork } from "./useNetwork";

const connector = new WalletConnect({
  bridge: "https://bridge.walletconnect.org",
});

const Context = createContext<{
  startSession: (chainId?: number) => Promise<string>;
  walletAddress?: string;
  chainId?: number;
  connector: WalletConnect;
  preferredNetworkId: string;
}>({
  preferredNetworkId: preferredNetwork.ethereum,
  startSession: async () => {
    return "";
  },
  connector,
});

export const useWalletConnect = () => useContext(Context);

export const WalletConnetContextProvider: React.FC = ({ children }) => {
  const { ethereum: preferredNetworkId } = useNetwork();

  const [walletAddress, setWalletAddress] = useState<string | undefined>(
    connector.session.accounts[0]
  );

  const [chainId, setChainId] = useState<number | undefined>();

  useEffect(() => {
    connector.on("connect", (error, payload) => {
      console.error(error);
      if (!error) {
        const { accounts } = payload.params[0];

        if (accounts && accounts[0]) {
          setWalletAddress(accounts[0]);
        }
      }
    });

    connector.on("session_update", (error, payload) => {
      console.error(error);
      if (!error) {
        const { chainId, accounts } = payload.params[0];

        setChainId(chainId);
        setWalletAddress(accounts[0]);
      }
    });

    connector.on("disconnect", (error) => {
      console.error(error);
      if (!error) {
        setWalletAddress(undefined);
      }
    });
  }, []);

  const startSession = useCallback(
    (chainId?: number) => {
      return new Promise<string>((resolve, reject) => {
        connector.on("connect", (error, payload) => {
          console.error(error);
          if (!error) {
            const { accounts } = payload.params[0];

            if (accounts && accounts[0]) {
              resolve(accounts[0] as string);

              if (QRCodeModal) {
                QRCodeModal.close();
              }
            } else {
              reject();
            }
          } else {
            reject();
          }
        });

        if (!connector.connected) {
          connector
            .createSession({ chainId: chainId || ethereumChainId[preferredNetworkId] })
            .then(() => {
              const uri = connector.uri;
              QRCodeModal.open(uri, () => {
                // if modal is closed without selecting a wallet (i.e. close button) - promise resolves as empty string
                resolve("");
              });
            });
        }
      });
    },
    [preferredNetworkId]
  );

  return (
    <Context.Provider
      value={{
        chainId,
        startSession,
        walletAddress,
        connector,
        preferredNetworkId,
      }}
    >
      {children}
    </Context.Provider>
  );
};
